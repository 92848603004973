import { useFeatureFlagPayload } from 'posthog-js/react';

export function useQuestionDisclaimer() {
  const tysonFoodsDisclaimerFF = useFeatureFlagPayload('tyson-foods-disclaimer');
  const fedexVerbiageChangesFF = useFeatureFlagPayload('depersonalize-premium');

  if (fedexVerbiageChangesFF?.value) {
    return '*Your response to this question will not impact the amount deducted from your paycheck. Cost used for these services are based on averages.';
  }

  if (tysonFoodsDisclaimerFF?.value) {
    return '*Your answer to this question will not be shared with Tyson, UHC, or the plan, and will not affect your rate.';
  }

  return '*Your answer to this question will not affect your rate';
}
