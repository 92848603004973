import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';

import { css } from 'emotion';

import FormControl from '@material-ui/core/FormControl';

import Select from '@material-ui/core/Select';
import { CaretDown } from 'phosphor-react';

const SelectInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(1),
    },
  },
  input: {
    boxSizing: 'border-box',
    borderRadius: 8,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '2px solid #9AA7B5',
    fontSize: 18,
    minWidth: '300px',
    lineHeight: '24px',
    padding: '12px 16px',
    marginRight: '-16px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    '&:focus': {
      boxShadow: '0px 2px 8px rgba(37, 40, 45, 0.25)',
      borderColor: '#25282D',
      borderRadius: 8,
    },
  },
}))(InputBase);

const useStyles = makeStyles({
  icon: {
    top: 'calc(50% - 16px)',
  },
});

export const BriteSelect = (props) => {
  const classes = useStyles();

  return (
    <FormControl>
      <label
        for={props.id}
        className={css`
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          /* identical to box height, or 150% */

          letter-spacing: 0.15px;

          color: #66737f;
        `}
      >
        {props.label}
      </label>
      {props.loading && (
        <div
          className={css`
            border: 2px solid #9aa7b5;
            padding: 12px 16px;
            position: relative;
            font-size: 18px;
            font-family: Roboto, sans-serif;
            line-height: 24px;
            border-radius: 8px;
            background-color: #fff;
            min-width: 300px;
            box-sizing: border-box;
            margin-top: 8px;
          `}
        >
          Looking up State...
        </div>
      )}

      {!props.loading && (
        <Select
          {...props}
          inputProps={{
            classes: {
              icon: classes.icon,
            },
          }}
          input={<SelectInput />}
          IconComponent={(props) => <CaretDown {...props} />}
        >
          {props.children}
        </Select>
      )}
    </FormControl>
  );
};
