import React from 'react';
import ReactDOM from 'react-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import errorHandler from './lib/stackdriver/errorHandler';
import getVisitor from './lib/fingerprint/fingerprint';
import posthog, { posthog_api_key } from './lib/analytics/posthog';
import { CustomAxios } from './axios/axios';
import { App } from './components/App';

let path = window.location.pathname;
let paths = path.split('/');

let domainRedirects = {
  'uslbm-benefits.com': 'uslbm-benefits/1',
  'uslbmbenefits.com': 'uslbm-benefits/1',
  'brites.app': 'hoolibenefits/1',
  localhost: 'hoolibenefits/1',
};

if (paths.length < 2 || !paths[1]) {
  // redirect to hardcoded brite guide based on domain
  if (domainRedirects[window.location.hostname]) {
    window.location.href = window.location.href + domainRedirects[window.location.hostname];
  }
}

// big hack for poker chips landon printed :) What a dumbass eh? hehehaha I love you landon
if (paths.length > 1 && paths[1] === 'varex-demo') {
  window.location.replace('https://britebenefits.com');
}

// init stackdriver error logging
const version = process.env.REACT_APP_GIT_SHA ? process.env.REACT_APP_GIT_SHA : 'unknown_version';
const branch = process.env.REACT_APP_GIT_BRANCH ? process.env.REACT_APP_GIT_BRANCH : 'unknown_branch';
errorHandler.start({
  key: 'AIzaSyAzd0qhj-7FGS0zdseN09-WnHoa_6BYjQA',
  projectId: 'be-brite',
  service: 'employee-view',
  version: branch + '.' + version,
  disabled: process.env.NODE_ENV !== 'production',
});

console.log('VERSION >>>', version);
console.log('BRANCH >>>', branch);

// Do a normal render without posthog analytics when previewing templates/pdfs.
if (paths.length >= 2 && (paths[1] === 'brite-template-preview' || paths[1] === 'pdf-preview')) {
  const queryParams = new URLSearchParams(window.location.search);
  const passcode = queryParams.get('passcode');
  if (passcode) {
    CustomAxios.setHeader('X-Brite-Course-Passcode', passcode);
    window.localStorage.setItem('passcode', passcode);
  }
  ReactDOM.render(<App />, document.getElementById('root'));
} else {
  const queryParams = new URLSearchParams(window.location.search);
  if (queryParams.get('lang')) {
    let currentUrl = window.location.href;
    if (window.location.hostname === 'localhost') {
      console.log('cannot translate localhost, translating production instead');
      currentUrl = `https://britehr.app${window.location.pathname}${window.location.search}`;
    }
    const translateUrl = `https://translate.google.com/translate?hl=&sl=en&tl=${queryParams.get(
      'lang'
    )}&u=${encodeURIComponent(currentUrl)}`;
    window.location.href = translateUrl;
  }

  const renderApp = (visitorId) => {
    posthog.init(posthog_api_key, {
      api_host: 'https://app.posthog.com',
      capture_pageview: false,
      loaded: function (posthog) {
        let setOnceParams = {};
        const linkTracker = queryParams.get('lt');
        if (!!linkTracker) {
          setOnceParams.initialLinkTracker = linkTracker;
        }

        if (linkTracker === 'preview') {
          // if we're previewing, let's make sure we don't get a cached version of the page.
          CustomAxios.setHeader('Cache-Control', 'no-cache');
          CustomAxios.setHeader('X-Brite-Bypass-Cache', 'true'); // the value is not important
          // someday we may want to track these events and filter them out later, but this is fine for now
          posthog.opt_out_capturing();
        } else if (posthog.has_opted_out_capturing()) {
          // opt user back-in if they aren't viewing from preview anymore
          posthog.opt_in_capturing();
        }

        if (visitorId !== null) {
          posthog.identify(visitorId, {}, setOnceParams);
        } else {
          posthog.register_once(setOnceParams);
          visitorId = posthog.get_distinct_id();
        }

        // we can't possibly know the currentBusiness and currentParent until we load the guide
        // so clear it out so we don't mistakenly evaluate feature-flags as turned on
        posthog.setPersonProperties({
          currentBusinessID: null,
          currentParentID: null,
        });

        CustomAxios.setHeader('X-Brite-User-Identifier', visitorId);

        if (linkTracker) {
          posthog.register({ linkTracker: linkTracker });
          CustomAxios.setHeader('X-Brite-Link-Tracker', linkTracker);
        } else {
          posthog.unregister('linkTracker');
        }
      },
    });

    ReactDOM.render(<App />, document.getElementById('root'));
  };

  // get visitor ID and identify them with posthog for analytics
  getVisitor()
    .then((v) => {
      renderApp(v.visitorId);
    })
    .catch((e) => {
      errorHandler.report(`Error getting visitor ID: ${e}`);
      renderApp(null);
    });
}
