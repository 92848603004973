import React, { Component } from 'react';
import { css } from 'emotion';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { Layer, Grommet } from 'grommet';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { X } from 'phosphor-react';
import { smallScreen } from '../additional-benefits/product-components/shared-styles';
export const theme = {
  layer: {
    container: { zIndex: 2000 },
    zIndex: 2000,
  },
};

export const formatCurrency = (value, options) => {
  let formatValue = value || '0';

  formatValue =
    formatValue.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      ...options,
    }) || '$0.00';

  if (formatValue && formatValue !== 'NaN' && formatValue.slice(-3) === '.00') {
    formatValue = formatValue.slice(0, -3);
  }

  return formatValue;
};

export class OtherPlansModal extends Component {
  getMonthsRemaining = (rec) => {
    if (rec?.PlanCostsForYear?.MonthsIncluded) {
      return rec?.PlanCostsForYear?.MonthsIncluded;
    }

    return 12;
  };

  getTotalCombinedCost = (rec) => {
    if (rec.Plan.ID === 'none') {
      return 0;
    }

    return rec?.PlanCostsForYear?.FinalPlanCost;
  };

  render() {
    let selectedRecommendation = this.props.plan;

    return (
      <>
        {this.props.showAllPlans && (
          <Grommet theme={theme}>
            <Layer
              responsive={true}
              position="center"
              modal
              margin="small"
              onClickOutside={() => this.props.setShowAllPlans(false)}
              onEsc={() => this.props.setShowAllPlans(false)}
              className={css`
                width: 100vw;
                max-width: 700px !important;
                font-family: Roboto, Montserrat, sans-serif !important;
              `}
            >
              <div
                className={css`
                  padding: 32px;
                  ${smallScreen} {
                    padding: 16px;
                  }
                `}
              >
                <div
                  className={css`
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  `}
                >
                  <div
                    className={css`
                      color: #25282d;
                      font-size: 24px;
                    `}
                  >
                    Other Medical Plans
                  </div>
                  <IconButton onClick={() => this.props.setShowAllPlans(false)}>
                    <X />
                  </IconButton>
                </div>
                <div
                  className={css`
                    max-height: 500px;
                    overflow: auto;
                  `}
                >
                  {this.props.allMedicalPlans.map((rec) => {
                    return (
                      <div
                        onClick={() => {
                          this.props.setSelectedMedicalRec(rec);
                          this.props.setMedicalType('medical');
                          this.props.setShowAllPlans(false);
                        }}
                        className={css`
                          border-radius: 16px;
                          border: ${rec.Plan.ID === selectedRecommendation.Plan.ID
                            ? `
                            4px solid ${
                              this.props.designStyles?.Theme?.Body?.ButtonColor
                                ? this.props.designStyles?.Theme?.Body?.ButtonColor
                                : '#25282D'
                            };
                            filter: drop-shadow(4px 4px 10px rgba(37, 40, 45, 0.3));
                          `
                            : '1px solid #d1dae3'};
                          margin: 32px;
                          background-color: white;
                          background-color: white;
                          padding: 24px;
                          cursor: pointer;
                          :hover {
                            background-color: #f5f7fa;
                          }
                          ${smallScreen} {
                            padding: 16px;
                            margin: 16px;
                          }
                        `}
                      >
                        <div
                          className={css`
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                          `}
                        >
                          <div>
                            <div
                              className={css`
                                color: #25282d;
                                font-size: 24px;
                                margin-right: 60px;
                                ${smallScreen} {
                                  font-size: 18px;
                                }
                              `}
                            >
                              {rec.Plan.Name}
                            </div>
                          </div>
                          <div>
                            <div
                              className={css`
                                color: #25282d;
                                font-size: 24px;
                                text-align: right;
                                ${smallScreen} {
                                  font-size: 16px;
                                }
                              `}
                            >
                              {this.getTotalCombinedCost(rec) < 0
                                ? `$0`
                                : `${formatCurrency(
                                    parseFloat(
                                      this.props.showMonthlyCosts
                                        ? this.getTotalCombinedCost(rec) / this.getMonthsRemaining(rec)
                                        : this.getTotalCombinedCost(rec)
                                    )
                                  )}`}
                            </div>
                            <div
                              className={css`
                                color: #25282d;
                                font-size: 14px;
                                margin-top: 8px;
                                margin-bottom: 4px;
                                text-align: right;
                                ${smallScreen} {
                                  font-size: 10px;
                                  margin-top: 0px;
                                  margin-bottom: 0px;
                                }
                              `}
                            >
                              Total estimated cost{' '}
                              <Tooltip
                                placement="right"
                                title="Includes the monthly insurance premium plus the estimated cost of medical services."
                              >
                                <HelpOutlineIcon
                                  style={{
                                    fontSize: 18,
                                    top: '3px',
                                    marginLeft: '2px',
                                    position: 'relative',
                                  }}
                                />
                              </Tooltip>
                            </div>

                            {this.props.showMonthlyCosts ? (
                              <div
                                className={css`
                                  text-align: right;
                                  font-size: 14px;
                                  color: #66737f;
                                  ${smallScreen} {
                                    font-size: 10px;
                                  }
                                `}
                              >
                                (Monthly average)
                              </div>
                            ) : (
                              <div
                                className={css`
                                  text-align: right;
                                  font-size: 14px;
                                  color: #66737f;
                                  ${smallScreen} {
                                    font-size: 10px;
                                  }
                                `}
                              >
                                (Annual average)
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Layer>
          </Grommet>
        )}
      </>
    );
  }
}
