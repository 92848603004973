import { css } from 'emotion';
import { Asterisk, Buildings, CurrencyDollarSimple, MapPin } from 'phosphor-react';
import { Div, Text } from '../../../../shared-components';
import { colors } from '../../../../shared-components/styles';
import * as PlanSummaryFields from '../benefits/plan-summary-fields';

export const sectionIcons = {
  'Premiums & Contributions': CurrencyDollarSimple,
  'Network Information': MapPin,
  'Plan Details': Asterisk,
  'Carrier Details': Buildings,
};

export const hideCustomDetailsByType = ['insurance_plan'];

export const hideOptionalDetailsByType = ['insurance_plan'];

export const advancedInputFields = [
  'Details.InNetworkProfessionalServices.PrimaryCarePhysician',
  'Details.InNetworkProfessionalServices.Specialist',
  'Details.InNetworkProfessionalServices.TeleMedicine',
  'Details.InNetworkProfessionalServices.InPatientHospital',
  'Details.InNetworkProfessionalServices.OutPatientSurgery',
  'Details.InNetworkProfessionalServices.MentalHealthOutpatient',
  'Details.InNetworkProfessionalServices.UrgentCare',
  'Details.InNetworkProfessionalServices.EmergencyRoom',
];

export const fieldsThatNeedAttention = {
  all: (product) => ({
    ProviderID:
      (!product?.ProviderID || product?.ProviderID === '00000000-0000-0000-0000-000000000000') &&
      !product?.ProviderName,
    ProviderName: !product?.ProviderName,
    ProductName: !product?.ProductName,
  }),
  custom: (product) => ({
    'Question.Text': product?.Question?.Responses?.length && !product?.Question?.Text,
  }),
};

// Fields that aren't editable in the PLAN SUMMARIES
export const nonEditable = {
  sections: ['Medical Plan Details', 'Network Information'],
  fields: (product) => {
    let hideList = [
      // ALL PLANS
      'ProviderID',
      'ProviderName',
      'ProductName',

      // Medical
      'Details.EffectiveDate',
      'Details.DeductibleReset',
      'Details.NetworkName',
      'Details.NetworkProviderSearchUrl',
      'Details.NetworkDescription',
      'Details.InNetworkPlanDesign.SingleEmbeddedDeductibleOOPM',
      'Details.InNetworkPrescriptionDrugs.PrescriptionDrugTier',
      'Details.PlanType',
      'ApplicableStates',

      // DENTAL
      'Details.IncludesOrtho',

      // VISION
      'Details.EmployeeCost',

      // LIFE INSURANCE
      // 'Details.SupplementalLifeCoverageAvailable',
      'Details.EmployerPaidCoverage',
      'Details.SpouseDependantCoverage',
      'Details.ADDIncluded',
    ];

    return hideList;
  },
};

export const planSummarySettingsSubSections = {
  insurance_plan: {
    // Plan Detail Sub-Section
    'Details.InNetworkPlanDesign.SingleDeductible': 'Label.InNetworkPlanDetails',
    'Details.InNetworkPlanDesign.Deductible': 'Label.InNetworkPlanDetails',
    'Details.InNetworkPlanDesign.SingleOOPM': 'Label.InNetworkPlanDetails',
    'Details.InNetworkPlanDesign.OOPM': 'Label.InNetworkPlanDetails',
    'Details.InNetworkPlanDesign.Coinsurance': 'Label.InNetworkPlanDetails',
    'Label.InNetworkPlanDetails': 'Label.InNetworkPlanDetails',

    // Prescription Drug Sub-Section
    'Details.InNetworkPrescriptionDrugs.MedicalDeductibleApplies': 'Label.PrescriptionDrugs',
    'Details.InNetworkPrescriptionDrugs.Tier1': 'Label.PrescriptionDrugs',
    'Details.InNetworkPrescriptionDrugs.Tier2': 'Label.PrescriptionDrugs',
    'Details.InNetworkPrescriptionDrugs.Tier3': 'Label.PrescriptionDrugs',
    'Details.InNetworkPrescriptionDrugs.Tier4': 'Label.PrescriptionDrugs',
    'Details.InNetworkPrescriptionDrugs.Tier5': 'Label.PrescriptionDrugs',
    'Details.InNetworkPrescriptionDrugs.Tier6': 'Label.PrescriptionDrugs',
    'Details.InNetworkPrescriptionDrugs.MailOrder': 'Label.PrescriptionDrugs',
    'Label.PrescriptionDrugs': 'Label.PrescriptionDrugs',

    ...Object.fromEntries(advancedInputFields.map((property) => [property, 'Label.InNetworkProfessionalServices'])),
    'Label.InNetworkProfessionalServices': 'Label.InNetworkProfessionalServices',
  },
};

const CustomSubtitle = ({ product }) => {
  return (
    <Div
      css={css`
        box-sizing: border-box;
        width: 100%;
        background-color: ${colors.gray[100]};
        border-radius: 16px;
        padding: 16px;
      `}
    >
      <Text h4>{product?.Subtitle || ''}</Text>
      <Text label>{product?.Description || ''}</Text>
    </Div>
  );
};

export const planSummaryOverrides = {
  Cost: {
    hide: true,
  },
  'Details.EmployerPaidCoverageAmount': {
    isComponent: true,
    Component: PlanSummaryFields.GroupLife,
  },
  'Details.SupplementalLifeCoverageAvailable': {
    isComponent: true,
    Component: PlanSummaryFields.SupplementalLife,
  },
  'Details.TotalLifeInsuranceBenefitString': {
    label: 'The maximum amount you can elect',
  },
  'Details.GuaranteeIssueAmount': {
    label: 'Guarantee Issue Amount: (This is what you can elect without submitting any health questionnaires)',
  },
  'Details.SpouseCoverage': {
    label: 'Spouses can elect up to this amount with no questions asked.',
  },
  'Details.DependentCoverage': {
    label: 'Dependent children can elect up to this amount with no questions asked.',
  },
  Title: { hide: true },
  Subtitle: { isComponent: true, Component: PlanSummaryFields.CustomSubtitle },
  'Label.CallToAction': { hide: true },
  CallToActionText: { hide: true },
  CallToActionUrl: { hide: true },
  Description: { hide: true },
  'Details.AdmissionBenefit': {
    isComponent: true,
    Component: PlanSummaryFields.SentenceField,
    fields: ['Details.AdmissionBenefit'],
  },
  'Label.DailyHospitalConfinement': {
    isComponent: true,
    Component: PlanSummaryFields.SentenceField,
    fields: ['Details.ConfinementBenefitValue', 'Details.ConfinementBenefitDaysUpTo'],
  },
  'Details.ConfinementBenefitValue': { hide: true },
  'Details.ConfinementBenefitDaysUpTo': { hide: true },
  'Label.DailyIntensiveCareBenefit': {
    isComponent: true,
    Component: PlanSummaryFields.SentenceField,
    fields: ['Details.IntensiveCareBenefitValue', 'Details.IntensiveCareBenefitDaysUpTo'],
  },
  'Details.IntensiveCareBenefitValue': { hide: true },
  'Details.IntensiveCareBenefitDaysUpTo': { hide: true },
};

const copyConditions = (list, fn) => Object.fromEntries(list.map((item) => [item, fn]));

export const conditionalFields = {
  ProviderName: (product) => {
    const { ProviderID } = product;
    return ProviderID && ProviderID !== '00000000-0000-0000-0000-000000000000' ? 'disabled' : 'enabled';
  },

  'Details.EmployerPaidCoverageAmount': ({ Details }) => (Details?.EmployerPaidCoverage ? 'show' : 'hide'),

  'Details.InNetworkPrescriptionDrugs.Tier4': ({ Details }) =>
    Details?.InNetworkPrescriptionDrugs?.PrescriptionDrugTier === '4 Tier Structure' ||
    Details?.InNetworkPrescriptionDrugs?.PrescriptionDrugTier === '5 Tier Structure' ||
    Details?.InNetworkPrescriptionDrugs?.PrescriptionDrugTier === '6 Tier Structure'
      ? 'show'
      : 'hide',

  'Details.InNetworkPrescriptionDrugs.Tier5': ({ Details }) =>
    Details?.InNetworkPrescriptionDrugs?.PrescriptionDrugTier === '5 Tier Structure' ||
    Details?.InNetworkPrescriptionDrugs?.PrescriptionDrugTier === '6 Tier Structure'
      ? 'show'
      : 'hide',

  'Details.InNetworkPrescriptionDrugs.Tier6': ({ Details }) =>
    Details?.InNetworkPrescriptionDrugs?.PrescriptionDrugTier === '6 Tier Structure' ? 'show' : 'hide',

  ...copyConditions(['Details.OrthodonticsLifetimeMax', 'Details.Children', 'Details.Adult'], ({ Details }) =>
    Details?.IncludesOrtho ? 'show' : 'hide'
  ),

  ...copyConditions(
    [
      'Details.TotalLifeInsuranceBenefitString',
      'Details.ADDIncluded',
      'Details.GuaranteeIssueAmount',
      'Details.SpouseDependantCoverage',
      'Details.SpouseCoverage',
      'Details.DependentCoverage',
    ],
    ({ Details }) => (Details.SupplementalLifeCoverageAvailable ? 'show' : 'hide')
  ),

  ...copyConditions(['Details.SpouseCoverage', 'Details.DependentCoverage'], ({ Details }) =>
    Details.SupplementalLifeCoverageAvailable && Details?.SpouseDependantCoverage ? 'show' : 'hide'
  ),

  'Details.PlanType': () => 'hide',
  ApplicableStates: () => 'hide',
  Cost: () => 'hide',
  ProductName: () => 'hide',
};

export const triggeringProperties = {
  'Details.IncludesOrtho': (value) => ({
    'Details.OrthodonticsLifetimeMax': { State: value ? 'show' : 'hide' },
    'Details.Children': { State: value ? 'show' : 'hide' },
    'Details.Adult': { State: value ? 'show' : 'hide' },
  }),

  'Details.EmployerPaidCoverage': (value) => ({
    'Details.EmployerPaidCoverageAmount': { State: value ? 'show' : 'hide' },
  }),

  'Details.SupplementalLifeCoverageAvailable': (value) => ({
    'Details.SupplementalLifeCoverageAvailable': {
      State: value ? 'show' : 'hide',
    },
    'Details.TotalLifeInsuranceBenefitString': {
      State: value ? 'show' : 'hide',
    },
    'Details.ADDIncluded': { State: value ? 'show' : 'hide' },
    'Details.GuaranteeIssueAmount': { State: value ? 'show' : 'hide' },
    'Details.SpouseCoverage': { State: value ? 'show' : 'hide' },
    'Details.DependentCoverage': { State: value ? 'show' : 'hide' },
  }),

  'Details.SpouseDependantCoverage': (value) => ({
    'Details.SpouseCoverage': { State: value ? 'show' : 'hide' },
    'Details.DependentCoverage': { State: value ? 'show' : 'hide' },
  }),

  'Details.InNetworkPrescriptionDrugs.PrescriptionDrugTier': (value) => ({
    'Details.InNetworkPrescriptionDrugs.Tier4': {
      State: value === '4 Tier Structure' ? 'show' : 'hide',
    },
    'Details.InNetworkPrescriptionDrugs.Tier5': {
      State: value !== '6 Tier Structure' ? 'show' : 'hide',
    },
    'Details.InNetworkPrescriptionDrugs.Tier6': { State: 'show' },
  }),

  'Details.PlanType': (value) => {
    const advanced = 'show';
    const advancedFieldState = advancedInputFields.map((property) => [property, { State: advanced }]);
    return {
      ...Object.fromEntries(advancedFieldState),
    };
  },
};

const planSummaryDependencies = {
  insurance_plan: {
    'Label.InNetworkPlanDetails': [
      'Details.InNetworkPlanDesign.SingleDeductible',
      'Details.InNetworkPlanDesign.Deductible',
      'Details.InNetworkPlanDesign.SingleOOPM',
      'Details.InNetworkPlanDesign.OOPM',
      'Details.InNetworkPlanDesign.Coinsurance',
    ],
    'Label.PrescriptionDrugs': [
      'Details.InNetworkPrescriptionDrugs.MedicalDeductibleApplies',
      'Details.InNetworkPrescriptionDrugs.Tier1',
      'Details.InNetworkPrescriptionDrugs.Tier2',
      'Details.InNetworkPrescriptionDrugs.Tier3',
      'Details.InNetworkPrescriptionDrugs.Tier4',
      'Details.InNetworkPrescriptionDrugs.Tier5',
      'Details.InNetworkPrescriptionDrugs.Tier6',
      'Details.InNetworkPrescriptionDrugs.MailOrder',
    ],
  },
};

// Some Fields ^^^ (planSummaryDependencies) are simply labels and shouldn't be displayed
// if several others are hidden.
export const getConditionalOverrides = (product, sections) => {
  const sectionObject = sections.reduce(
    (prev, item) =>
      item?.Fields?.reduce(
        (p, i) => ({
          ...p,
          [i?.PropertyChain]: i,
        }),
        prev
      ),
    {}
  );
  let dependencies = planSummaryDependencies?.[product?.Type];
  if (product?.Type === 'insurance_plan') {
    dependencies = {
      ...dependencies,
      'Label.InNetworkProfessionalServices': advancedInputFields,
    };
  }
  return Object.entries(dependencies || {}).reduce((prev, [key, value]) => {
    const isHidden = value?.every((field) => sectionObject[field]?.State === 'hide');
    const state = isHidden ? 'hide' : 'show';
    return { ...prev, [key]: state };
  }, {});
};

export const flattenSummaryFields = (product, layout) => {
  const { Sections = [] } = layout || {};

  const conditionalOverrides = getConditionalOverrides(product, Sections);

  const getField = (field, sectionIdx) => {
    const State = conditionalOverrides?.[field?.PropertyChain] || field?.State;
    return {
      ...field,
      State,
      sectionIdx,
      itemType: 'field',
    };
  };

  return Sections?.reduce((prev, { DisplayValue, Fields }, sectionIdx) => {
    if (!nonEditable.sections.includes(DisplayValue) && DisplayValue !== 'Premiums & Contributions') {
      let list = [];
      if (sectionIdx === 0) {
        list.push({ sectionTitle: 'Plan Details', itemType: 'section' });
      }

      return [...prev, ...list, ...Fields.map(getField)];
    }
    return prev;
  }, []);
};
