import React from 'react';
import { getAttributes } from '../utils';
import { css, cx } from 'emotion';

export const TextV2 = ({ item, totalColumns }) => {
  const componentAttributes = getAttributes({
    column: item,
    length: totalColumns,
    type: 'component',
  });

  const combineComponentClass = cx(
    componentAttributes.className,
    css`
      ol,
      ul,
      p {
        margin: 0;
        li {
          margin: 0;
        }
      }
    `
  );
  return (
    <pre
      {...componentAttributes}
      className={combineComponentClass}
      dangerouslySetInnerHTML={{ __html: item?.content || '' }}
    ></pre>
  );
};
