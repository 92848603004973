import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { css } from 'emotion';
import Confetti from 'react-confetti';
import { CustomAxios } from '../axios/axios';

import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function QuestionPageModal(props) {
  useEffect(() => {
    if (props.open) {
      try {
        let answeredCorrectly = props.answerState === 'correct' || props.answerState === 'no-correct-answer';

        CustomAxios.post(`/public/v1/${props.courseId}/analytics/courseScore`, {
          QuestionID: props.questionId,
          CourseID: props.courseId,
          AnswerID: props.answerId,
          AnsweredCorrect: answeredCorrectly,
        });
      } catch (error) {
        console.log('did not save question answer', error);
      }
    }
  }, [props.open]);
  const getTitleText = () => {
    if (props.answerState === 'correct') {
      return 'You answered correctly!';
    } else if (props.answerState === 'no-correct-answer') {
      return 'Thanks for selecting an answer!';
    } else {
      return 'You answered incorrectly';
    }
  };
  return (
    <>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        maxWidth={'xs'}
        onClose={() => {
          props.setOpen(false);
          props.nextPage();
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{getTitleText()}</DialogTitle>
        <DialogContent>
          <Confetti width={'500px'} height={'200px'} run={props.answerState === 'correct'} opacity={0.4} />
          <DialogContentText>{props.description}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className={css`
              width: 100% !important;
            `}
            onClick={() => {
              props.setOpen(false);
              props.nextPage();
            }}
            variant="contained"
            autoFocus
            size={'large'}
          >
            Got it
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
