import { css, cx } from 'emotion';
import { useState } from 'react';
import { Div } from './div';
import { Text } from './text';
import { Portal } from './Portal';
import { usePopper } from 'react-popper';
import { useLifecycle } from './useLifecycle';
import { useThemedProps } from './useThemedProps';
import { Box } from './Box';
import { animation, container } from './shared-styles';
import { useOutsideClick } from '../components/use-outside-click';

const dropdownTheme = {
  default: `
    position: relative;
    background-color: white;
    border-radius: 8px;
    padding: 8px;
  `,
  disabled: `
    opacity: .6;
    pointer-events: none;
  `,
};

export const Dropdown = ({
  button,
  stopPropagation = false,
  css: cssString,
  flex = '',
  children,
  outsideClickProps = {},
  listWidth,
  listMaxHeight = '40vh',
  listCss = '',
  onClose = null,
  // react-popper props
  popper: popperProps = {},
  disabled = false,
}) => {
  const [open, setOpen] = useState(false);
  const ref = useOutsideClick(() => setOpen(false), outsideClickProps);
  const [anchor, setAnchor] = useState(null);
  const [popper, setPopper] = useState(null);
  const { styles, attributes } = usePopper(anchor, popper, popperProps);

  const props = useThemedProps({ flex, css: cssString, disabled }, dropdownTheme);

  useLifecycle(
    {
      onSubsequent: () => {
        if (!open) {
          onClose?.();
        }
      },
    },
    [open]
  );
  return (
    <Div {...props} ref={setAnchor} onClick={() => setOpen(!open)}>
      {button({ open })}
      {open ? (
        <Portal>
          <div ref={ref}>
            <Box
              ref={setPopper}
              scrollbar
              className={cx(
                css`
                  z-index: 10000;
                  ${container.box}
                  ${animation('fadein', '.2s ease')}
                  position: absolute;
                  padding: 8px;
                  margin-top: 8px;
                  overflow: auto;
                  overflow-x: hidden;
                  max-height: ${listMaxHeight};
                  min-width: ${listWidth};
                  border-radius: 8px;
                  ::-webkit-scrollbar-track {
                    border-radius: 50%;
                  }
                  ${listCss}
                `,
                'dropdown-list'
              )}
              style={styles.popper}
              {...attributes.popper}
              onMouseUp={(e) => {
                if (stopPropagation) {
                  e.stopPropagation();
                }
              }}
              onClick={(e) => {
                if (stopPropagation) {
                  e.stopPropagation();
                }
              }}
            >
              {children}
            </Box>
          </div>
        </Portal>
      ) : null}
    </Div>
  );
};

Dropdown.Item = ({ children, onClick, css: cssStyle, className }) => {
  return (
    <Div
      css={cx(
        css`
          padding: 8px;
          ${container.hover}
        `,
        css`
          ${cssStyle}
        `
      )}
      className={className}
      onClick={onClick}
    >
      <Text label>{children}</Text>
    </Div>
  );
};
