import React, { Component } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import { BriteSelect } from './inputs/BriteSelect';
import { css } from 'emotion';
import { motion } from 'framer-motion';

export class DecisionToolMedicalHistoryQuestion extends Component {
  render() {
    return (
      <motion.div
        key={`medical-history-question`}
        initial="pageInitial"
        animate="pageAnimate"
        exit="pageExit"
        variants={{
          pageInitial: {
            y: 400,
            opacity: 0,
          },
          pageAnimate: {
            y: 0,
            opacity: 1,
          },
          pageExit: {
            y: -400,
            opacity: 0,
          },
        }}
        className={css`
          box-shadow: 0px 2px 8px rgba(37, 40, 45, 0.25);
          border-radius: 8px;
          background: #ffffff;
          padding: 24px;
          margin-bottom: 32px;
          text-align: left;
        `}
      >
        <h1
          className={css`
            color: #25282d;
            font-size: 23px;
            line-height: 32px;
            margin-block-start: 0px;
            margin-block-end: 24px;
          `}
        >
          Do you have a family history of any of the following?
        </h1>

        <BriteSelect
          MenuProps={{
            style: { zIndex: 35001 },
          }}
          multiple
          className={css`
            max-width: 300px;
          `}
          labelId="medical-history-label"
          id="medical-history-label-id"
          value={this.props.medicalHistory}
          onChange={(e) => this.props.setMedicalHistory(e)}
          label="Select all that apply"
          renderValue={(selected) => selected.join(', ')}
        >
          {[
            'Diabetes',
            'Cancer',
            'High Blood Pressure',
            'High Cholesterol',
            'Cardiovascular Disease',
            'Heart Disease',
            'Stroke',
            'Mental Illness',
            'Asthma',
            'Lung Disease',
            'Alcohol/Drug Abuse',
          ].map((name) => {
            return (
              <MenuItem key={name} value={name}>
                <Checkbox checked={this.props.medicalHistory.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </MenuItem>
            );
          })}
        </BriteSelect>

        <div
          className={css`
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 24px;
            /* identical to box height, or 200% */

            letter-spacing: 0.15px;

            color: #9aa7b5;
            margin-top: 16px;
          `}
        >
          {this.props.questionDisclaimerText}
        </div>
      </motion.div>
    );
  }
}
