import { css, cx } from 'emotion';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { animation } from '../../../shared-components/shared-styles';

function toFixed(num, fixed = 4) {
  var re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?');
  return num.toString().match(re)[0];
}

const calculatedCrop = ({ crop, image }) => {
  try {
    const pixelWidth = toFixed(crop?.width) * image?.naturalWidth;
    const pixelHeight = toFixed(crop?.height) * image?.naturalHeight;
    const aspectRatio = toFixed(pixelWidth) / toFixed(pixelHeight);
    const scaleW = image?.naturalWidth / (image?.naturalWidth * (toFixed(crop?.width) / 100));
    const scaleH = image?.naturalHeight / (image?.naturalHeight * (toFixed(crop?.height) / 100));
    const width = scaleW * 100;
    const cropX = scaleW * toFixed(crop?.x);
    const cropY = scaleH * toFixed(crop?.y);

    return css`
      position: relative;
      width: 100%;
      overflow: hidden;
      aspect-ratio: ${aspectRatio};
      ${crop.circularCrop ? `border-radius: 50%;` : ''}
      opacity: 100%;
      img {
        position: absolute;
        width: ${width}%;
        top: -${cropY}%;
        left: -${cropX}%;
        transform: scale(${crop?.scale}) rotate(${crop?.rotate}deg);
      }
    `;
  } catch (err) {
    return css`
      display: none;
    `;
  }
};

const defaultCss = css`
  display: none;
  height: 100px;
`;

export const CroppedImage = ({ crop, style, imageProps }) => {
  const ref = useRef();
  const [cssStyle, setCssStyle] = useState(defaultCss);

  const updateCssStyles = (event) => {
    const styles = calculatedCrop({ crop, image: event.currentTarget });
    setCssStyle(styles);
  };

  const cropStyle = {
    ...style,
    ...(crop?.circularCrop
      ? {
          borderTopLeftRadius: '50%',
          borderTopRightRadius: '50%',
          borderBottomLeftRadius: '50%',
          borderBottomRightRadius: '50%',
        }
      : {}),
  };

  return (
    <div className={cssStyle} style={cropStyle}>
      <img ref={ref} {...imageProps} onLoad={updateCssStyles} />
    </div>
  );
};
