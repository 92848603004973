import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';

import { css } from 'emotion';
import FormControl from '@material-ui/core/FormControl';
import { Minus, Plus } from 'phosphor-react';

const useStyles = makeStyles((theme) => ({
  customIconButton: {
    '&.MuiIconButton-root': { backgroundColor: '#E8EDF3' },
    '&:hover, &.Mui-focusVisible': {
      backgroundColor: '#D1DAE3',
    },
  },
  inputnumber: {
    [theme.breakpoints.up('md')]: {
      '& input[type=number]': {
        '-moz-appearance': 'textfield',
      },
      '& input[type=number]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '& input[type=number]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
    },
  },
}));

const BriteTextInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(1),
    },
  },
  input: {
    boxSizing: 'border-box',
    borderRadius: 8,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '2px solid #9AA7B5',
    fontSize: 18,

    lineHeight: '24px',
    padding: '24px 16px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    '&:focus': {
      boxShadow: '0px 2px 8px rgba(37, 40, 45, 0.25)',
      borderColor: '#25282D',
    },
  },
}))(InputBase);

export default function BriteInputField(props) {
  const classes = useStyles();
  return (
    <FormControl>
      <label
        for={props.id}
        className={css`
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          /* identical to box height, or 150% */

          letter-spacing: 0.15px;

          color: #66737f;
        `}
      >
        {props.label}
      </label>
      {props.type === 'number' ? (
        <div
          className={css`
            display: flex;
            align-items: center;
            gap: 8px;
          `}
        >
          <BriteTextInput autoComplete="off" {...props} style={{ width: '190px' }} className={classes.inputnumber} />
          <IconButton
            className={classes.customIconButton}
            onClick={() => {
              if (props.value - 1 >= 0) {
                props.onChange({ target: { value: props.value - 1 } });
              }
            }}
          >
            <Minus size="24" />
          </IconButton>
          <IconButton
            className={classes.customIconButton}
            onClick={() => {
              props.onChange({ target: { value: props.value + 1 } });
            }}
          >
            <Plus size="24" />
          </IconButton>
        </div>
      ) : (
        <BriteTextInput autoComplete="off" {...props} style={{ minWidth: '300px' }} />
      )}
    </FormControl>
  );
}
