import React, { useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import { Grommet, Layer } from 'grommet';
import { css } from 'emotion';
import { toast } from 'react-toastify';
import Tooltip from '@material-ui/core/Tooltip';

import { X, CopySimple, PaperPlaneTilt } from 'phosphor-react';
import { posthogAttrs } from '../../posthog-constants';

export const theme = {
  layer: {
    container: { zIndex: 2000 },
    zIndex: 2000,
  },
};

function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'windows-phone';
  }

  if (/android/i.test(userAgent)) {
    return 'android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'ios';
  }

  return 'unknown';
}

export const ShareModal = ({
  shareData = {
    title: '',
    text: '',
    url: '',
  },
  designStyles,
}) => {
  const [showBackupShare, setShowBackupShare] = useState(false);

  const showModal = async () => {
    if (window.navigator.share) {
      try {
        await navigator.share(shareData);
        setShowBackupShare(false);
      } catch (err) {
        setShowBackupShare(true);
      }
    } else {
      setShowBackupShare(true);
    }
  };

  const email = () => {
    const body = encodeURIComponent(shareData.text + ' ' + shareData.url);
    //window.location.href = `mailto:?subject=${shareData.title}&body=${body}`;
    window.open(`mailto:?subject=${shareData.title}&body=${body}`, '_blank');
    setShowBackupShare(false);
  };

  const textMessage = () => {
    const device = getMobileOperatingSystem();
    let message = shareData.text + ' ' + shareData.url;
    if (device === 'android') {
      window.location.href = `sms:${shareData.sendToNumber}?body=${message}`;
    } else {
      window.location.href = `sms:${shareData.sendToNumber}&body=${message}`;
    }
    setShowBackupShare(false);
  };

  return (
    <>
      <Grommet theme={theme}>
        {showBackupShare && (
          <Layer
            responsive={true}
            position="center"
            modal
            margin="small"
            onClickOutside={() => setShowBackupShare(false)}
            onEsc={() => setShowBackupShare(false)}
            className={css`
              width: 100vw;
              max-width: 500px !important;
            `}
          >
            <div
              className={css`
                padding: 24px 32px;
              `}
            >
              <div
                className={css`
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                `}
              >
                <div
                  className={css`
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 32px;
                    /* identical to box height, or 133% */

                    color: #25282d;
                  `}
                >
                  Share
                </div>
                <IconButton onClick={() => setShowBackupShare(false)}>
                  <X />
                </IconButton>
              </div>

              <div
                className={css`
                  font-family: 'Roboto';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 24px;
                  /* or 150% */

                  letter-spacing: 0.15px;

                  color: #66737f;
                  margin-top: 18px;
                `}
              >
                We are all about communication. If you have someone in your family who needs to know about this guide,
                let's get the conversation going!
              </div>

              <div
                className={css`
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 32px;
                  margin-top: 32px;
                  margin-bottom: 32px;
                `}
              >
                <div
                  onClick={() => {
                    navigator.clipboard.writeText(shareData.url);
                    setShowBackupShare(false);
                    toast.success('Guide link has been copied ', {
                      position: toast.POSITION.TOP_CENTER,
                      closeButton: <X size="32" color="#25282D" />,
                      progressClassName: css`
                        background: #25282d;
                      `,
                      className: css`
                        background: white !important;
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 133.4%;
                        /* identical to box height, or 24px */
                        display: flex;
                        align-items: center;
                        color: #25282d;
                      `,
                    });
                  }}
                  className={css`
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    cursor: pointer;
                  `}
                >
                  <div
                    className={css`
                      background: ${designStyles?.Theme?.Body?.ButtonColor
                        ? designStyles?.Theme?.Body?.ButtonColor
                        : '#25282D'};
                      border-radius: 51px;
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      padding: 20px;
                      &:hover {
                        opacity: 0.9;
                      }
                    `}
                  >
                    <CopySimple size="40px" color="white" />
                  </div>

                  <div
                    className={css`
                      font-family: 'Roboto';
                      font-style: normal;
                      font-weight: 400;
                      font-size: 16px;
                      line-height: 24px;
                      /* identical to box height, or 150% */

                      text-align: center;
                      letter-spacing: 0.15px;

                      color: #25282d;
                      margin-top: 16px;
                    `}
                  >
                    Copy Link
                  </div>
                </div>
              </div>
              <div
                className={css`
                  font-family: 'Roboto';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 24px;
                  /* or 150% */

                  letter-spacing: 0.15px;

                  color: #66737f;
                  margin-top: 18px;
                `}
              >
                Click the button above to copy the link and then share it with anyone by sending them the text or email.
              </div>
            </div>
          </Layer>
        )}
      </Grommet>
      <div
        className={css`
          margin-left: -2px;
        `}
      >
        <Tooltip PopperProps={{ style: { zIndex: 234234 } }} title={'Share'}>
          <IconButton
            color="inherit"
            aria-label="Share"
            edge="end"
            data-ph-capture-attribute-source={posthogAttrs?.types?.RECOMMENDATIONS}
            data-ph-capture-attribute-action={posthogAttrs?.actions?.SHARE}
            onClick={() => showModal()}
          >
            <PaperPlaneTilt />
          </IconButton>
        </Tooltip>
      </div>
    </>
  );
};
