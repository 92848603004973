import { useEffect, useRef } from 'react';

export const useLifecycle = ({ onMount, onSubsequent, onUnmount }, deps = '') => {
  const isInitial = useRef(true);
  const methods = useRef({});
  methods.current = {
    onMount,
    onSubsequent,
    onUnmount,
  };
  useEffect(() => {
    if (isInitial.current) {
      isInitial.current = false;
      methods?.current?.onMount?.();
    } else {
      methods?.current?.onSubsequent?.();
    }
    return () => methods?.current?.onUnmount?.();
  }, [...deps]);

  return isInitial.current;
};
