import { CircularProgress } from '@material-ui/core';
import { css, cx } from 'emotion';
import { Div } from '../shared-components';
import { flex } from '../shared-components/shared-styles';
import { colors } from '../shared-components/styles';

const bounce = `
  @keyframes bounce {
    0%   { transform: translateY(0px); }
    20%  { transform: translateY(-5px); }
    40%  { transform: translateY(0px); }
    60%  { transform: translateY(-5px); }
    80%  { transform: translateY(0px); }
  }
`;

const threeDots = css`
  @keyframes three-dots {
    to {
      opacity: 0.1;
      transform: translateY(8px);
    }
  }
`;

const threeDotsLoader = (isLoading) => css`
  ${flex('right start')}
  position: absolute;
  top: 3px;
  right: 3px;
  bottom: 3px;
  left: 3px;
  padding: 8px;
  background-color: white;
  z-index: 100;
  transition: 0.2s opacity ease;
  ${!isLoading &&
  `
    opacity: 0;
    pointer-events: none;
  `}
  ${threeDots}
  > div {
    animation: three-dots 0.6s infinite alternate;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${colors.black};
    margin: 0 4px;
    :nth-child(2) {
      animation-delay: 0.2s;
    }
    :nth-child(3) {
      animation-delay: 0.4s;
    }
  }
`;

const iconLoader = (isLoading) => css`
  ${flex('center column')}
  ${bounce}
  .bounce {
    animation: bounce 1s ease alternate;
    animation-iteration-count: infinite;
  }
  .delay {
    :nth-child(odd) {
      animation-delay: 0.15s;
    }
    :nth-child(even) {
      animation-delay: 0.25s;
    }
  }
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1500;
  background-color: white;
  opacity: 1;
  transition: opacity 0.5s ease;
  ${isLoading
    ? `
    pointer-events: all;
  `
    : `
    opacity: 0;
    pointer-events: none;
  `}
`;

export const Loader = ({ type = 'circular', children, isLoading = true, className = '' }) => {
  if (type === 'circular') {
    return isLoading ? (
      <Div
        css={css`
          ${flex('jcc aic')} width: 100%;
          height: 100%;
          padding: 8px;
        `}
      >
        <CircularProgress />
      </Div>
    ) : null;
  } else if (type === 'icon') {
    return <Div css={cx(iconLoader(isLoading), className)}>{children}</Div>;
  } else if (type === 'three-dots') {
    return (
      <Div css={cx(threeDotsLoader(isLoading), className)}>
        <Div />
        <Div />
        <Div />
      </Div>
    );
  }
};
