// ** Used Attributes for posthog: **
// data-ph-capture-attribute-source = posthogAttrs.types
// data-ph-capture-attribute-action = posthogAttrs.actions
// data-ph-capture-attribute-product-id
// data-ph-capture-attribute-product-type
// data-ph-capture-attribute-component (editor component types)

// For added context, components with actions:
// data-ph-capture-attribute-value:{property} = value

export const posthogAttrs = {
  types: {
    CONTENT: 'content',
    NAVIGATION: 'navigation',
    RECOMMENDATIONS: 'recommendations',
  },
  actions: {
    BACK_TO_GUIDE: 'back-to-guide',
    CALL_TO_ACTION: 'call-to-action',
    ENROLL: 'enroll',
    GET_RECOMMENDATIONS: 'get-recommendations',
    NEXT_PAGE: 'navigate-to-next-page',
    PAGES_MENU: 'pages-drawer',
    PREV_PAGE: 'navigate-to-previous-page',
    SEARCH: 'search',
    LANGUAGE: 'language',
    SEARCH_NETWORK: 'search-network',
    SEE_RESULTS: 'see-results',
    SELECT_MEDICAL: 'select-medical-plan',
    SHARE: 'share',
    SHOW_COMPARISONS: 'show-comparisons',
    SUREST_TOGGLE: 'surest-flexible-toggle',
    VIEW_MORE: 'view-more',
    VIEW_RECOMMENDATIONS: 'view-recommendations',
  },
};
