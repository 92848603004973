import { CroppedImage } from '../../brite-viewer/components/cropped-image';
import { getAttributes } from '../../brite-viewer/utils';

export const Image = ({ item, totalColumns, containerAttributes, containerStyle }) => {
  const componentAttributes = getAttributes({
    column: item,
    length: totalColumns,
    type: 'component',
  });

  return (
    <div style={containerStyle}>
      {item?.crop ? (
        <CroppedImage
          style={componentAttributes?.style}
          width={containerAttributes?.style?.width}
          crop={item?.crop}
          imageProps={{
            src: componentAttributes?.src,
            alt: componentAttributes?.alt,
          }}
        />
      ) : (
        <img alt="" {...componentAttributes} />
      )}
    </div>
  );
};
