import React, { Component, useEffect } from 'react';

import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { CustomAxios } from '../../../axios/axios';
import { css } from 'emotion';
import { states } from '../constants';
import { motion } from 'framer-motion';
import BriteTextInput from './inputs/BriteTextInput';
import { BriteSelect } from './inputs/BriteSelect';

export const DecisionToolStateQuestion = ({ zipCode, setZipCode, livingState, setLivingState }) => {
  // add state for loading
  const [loading, setLoading] = React.useState(false);

  const lookupState = async (zipCode) => {
    setLoading(true);
    try {
      const resp = await CustomAxios.get(`/public/v1/zywave/areas?zipCode=${zipCode}`);
      if (resp.data.Data.length > 0) {
        const stateAbbreviation = resp.data.Data[0].SubdivisionCode;
        const state = states.find((s) => s.id === stateAbbreviation);
        if (state.id) {
          setLivingState(state.id);
        }
      }
    } catch (error) {
      console.log('error getting state for zip code, will continue without it:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!livingState && zipCode) {
        lookupState(zipCode);
      }
    }, 600);

    return () => clearTimeout(timer);
  }, [zipCode]);

  const handleZipCodeChange = (zip) => {
    setZipCode(zip);
  };

  return (
    <motion.div
      key={'state_question'}
      initial="pageInitial"
      animate="pageAnimate"
      exit="pageExit"
      variants={{
        pageInitial: {
          y: 400,
          opacity: 0,
        },
        pageAnimate: {
          y: 0,
          opacity: 1,
        },
        pageExit: {
          y: -400,
          opacity: 0,
        },
      }}
      className={css`
        background: #ffffff;

        box-shadow: 0px 2px 8px rgba(37, 40, 45, 0.25);
        border-radius: 8px;
        padding: 24px;
        margin-bottom: 32px;
        text-align: left;
      `}
    >
      <h1
        className={css`
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 32px;
          /* identical to box height, or 133% */

          color: #25282d;
          margin-block-start: 0px;
          margin-block-end: 24px;
        `}
      >
        Where are you located?
      </h1>

      <div
        className={css`
          display: flex;
          align-items: center;
          gap: 16px;
          flex-wrap: wrap;
        `}
      >
        <div>
          <BriteTextInput
            value={zipCode}
            id="zip-code-input"
            label="Zip Code"
            placeholder="Enter Zip Code"
            variant="outlined"
            inputProps={{ inputMode: 'numeric' }}
            onChange={(e) => handleZipCodeChange(e.target.value)}
          />
        </div>

        <div>
          <BriteSelect
            MenuProps={{
              style: { zIndex: 35001 },
            }}
            labelId="state-label"
            id="state-label-id"
            value={livingState}
            onChange={(e) => setLivingState(e.target.value)}
            label="State"
            disabled={loading}
            loading={loading}
          >
            {states.map((state) => {
              return (
                <MenuItem key={state.id} value={state.id}>
                  {state.label}
                </MenuItem>
              );
            })}
          </BriteSelect>
        </div>
      </div>
    </motion.div>
  );
};
