import { css } from 'emotion';
import { Div, Text } from '../../../../shared-components';
import { flex } from '../../../../shared-components/shared-styles';
import { colors } from '../../../../shared-components/styles';
import { getDisplayValue, getFieldValue } from './field-utils';

// This component is only used for presentational fields and non-editable types
export const FieldComponent = ({ product, field, comparisonView = false, fullWidth = false }) => {
  const value = getFieldValue(product, field);

  const compCheck = comparisonView && field?.State === 'hide';
  const testValue = value || {};
  const valueCheck =
    (!value && value !== 0) || (typeof value === 'object' && 'inputValue' in testValue && !value?.inputValue);

  if ((compCheck || valueCheck) && field?.Type !== 'label' && field?.Type !== 'toggle') {
    return comparisonView ? (
      <Text
        h4
        css={`
          color: ${colors.gray[300]};
        `}
      >
        No Details
      </Text>
    ) : (
      <Text>{getDisplayValue(value, field)}</Text>
    );
  }

  const CustomText = ({ children, ...props }) => (
    <Text {...props} h4={comparisonView}>
      {children}
    </Text>
  );

  const fieldTypeOverride = value.PercentOrDollar === '$' ? 'dollar' : 'percent';

  return (
    <Div
      css={css`
        flex-grow: 1;
        ${field.RecDisplayValue || field.DisplayValue ? 'min-width: 50%;' : 'width: 100%;'}
        ${comparisonView
          ? `
          ${flex('left wrap')}
        `
          : ''}
      `}
    >
      {field.Pre && (
        <Text
          className={css`
            text-align: right;
            flex-grow: 1;
            ${comparisonView
              ? `
              max-width: max-content;
            `
              : ''}
          `}
        >
          {field.Pre}
        </Text>
      )}
      <Div
        css={css`
          ${comparisonView
            ? `
              ${flex('left')}
              ${!field.Pre ? 'padding-left: 0px' : 'padding-left: 8px'};
            `
            : `
            ${flex('right')}
            ${field.Pre ? 'padding-left: 0px' : 'padding-left: 8px'};
          `}
        `}
      >
        {field.Type === 'dollar' ? (
          // ----> DOLLAR
          <CustomText styles="label bold">{getDisplayValue(value, field)}</CustomText>
        ) : field.Type === 'percent' || field.Type === 'percentOfEmployeeElectedAmount' ? (
          // ----> PERCENT
          <CustomText styles="label bold">{getDisplayValue(value, field)}</CustomText>
        ) : field.Type === 'toggle' ? (
          // ----> TOGGLE
          <Text styles="label bold">{value ? 'Yes' : 'No'}</Text>
        ) : field.Type === 'text-input' || field.Type === 'number' ? (
          <CustomText
            styles="label bold"
            className={css`
              text-align: ${comparisonView ? 'left' : 'right'};
            `}
          >
            {value}
          </CustomText>
        ) : field.Type === 'multipleOfSalary' ? (
          // ----> DOLLAR
          <CustomText styles="label bold">{getDisplayValue(value, field)}X Salary</CustomText>
        ) : field.Type === 'text-area' ? (
          <CustomText
            className={css`
              text-align: ${comparisonView ? 'left' : 'right'};
              width: 100%;
            `}
            label
            bold
          >
            {value}
          </CustomText>
        ) : field.Type === 'visionFrequency' ? (
          <CustomText styles="label bold">{value.inputValue}</CustomText>
        ) : field.Type === 'advancedInputField' ? (
          <Div
            css={css`
              ${flex('right')}
            `}
          >
            {value.ADOrCopay === 'COPAY + %' && (
              <CustomText
                styles="label bold"
                className={css`
                  margin-right: 4px;
                `}
              >
                {getDisplayValue(value?.SpecialCopay || '', field, 'dollar')} Copay plus
              </CustomText>
            )}
            {/* Skip display value if covered or not covered, since these only need the label */}
            {value.ADOrCopay != 'Not Covered' && value.ADOrCopay != '100% Covered' && (
              <CustomText
                styles="label bold"
                className={css`
                  margin-right: 4px;
                  min-width: max-content;
                `}
              >
                {getDisplayValue(value.Value, field, fieldTypeOverride)}
              </CustomText>
            )}
            {value?.ADOrCopay === 'COPAY' && value?.MaxValue !== null ? (
              <CustomText
                styles="label bold"
                className={css`
                  margin-right: 4px;
                  min-width: max-content;
                `}
              >
                - {getDisplayValue(value.MaxValue, field, fieldTypeOverride)}
              </CustomText>
            ) : null}
            <CustomText styles="label bold">
              {value.ADOrCopay === 'COPAY' ? (
                value?.PercentOrDollar === '$' ? (
                  value?.MaxValue !== null ? (
                    'Copay Range'
                  ) : (
                    'Copay'
                  )
                ) : (
                  'Coinsurance'
                )
              ) : value.ADOrCopay === 'COPAY + %' || value.ADOrCopay === 'AD' ? (
                <>
                  <span
                    className={css`
                      @media (min-width: 600px) {
                        display: none;
                      }
                    `}
                  >
                    After Ded.
                  </span>
                  <span
                    className={css`
                      @media (max-width: 600px) {
                        display: none;
                      }
                    `}
                  >
                    After Deductible
                  </span>
                </>
              ) : value.ADOrCopay === '100% Covered' ? (
                '100% Covered'
              ) : value.ADOrCopay === 'Not Covered' ? (
                'Not Covered'
              ) : (
                ''
              )}
            </CustomText>
          </Div>
        ) : field.Type === 'surestInputField' ? (
          <Div
            css={css`
              ${flex('right')}
            `}
          >
            {value.lower > 0 && (
              <CustomText
                styles="label bold"
                className={css`
                  margin-right: 8px;
                `}
              >
                {getDisplayValue(value?.lower || '', field)} -
              </CustomText>
            )}
            <CustomText
              styles="label bold"
              className={css`
                margin-right: 8px;
              `}
            >
              {getDisplayValue(value?.upper || '', field)}
            </CustomText>
            <CustomText styles="label bold">{value.upper > 0 ? 'Copay Range' : 'Copay'}</CustomText>
          </Div>
        ) : field.Type === 'prescriptionDrugDeductible' ? (
          <CustomText label bold>
            {value?.Value === -1
              ? 'Medical Deductible Applies'
              : value?.Value === -2
              ? 'None'
              : value?.Value >= 0
              ? getDisplayValue(value?.Value || '', field, 'dollar')
              : null}
          </CustomText>
        ) : null}
      </Div>
      {field.Post && (
        <Text
          className={css`
            ${comparisonView
              ? `
              width: 100%;
              text-align: left;
            `
              : `
              text-align: right;
            `}
            flex-grow: 1;
          `}
        >
          {field.Post}
        </Text>
      )}
    </Div>
  );
};
