import axios from 'axios';
const url = window._env_?.API_URL + `/`;

const axiosInstance = axios.create({
  baseURL: url,
});

export class CustomAxios {
  static setHeader = (key, value) => {
    axiosInstance.defaults.headers.common[key] = value;
  };

  static getHeaders = () => {
    return axiosInstance.defaults.headers;
  };

  static deleteHeader = (key) => {
    delete axiosInstance.defaults.headers.common[key];
  };

  static baseURL = () => {
    return axiosInstance.defaults.baseURL;
  };

  static request = (config) => {
    return axiosInstance.request(config);
  };
  static get = (url, config) => {
    return axiosInstance.get(url, config);
  };
  static delete = (url, config) => {
    return axiosInstance.delete(url, config);
  };
  static head = (url, config) => {
    return axiosInstance.head(url, config);
  };
  static post = (url, data, config) => {
    return axiosInstance.post(url, data, config);
  };
  static put = (url, data, config) => {
    return axiosInstance.put(url, data, config);
  };
  static patch = (url, data, config) => {
    return axiosInstance.patch(url, data, config);
  };
}
