import { useMemo, useState } from 'react';
import { css } from 'emotion';
import parse from 'html-react-parser';
import { get } from 'lodash';
import { Check } from 'phosphor-react';
import { Div, Text } from '../../../shared-components';
import { flex, shadows } from '../../../shared-components/shared-styles';
import { colors } from '../../../shared-components/styles';
import { CarrierLogo } from '../../brite-viewer/components/benefits/carrier-logo';
import { getFieldConfigs } from '../../brite-viewer/components/benefits/configs/config-utils';
import { summaryFieldConfigs } from '../../brite-viewer/components/benefits/configs/product-summary.config';
import { flattenSummaryFields } from '../../brite-viewer/components/benefits/product-layout-constants';
import { CostSummary } from './CostSummary';
import { SummaryField } from './SummaryField';

const customDetailsIsAboveFold = ['hsa_buyup'];

const programs = {
  telemedicine: 'Telemedicine',
  wellness_differential: 'Wellness Differential',
  disease_management_program: 'Disease Management',
  specialty_pharmacy_program: 'Specialty Pharmacy',
  health_reimbursement_arrangement: 'Health Reimbursement Arrangement',
  health_savings_account: 'Health Savings Account',
  flexible_spending_account: 'Flexible Spending Account',
  custom: 'Custom',
};

export const programList = Object.entries(programs);

export const BenefitsSummary = ({ data }) => {
  const {
    product,
    layout,
    includedPrograms,
    meta: { networks, fieldsObject },
  } = data;

  const [hideDisclaimer, setHideDisclaimer] = useState(true);

  const isCustom = product?.Type === 'custom';

  const fieldConfigs = getFieldConfigs(summaryFieldConfigs, {
    product,
    fieldsObject,
  });

  const { aboveFold = [], expandable = [] } = useMemo(() => {
    if (!layout?.Sections) {
      return {};
    }

    const allFields = flattenSummaryFields(product, layout);
    const values = allFields?.reduce(
      (prev, field) => {
        if (field.State === 'hide') {
          return prev;
        }
        const fieldConfig = fieldConfigs?.[field?.PropertyChain] || {};
        if (fieldConfig?.aboveTheFold) {
          return { ...prev, aboveFold: [...prev.aboveFold, field] };
        } else {
          return { ...prev, expandable: [...prev.expandable, field] };
        }
      },
      { aboveFold: [], expandable: [] }
    );
    const aboveFold = values?.aboveFold?.length ? values?.aboveFold : values?.expandable;
    const expandable = !values?.aboveFold?.length ? [] : values?.expandable;
    return { allFields, aboveFold, expandable };
  });

  const hasCostSection = useMemo(() => {
    const idx = layout?.Sections?.findIndex(({ DisplayValue }) => DisplayValue === 'Premiums & Contributions');
    if (idx > -1) {
      const cost = layout?.Sections?.[idx];
      const field = cost?.Fields?.find(({ PropertyChain }) => PropertyChain === 'Cost');
      return field?.State !== 'hide';
    }
    return false;
  });
  const coreData = product?.Type === 'insurance_plan' ? networks.core : product;

  const hasCustomDisclaimer = !!product?.Disclaimer;

  const splitCustomList = customDetailsIsAboveFold.includes(product.Type) || isCustom;

  const customDetailsList = product?.TitleDescriptions?.filter(({ Autogenerated }) => !Autogenerated);

  const customPlanDetails = splitCustomList ? customDetailsList?.slice(0, 5) : [];

  const customDetails = splitCustomList ? customDetailsList?.slice(5) : customDetailsList;

  // Check if any of the custom details have valid values. If so, we will display them.
  // This prevents scenarios where the Other Details section is displayed with no content due to empty values.
  const hasValidCustomDetails =
    customDetails?.length !== 0 && customDetails?.some(({ Title, Description }) => Title !== '' || Description !== '');

  const hasIncludedPrograms = !!Object.entries(includedPrograms || {})?.length && product?.Type !== 'hsa_buyup';

  const conditions = product?.Details?.ConditionPercentages?.filter((item) => item.Name && item.Percentage);
  const hasConditions = product.Type === 'critical_illness' && conditions?.length;

  const hasMoreDetails =
    !!expandable.length || (!!customDetails?.length && hasValidCustomDetails) || hasIncludedPrograms;

  const hasCustomDetailsAbove = !!customDetails?.length;

  const parseDisclaimer = (text) => {
    try {
      var exp = /(?:^|[^"'])((ftp|http|https|file):\/\/[\S]+(\b|$))/gi;
      const disclaimer = text?.replace(exp, " <a href='$1' target='_blank'>$1</a>") || '';
      return parse(disclaimer);
    } catch {
      return '';
    }
  };

  const hasEmbeddedDisclaimer = get(product, 'Details.InNetworkPlanDesign.SingleEmbeddedDeductibleOOPM');

  const disclaimer = parseDisclaimer(product?.Disclaimer);

  const includedProgramTypes = useMemo(() => includedPrograms?.map(({ ProgramType }) => ProgramType), [
    includedPrograms,
  ]);

  return (
    <Div
      css={css`
        ${flex('center')};
        box-sizing: border-box;
      `}
    >
      <Div
        css={css`
          position: relative;
          min-height: 200px;
          width: 100%;
          border-radius: 8px;
          ${shadows.md}
          padding: 24px 32px;
          background-color: white;
        `}
      >
        <Div
          css={css`
            ${flex('row jcsb')};
            padding: 16px 0px;
            gap: 32px;
          `}
        >
          <Div
            css={css`
              width: 50%;
            `}
          >
            {coreData?.Details?.IsMultiCarrier ? (
              <Div
                css={css`
                  display: flex;
                  align-items: center;
                  gap: 16px;
                  justify-content: space-between;
                `}
              >
                <CarrierLogo
                  carrierID={coreData?.ProviderID || '00000000-0000-0000-0000-000000000000'}
                  planCarrierName={coreData?.ProviderName || ''}
                  maxWidth="150px"
                  maxHeight="100px"
                  position="center"
                />
                <Div
                  css={css`
                    border-left: 1px solid #26282d;
                    height: 50px;
                  `}
                />
                <CarrierLogo
                  carrierID={coreData?.Details?.MultiCarrierID || '00000000-0000-0000-0000-000000000000'}
                  planCarrierName={coreData?.Details?.MultiCarrierName || ''}
                  maxWidth="150px"
                  maxHeight="100px"
                  position="center"
                />
              </Div>
            ) : (
              <CarrierLogo
                carrierID={coreData?.ProviderID || '00000000-0000-0000-0000-000000000000'}
                planCarrierName={coreData?.ProviderName || ''}
                maxWidth="350px"
                maxHeight="100px"
                position="center"
              />
            )}
          </Div>
          <Div
            css={css`
              ${flex('end column')} width: 50%;
            `}
          >
            <Text styles="h4">{coreData?.ProductName || 'Benefit Summary'}</Text>
            {isCustom ? <Text>{product?.Description}</Text> : null}
            {product?.Details?.NetworkName ? (
              <Text
                css={`
                  ${flex('center')} padding-top: 8px;
                `}
              >
                {product?.Details?.NetworkName}{' '}
                {product?.Details?.MultiCarrierNetworkName && coreData?.Details?.IsMultiCarrier
                  ? `| ${product?.Details?.MultiCarrierNetworkName}`
                  : ''}
              </Text>
            ) : null}
            {coreData?.Details?.IsMultiCarrier && (
              <Text
                label
                css={`
                  margin-top: 8px;
                  background-color: ${colors.gray[100]};
                  padding: 4px 8px;
                  border-radius: 16px;
                `}
              >
                Multi Carrier
              </Text>
            )}
          </Div>
        </Div>
        {product?.Type === 'insurance_plan' && (
          <Text
            className={css`
              padding: 16px 0;
              padding-bottom: 0;
            `}
          >
            *After Ded. = After Deductible
          </Text>
        )}
        <Div
          css={css`
             {
              display: flex;
              flex-direction: row;
              width: 100%;
              gap: 32px;
            }
          `}
        >
          <Div
            css={css`
               {
                display: flex;
                flex-direction: column;
                flex-basis: 100%;
                flex: 1;
              }
            `}
          >
            <Div styles="alternatingList">
              {hasCostSection && (
                <CostSummary data={data} premiumsSetExternally={data?.product?.Details?.PlanType?.startsWith('aca')} />
              )}
            </Div>
            <Div styles="alternatingList">
              {aboveFold?.map((field) => (
                <SummaryField product={product} field={field} config={fieldConfigs?.[field?.PropertyChain] || {}} />
              ))}
            </Div>
            {hasCustomDetailsAbove && customPlanDetails?.length ? (
              <Div styles="alternatingList">
                {customPlanDetails?.map(({ Title }, idx) => (
                  <SummaryField
                    product={{ TitleDescriptions: customPlanDetails }}
                    field={{
                      DisplayValue: Title,
                      State: 'show',
                      Type: 'text-input',
                      PropertyChain: `TitleDescriptions[${idx}].Description`,
                    }}
                  />
                ))}
              </Div>
            ) : null}
            {hasConditions ? (
              <>
                <Text
                  label
                  bold
                  css={css`
                    padding: 16px 32px;
                  `}
                >
                  Listed Conditions
                </Text>
                <Div styles="alternatingListOdd">
                  {conditions?.map(({ Name }, idx) => {
                    return (
                      <SummaryField
                        product={{
                          Details: { ConditionPercentages: conditions },
                        }}
                        field={{
                          DisplayValue: Name,
                          State: 'show',
                          Type: 'percent',
                          PropertyChain: `Details.ConditionPercentages[${idx}].Percentage`,
                        }}
                      />
                    );
                  })}
                </Div>
              </>
            ) : null}
          </Div>
          {hasMoreDetails && (
            <Div
              css={css`
                 {
                  display: flex;
                  flex-direction: column;
                  flex-basis: 100%;
                  flex: 1;
                  width: 50%;
                }
              `}
            >
              <Div styles="alternatingList">
                {expandable?.map((field) => {
                  return (
                    <SummaryField product={product} field={field} config={fieldConfigs?.[field?.PropertyChain] || {}} />
                  );
                })}
              </Div>
              {hasIncludedPrograms && (
                <>
                  <Text
                    h4
                    css={css`
                      padding: 16px 0px;
                    `}
                  >
                    Included Programs
                  </Text>
                  <Div styles="alternatingListOdd">
                    {programList?.map(([programType, value]) =>
                      includedProgramTypes.includes(programType) ? (
                        <Div
                          key={programType}
                          className="alternating-item"
                          css={css`
                            padding: 8px 24px;
                            ${flex('space-between')}
                            svg {
                              color: var(--button-background-color, ${colors.purple});
                            }
                          `}
                        >
                          <Text label>
                            {includedPrograms?.[programType]?.RawData?.Details?.Name || programs[programType]}
                          </Text>
                          <Check size={24} />
                        </Div>
                      ) : null
                    )}
                  </Div>
                </>
              )}
              {hasValidCustomDetails && (
                <>
                  <Text
                    h4
                    className={css`
                      padding: 16px 0;
                    `}
                  >
                    Other Details
                  </Text>
                  <Div styles="alternatingListOdd">
                    {customDetails?.map(({ Title }, idx) => (
                      <SummaryField
                        product={{ TitleDescriptions: customDetails }}
                        field={{
                          DisplayValue: Title,
                          State: 'show',
                          Type: 'text-input',
                          PropertyChain: `TitleDescriptions[${idx}].Description`,
                        }}
                      />
                    ))}
                  </Div>
                </>
              )}
            </Div>
          )}
        </Div>
        {hasEmbeddedDisclaimer ? (
          <Text
            css={`
              margin: 16px;
              font-size: 0.9em;
            `}
          >
            * All dependents deductibles & OOPM are subject to the single deductible & OOPM.
          </Text>
        ) : null}
        {hasCustomDisclaimer ? (
          <Text
            className={css`
              font-size: 14px;
              padding: 32px;
              margin-top: -16px;
              padding-top: 0;
            `}
          >
            {disclaimer.slice(0, 200)}
            {disclaimer?.length > 200 ? '...' : ''}
            {disclaimer?.length > 200 ? (
              <Text
                as="span"
                onClick={() => setHideDisclaimer(!hideDisclaimer)}
                css={`
                  margin-left: 16px;
                  text-decoration: underline;
                  color: ${colors.black};
                  cursor: pointer;
                  :hover {
                    font-weight: bold;
                  }
                `}
              >
                See All
              </Text>
            ) : null}
          </Text>
        ) : null}
      </Div>
    </Div>
  );
};
