import React, { useMemo, useState } from 'react';

import { motion } from 'framer-motion';
import { css } from 'emotion';
import parse from 'html-react-parser';

import { IconButton, Collapse, Typography } from '@material-ui/core';

import { CaretDown, CaretUp, WarningCircle } from 'phosphor-react';
import { benefitContainer, itemContainer, listContainer, smallScreen } from './product-components/shared-styles';
import Button from '@material-ui/core/Button';
import { posthogAttrs } from '../../../posthog-constants';

export const SpecialHealthcareRecs = (props) => {
  const [learnMoreOpen, setLearnMoreOpen] = useState(false);

  const titleDescriptions = useMemo(() => {
    if (props.benefit && props.benefit.Product && props.benefit.Product.TitleDescriptions) {
      return props.benefit.Product.TitleDescriptions.filter(
        (item) => !item.Autogenerated && !!item.Title && !!item.Description
      );
    }
    return [];
  }, []);

  const replaceURLWithHTMLLinks = (text) => {
    var exp = /(?:^|[^"'])((ftp|http|https|file):\/\/[\S]+(\b|$))/gi;
    return text.replace(exp, " <a href='$1' target='_blank'>$1</a>");
  };

  const parseDisclaimer = (disclaimer) => {
    return parse(replaceURLWithHTMLLinks(disclaimer));
  };

  return (
    <motion.div
      key={'special-health'}
      initial="pageInitial"
      animate="pageAnimate"
      exit="pageExit"
      variants={{
        pageInitial: {
          x: 400,
          opacity: 0,
        },
        pageAnimate: {
          x: 0,
          opacity: 1,
        },
        pageExit: {
          x: -400,
          opacity: 0,
        },
      }}
      className={benefitContainer}
      style={{
        border: `4px solid ${
          props.designStyles?.Theme?.Body?.ButtonColor ? props.designStyles?.Theme?.Body?.ButtonColor : '#25282D'
        }`,
      }}
    >
      <div
        className={css`
          display: flex;
          justify-content: space-between;
          align-items: center;

          ${smallScreen} {
          }
        `}
      >
        <div
          className={css`
            flex-grow: 1;
            margin-right: 16px;
           max-width: 66%"
            ${smallScreen} {
              min-width: 140px;
            }
          `}
        >
          <div>
            <div
              className={css`
                display: flex;
                justify-content: space-between;
                align-items: center;
              `}
            >
              <div
                className={css`
                  font-size: 20px;
                  font-weight: bold;
                  margin: 16px 0;
                  margin-top: 0;
                  flex-grow: 1;
                  text-align: left;

                  @media only screen and (max-width: 600px) {
                    font-size: 16px;
                    margin: 8px 0;
                    padding: 0;
                  }
                `}
              >
                {props.header.split('.').map((str) => (
                  <div
                    className={css`
                      margin-bottom: 4px;
                    `}
                  >
                    {str}
                  </div>
                ))}
              </div>
              {!(props.benefit && props.benefit.Provider && props.benefit.Provider.LogoURL) &&
                props.benefit &&
                props.benefit.Product &&
                props.benefit.Product.CarrierName && (
                  <Typography style={{ fontSize: '12px', margin: '4px 8px' }}>
                    through <strong>{props.benefit.Product.CarrierName}</strong>
                  </Typography>
                )}
            </div>
          </div>
        </div>
        {props.benefit && props.benefit.Provider && props.benefit.Provider.LogoURL ? (
          <div
            className={css`
              display: flex;
              justify-content: center;
              align-items: start;

              flex-grow: 1;
              @media only screen and (max-width: 600px) {
                flex-grow: 1;
                justify-content: end;
                width: 100%;
                padding: 8px 0;
                margin-bottom: 0px;
              }
            `}
          >
            <img
              src={props.benefit.Provider.LogoURL}
              alt="Carrier Logo"
              className={css`
                max-height: 60px;
                max-width: 100px;
                width: auto;
                height: auto;
              `}
            />
          </div>
        ) : null}
      </div>

      <div
        className={css`
          margin-left: -24px;
          margin-right: -24px;
          margin-top: 12px;
        `}
      >
        <div
          className={css`
            background: #f5f7fa;
            font-size: 18px;
            color: #66737f;
            padding: 10px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;

            line-height: 26px;
            margin-left: 16px;
            margin-right: 16px;

            @media only screen and (max-width: 595px) {
              font-size: 14px;
              line-height: 160%;
            }
          `}
        >
          <WarningCircle
            size={24}
            className={css`
              margin-right: 16px;
            `}
          />
          <div
            className={css`
              @media only screen and (max-width: 595px) {
                font-size: 10px;
                line-height: 14px;
              }
            `}
          >
            {props.benefit.Product.CardText}
          </div>
        </div>
      </div>

      <Collapse in={learnMoreOpen} timeout="auto" unmountOnExit style={{ padding: '16px 0' }}>
        {props.benefit.Product.SecondaryText && (
          <div
            className={css`
              font-size: 14px;
              margin-right: 4px;
              padding-bottom: 12px;
              padding: 16px;
            `}
          >
            {props.benefit.Product.SecondaryText}
          </div>
        )}
        <div
          className={css`
            margin-top: 25px;
            margin-bottom: 25px;
            padding-bottom: 25px;
          `}
        >
          <div
            className={css`
              display: flex;
              justify-content: center;
            `}
          >
            <Button
              variant="contained"
              size="large"
              onClick={() => {
                window.open(props.benefit.Product.AdvisorLink, '_blank');
              }}
            >
              Click to Learn More
            </Button>
          </div>
          {props.benefit.Product.AdvisorPhone && (
            <div
              className={css`
                margin-top: 12px;
                display: flex;
                justify-content: center;
              `}
            >
              Advisor Phone Number:
              <br />
              {props.benefit.Product.AdvisorPhone}
            </div>
          )}
        </div>

        {!!titleDescriptions.length && (
          <div className={listContainer}>
            {titleDescriptions.map((item) => (
              <div className={itemContainer} key={item.Title + item.Description}>
                <Typography>{item.Title}</Typography>
                <Typography style={{ fontWeight: 'bold' }}>{item.Description}</Typography>
              </div>
            ))}
          </div>
        )}

        <Typography
          style={{ fontSize: '14px' }}
          className={css`
            padding: 16px;
            margin-top: 30px;
          `}
        >
          *There are other aspects of this benefit to consider, including pricing, coverage limitations, exclusions,
          reductions and other ways this benefit will or will not pay. Please refer to your plan documents for full
          coverage.
        </Typography>

        {props.benefit && props.benefit.Product && props.benefit.Product.Disclaimer && (
          <div
            className={css`
              font-size: 11px;
              font-weight: 400;
              margin: 16px;
              margin-top: 30px;
            `}
          >
            {parseDisclaimer(props.benefit.Product.Disclaimer)}
          </div>
        )}
      </Collapse>
      <div
        className={css`
          display: flex;
          justify-content: center;
          margin-bottom: -20px;
        `}
      >
        <IconButton
          aria-label="Learn more"
          onClick={() => setLearnMoreOpen(!learnMoreOpen)}
          data-ph-capture-attribute-source={posthogAttrs?.types?.RECOMMENDATIONS}
          data-ph-capture-attribute-action={posthogAttrs?.actions?.VIEW_MORE}
          data-ph-capture-attribute-product-id={props?.benefit?.Product?.ID}
          data-ph-capture-attribute-product-type={props?.benefit?.Product?.Type}
        >
          {!learnMoreOpen ? <CaretDown size={24} /> : <CaretUp size={24} />}
        </IconButton>
      </div>
    </motion.div>
  );
};
