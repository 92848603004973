import { css } from 'emotion';
import QRCode from 'react-qr-code';
import { Div, Text } from '../../shared-components';
import { colors } from '../../shared-components/styles';

export const PDFQRText = ({ title = '', description = '', url = '' }) => {
  return (
    <Div
      css={css`
        display: inline-block;
        width: 100%;
      `}
    >
      <Div
        css={css`
          display: flex;
          flex-direction: column;
          padding: 16px;
          margin: 8px;
          border-radius: 16px;
          border: 1px solid ${colors.gray[300]};
        `}
      >
        <Div
          css={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
            overflow-wrap: break-word;
            gap: 16px;
          `}
        >
          <Div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              padding: 8px;
            `}
          >
            {title && <Text h4>{title}</Text>}
            {description !== '' && description?.length <= 100 && (
              <Text
                body
                css={css`
                  word-break: break-all;
                `}
              >
                {description}
              </Text>
            )}
          </Div>
          <Div>
            <QRCode size={80} value={url} />
          </Div>
        </Div>
      </Div>
    </Div>
  );
};
