import { useState } from 'react';
import { css } from 'emotion';
import { Tooltip } from '@material-ui/core';
import { ArrowSquareOut, Check, Globe, X } from 'phosphor-react';
import { useFeatureFlagPayload } from 'posthog-js/react';
import { usePopper } from 'react-popper';
import { posthogAttrs } from '../posthog-constants';
import { Button, Div, Portal, Text } from '../shared-components';
import { colors } from '../shared-components/styles';

// allLanguages is a list of all language codes, whether or not they are enabled for WeGlot, and then how they are written in the locality.
const allLanguages = {
  ab: { name: 'Abkhazian', endonym: 'Аҧсуа (Apsua)' },
  aa: { name: 'Afar', endonym: 'Qafar af' },
  af: { name: 'Afrikaans', endonym: 'Afrikaans' },
  ak: { name: 'Akan', endonym: 'Ákán' },
  sq: { name: 'Albanian', endonym: 'Shqip' },
  am: { name: 'Amharic', endonym: 'አማርኛ' },
  ar: { name: 'Arabic', endonym: 'اَلْعَرَبِيَّةُ' },
  an: { name: 'Aragonese', endonym: 'Aragonés' },
  hy: { name: 'Armenian', endonym: 'Հայերեն' },
  as: { name: 'Assamese', endonym: 'অসমীয়া' },
  av: { name: 'Avaric', endonym: 'Авар мацӏ; اوار ماض ' },
  ay: { name: 'Aymara', endonym: 'Aymara' },
  az: { name: 'Azerbaijani', endonym: 'آذربا یجان دیلی' },
  bm: { name: 'Bambara', endonym: 'بَمَنَنكَن ;ߓߡߊߣߊ߲ߞߊ߲' },
  ba: { name: 'Bashkir', endonym: 'Башҡорт теле  Başqort tele' },
  eu: { name: 'Basque', endonym: 'Euskara' },
  be: { name: 'Belarusian', endonym: 'Беларуская мова' },
  bn: { name: 'Bengali', endonym: 'বাংলা' },
  bi: { name: 'Bislama', endonym: 'Bislama' },
  bs: { name: 'Bosnian', endonym: 'Босански' },
  br: { name: 'Breton', endonym: 'Brezhoneg' },
  bg: { name: 'Bulgarian', endonym: 'Български' },
  my: { name: 'Burmese', endonym: 'မြန်မာစာ' },
  ca: { name: 'Catalan, Valencian', endonym: 'Català; Valencià' },
  km: { name: 'Khmer', endonym: 'ខេមរភាសា' },
  ch: { name: 'Chamorro', endonym: "Finu' Chamoru" },
  ce: { name: 'Chechen', endonym: 'Нохчийн мотт' },
  ny: { name: 'Chichewa, Chewa, Nyanja', endonym: 'Chichewa Chinyanja' },
  'zh-Hans': { name: 'Chinese', endonym: '中文' },
  yue: { name: 'Cantonese', endonym: '广东话' },
  'chk-Latn': { name: 'Chuukese', endonym: 'Chuuk' },
  cv: { name: 'Chuvash', endonym: 'Чӑвашла' },
  kw: { name: 'Cornish', endonym: 'Kernowek' },
  co: { name: 'Corsican', endonym: 'Corsu' },
  cr: { name: 'Cree', endonym: 'ᓀᐦᐃᔭᐁᐧᐃᐧᐣ' },
  hr: { name: 'Croatian', endonym: 'Hrvatski' },
  cs: { name: 'Czech', endonym: 'Čeština' },
  da: { name: 'Danish', endonym: 'Dansk' },
  dv: { name: 'Divehi, Dhivehi, Maldivian', endonym: 'ދިވެހި' },
  nl: { name: 'Dutch, Flemish', endonym: 'Nederlands' },
  dz: { name: 'Dzongkha', endonym: 'རྫོང་ཁ་' },
  en: { name: 'English', endonym: 'English' },
  // "eo": {name:  "Esperanto", endonym: "Esperanto"}, // Constructed
  et: { name: 'Estonian', endonym: 'Eesti keel' },
  ee: { name: 'Ewe', endonym: 'Èʋegbe' },
  fo: { name: 'Faroese', endonym: 'Føroyskt' },
  fj: { name: 'Fijian', endonym: 'Na Vosa Vakaviti' },
  fi: { name: 'Finnish', endonym: 'Suomi' },
  fr: { name: 'French', endonym: 'Français' },
  ff: { name: 'Fula', endonym: '𞤊𞤵𞤤𞤬𞤵𞤤𞤣𞤫 ࢻُلْࢻُلْدٜ' },
  gd: { name: 'Gaelic, Scottish', endonym: 'Gàidhlig' },
  gl: { name: 'Galician', endonym: 'Galego' },
  lg: { name: 'Ganda', endonym: 'Luganda' },
  ka: { name: 'Georgian', endonym: 'ქართული' },
  de: { name: 'German', endonym: 'Deutsch' },
  el: { name: 'Greek', endonym: 'Νέα Ελληνικά' },
  gn: { name: 'Guarani', endonym: "Avañe'ẽ" },
  gu: { name: 'Gujarati', endonym: 'ગુજરાતી' },
  ht: { name: 'Haitian Creole', endonym: 'Kreyòl ayisyen' },
  ha: { name: 'Hausa', endonym: 'هَرْشٜن هَوْس' },
  he: { name: 'Hebrew', endonym: 'עברית' },
  hz: { name: 'Herero', endonym: 'Otjiherero' },
  hi: { name: 'Hindi', endonym: 'हिन्दी ' },
  ho: { name: 'Hiri Motu', endonym: 'Hiri Motu' },
  hu: { name: 'Hungarian', endonym: 'Magyar nyelv' },
  is: { name: 'Icelandic', endonym: 'Íslenska' },
  // "io": {name:  "Ido", endonym: "Ido"}, // Constructed
  ig: { name: 'Igbo', endonym: 'ásụ̀sụ́ Ìgbò' },
  id: { name: 'Indonesian', endonym: 'bahasa Indonesia' },
  // "ia": {name:  "Interlingua (International Auxiliary Language Association)", endonym: "Interlingua"},
  // "ie": {name:  "Interlingue, Occidental", endonym: "Interlingue; Occidental"},
  iu: { name: 'Inuktitut', endonym: 'ᐃᓄᒃᑎᑐᑦ' },
  ik: { name: 'Inupiaq', endonym: 'Iñupiaq' },
  ga: { name: 'Irish', endonym: 'Gaeilge' },
  it: { name: 'Italian', endonym: 'Italiano' },
  ja: { name: 'Japanese', endonym: '日本語' },
  jv: { name: 'Javanese', endonym: 'ꦧꦱꦗꦮ' },
  kl: { name: 'Kalaallisut, Greenlandic', endonym: 'Kalaallisut' },
  kn: { name: 'Kannada', endonym: 'ಕನ್ನಡ' },
  kr: { name: 'Kanuri', endonym: 'كَنُرِيِه' },
  ks: { name: 'Kashmiri', endonym: 'कॉशुर; كأشُر' },
  'kar-Mymr': { name: 'Karen', endonym: 'ကညီကျိာ်' },
  kk: { name: 'Kazakh', endonym: 'Қазақша' },
  ki: { name: 'Kikuyu, Gikuyu', endonym: 'Gĩgĩkũyũ' },
  rw: { name: 'Kinyarwanda', endonym: 'Ikinyarwanda' },
  ky: { name: 'Kirghiz, Kyrgyz', endonym: 'Кыргызча' },
  kv: { name: 'Komi', endonym: 'Коми кыв' },
  kg: { name: 'Kongo', endonym: 'Kikongo' },
  ko: { name: 'Korean', endonym: '한국어' },
  kj: { name: 'Kuanyama', endonym: 'Oshikwanyama' },
  ku: { name: 'Kurdish', endonym: 'کوردی' },
  lo: { name: 'Lao', endonym: 'ພາສາລາວ' },
  lv: { name: 'Latvian', endonym: 'Latviski' },
  li: { name: 'Limburgan', endonym: 'Lèmburgs' },
  ln: { name: 'Lingala', endonym: 'Lingála' },
  lt: { name: 'Lithuanian', endonym: 'Lietuviškai' },
  lu: { name: 'Luba-Katanga', endonym: 'Kiluba' },
  lb: { name: 'Luxembourgish', endonym: 'Lëtzebuergesch' },
  mk: { name: 'Macedonian', endonym: 'Македонски (Makedonski)' },
  mg: { name: 'Malagasy', endonym: 'مَلَغَسِ' },
  ms: { name: 'Malay', endonym: 'بهاس ملايو' },
  ml: { name: 'Malayalam', endonym: 'മലയാളം' },
  mt: { name: 'Maltese', endonym: 'Malti' },
  gv: { name: 'Manx', endonym: 'Gaelg; Gailck' },
  mi: { name: 'Maori', endonym: 'reo Māori' },
  mr: { name: 'Marathi', endonym: 'मराठी (Marāṭhī)' },
  mh: { name: 'Marshallese', endonym: 'kajin M̧ajel‌̧' },
  mn: { name: 'Mongolian', endonym: 'ᠮᠣᠩᠭᠣᠯ ᠬᠡᠯᠡ; Монгол хэл (Mongol xel)' },
  na: { name: 'Nauru', endonym: 'dorerin Naoe' },
  nv: { name: 'Navajo, Navaho', endonym: 'Diné bizaad; Naabeehó bizaad' },
  ng: { name: 'Ndonga', endonym: 'Ndonga' },
  ne: { name: 'Nepali', endonym: 'नेपाली भाषा' },
  nd: { name: 'North Ndebele', endonym: 'isiNdebele; saseNyakatho; Mthwakazi Ndebele' },
  se: { name: 'Northern Sami', endonym: 'Davvisámegiella' },
  no: { name: 'Norwegian', endonym: 'Norsk' },
  nb: { name: 'Bokmål', endonym: 'Norsk Bokmål' },
  nn: { name: 'Norwegian Nynorsk', endonym: 'Norsk Nynorsk' },
  nus: { name: 'Nuer', endonym: 'Thok Naath' },
  oc: { name: 'Occitan', endonym: 'Occitan; Provençal' },
  oj: { name: 'Ojibwa', endonym: 'ᐊᓂᔑᓈᐯᒧᐎᓐ (Anishinaabemowin)' },
  or: { name: 'Oriya', endonym: 'ଓଡ଼ିଆ (Odia)' },
  om: { name: 'Oromo', endonym: 'afaan Oromoo' },
  os: { name: 'Ossetian, Ossetic', endonym: 'Ирон æвзаг (Iron ævzag)' },
  // "pi": {name:  "Pali", endonym: "Pāli"}, // Ancient
  ps: { name: 'Pashto, Pushto', endonym: 'پښتو (Pax̌tow)' },
  fa: { name: 'Persian', endonym: 'فارسی' },
  pon: { name: 'Pohnpeian', endonym: 'Lokaiahn Pohnpei' },
  pl: { name: 'Polish', endonym: 'Polski' },
  pt: { name: 'Portuguese', endonym: 'Português' },
  pa: { name: 'Punjabi, Panjabi', endonym: 'ਪੰਜਾਬੀ; پنجابی (Pãjābī)' },
  'fuc-Arab': { name: 'Pulaar (Arabic)', endonym: 'بولار' },
  qu: { name: 'Quechua', endonym: 'Runa simi; kichwa simi; Nuna shimi' },
  ro: { name: 'Moldovan, Romanian', endonym: 'Românește' },
  'rhg-Rohg': { name: 'Rohingya (Hanifi)', endonym: '𐴌𐴟𐴇𐴥𐴝𐴚𐴒𐴙𐴝 𐴇𐴝𐴕𐴞𐴉𐴞 𐴓𐴠𐴑𐴤𐴝' },
  rm: { name: 'Romansh', endonym: 'Rumantsch; Rumàntsch; Romauntsch; Romontsch' },
  rn: { name: 'Rundi', endonym: 'Ikirundi' },
  ru: { name: 'Russian', endonym: 'Русский язык' },
  sm: { name: 'Samoan', endonym: 'gagana Sāmoa' },
  sg: { name: 'Sango', endonym: 'yângâ tî Sängö' },
  // "sa": {name:  "Sanskrit", endonym: "संस्कृतम् (Saṃskṛtam)"}, // Ancient
  sc: { name: 'Sardinian', endonym: 'Sardu' },
  'sr-Cyrl': { name: 'Serbian', endonym: 'Српски' },
  sn: { name: 'Shona', endonym: 'chiShona' },
  ii: { name: 'Sichuan Yi, Nuosu', endonym: 'ꆈꌠ꒿ Nuosuhxop' },
  sd: { name: 'Sindhi', endonym: 'سنڌي; सिन्धी (Sindhī)' },
  si: { name: 'Sinhala, Sinhalese', endonym: 'සිංහල (Siṁhala)' },
  sk: { name: 'Slovak', endonym: 'Slovenčina' },
  sl: { name: 'Slovenian', endonym: 'Slovenščina' },
  so: { name: 'Somali', endonym: '𐒈𐒝𐒑𐒛𐒐𐒘' },
  nr: { name: 'South Ndebele', endonym: 'isiNdebele sakwaNdzundza' },
  st: { name: 'Southern Sotho', endonym: 'Sesotho' },
  es: { name: 'Spanish', endonym: 'Español' },
  su: { name: 'Sundanese', endonym: 'ᮘᮞ ᮞᮥᮔ᮪ᮓ بَاسَا سُوْندَا' },
  sw: { name: 'Swahili', endonym: 'Kiswahili' },
  ss: { name: 'Swati', endonym: 'siSwati' },
  sv: { name: 'Swedish', endonym: 'Svenska' },
  tl: { name: 'Tagalog', endonym: 'Wikang Tagalog' },
  ty: { name: 'Tahitian', endonym: 'reo Tahiti' },
  tg: { name: 'Tajik', endonym: 'Тоҷикӣ (Tojikī)' },
  ta: { name: 'Tamil', endonym: 'தமிழ் (Tamiḻ)' },
  tt: { name: 'Tatar', endonym: 'Татар теле, Tatar tele, تاتار تئلئ' },
  te: { name: 'Telugu', endonym: 'తెలుగు (Telugu)' },
  th: { name: 'Thai', endonym: 'ภาษาไทย' },
  bo: { name: 'Tibetan', endonym: "བོད་སྐད་ (Bodskad), ལྷ་སའི་སྐད་ (Lhas'iskad)" },
  ti: { name: 'Tigrinya', endonym: 'ትግርኛ' },
  to: { name: 'Tonga language', endonym: 'lea faka-Tonga' },
  ts: { name: 'Tsonga', endonym: 'Xitsonga' },
  tn: { name: 'Tswana', endonym: 'Setswana' },
  tr: { name: 'Turkish', endonym: 'Türkçe' },
  tk: { name: 'Turkmen', endonym: 'Türkmençe, Түркменче, تۆرکمنچه' },
  tw: { name: 'Twi', endonym: 'Twi' },
  ug: { name: 'Uighur, Uyghur', endonym: 'ئۇيغۇر تىلى, Уйғур тили, Uyƣur tili' },
  uk: { name: 'Ukrainian', endonym: 'Українська' },
  ur: { name: 'Urdu', endonym: 'اُردُو' },
  uz: { name: 'Uzbek', endonym: 'Ózbekça, ўзбекча; ئوزبېچه' },
  ve: { name: 'Venda', endonym: 'Tshivenḓa' },
  vi: { name: 'Vietnamese', endonym: 'tiếng Việt' },
  // "vo": {name:  "Volapük", endonym: "Volapük"}, // Constructed
  wa: { name: 'Walloon', endonym: 'Walon' },
  cy: { name: 'Welsh', endonym: 'Cymraeg' },
  fy: { name: 'Western Frisian', endonym: 'Frysk' },
  wo: { name: 'Wolof', endonym: 'وࣷلࣷفْ لࣵکّ' },
  xh: { name: 'Xhosa', endonym: 'isiXhosa' },
  yi: { name: 'Yiddish', endonym: 'ייִדיש' },
  yo: { name: 'Yoruba', endonym: 'èdè Yorùbá' },
  za: { name: 'Zhuang, Chuang', endonym: '話僮' },
  zu: { name: 'Zulu', endonym: 'isiZulu' },
};

const getWeGlotLanguages = () => {
  if (!window.Weglot) {
    return;
  }

  const currentLanguage = window.Weglot.getCurrentLang();

  const supportedLanguages = window.Weglot.options.languages
    .filter((language) => allLanguages.hasOwnProperty(language.language_to) && language.enabled)
    .map((language) => ({
      code: language.language_to,
      name: allLanguages[language.language_to].name,
      endonym: allLanguages[language.language_to].endonym,
    }))
    .concat({ code: 'en', name: 'English', endonym: 'English' });

  // Sort supported languages by name.
  supportedLanguages.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

  // Find and move currentLanguage to the top of the array.
  const currentLangIndex = supportedLanguages.findIndex((lang) => lang.code === currentLanguage);
  if (currentLangIndex !== -1) {
    const [currentLang] = supportedLanguages.splice(currentLangIndex, 1);
    supportedLanguages.unshift(currentLang);
  }

  return supportedLanguages;
};

export const BriteLanguageSelector = ({ guideID, changeLanguageCallback }) => {
  const [languageSelectionOpen, setLanguageSelectionOpen] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, { placement: 'bottom-end' });

  const languageIntegrationFF = useFeatureFlagPayload('integrated-language-translation');

  if (!languageIntegrationFF?.value || !window.Weglot) {
    return null;
  }

  const languageSelectorId = 'brite-language-switcher';

  const currentLanguage = window.Weglot.getCurrentLang();

  return (
    <>
      <Tooltip title={'Language'}>
        {/* Converted this button from a Material UI IconButton to a Button component from shared-components. */}
        {/* The -12px margin-right is to match the original Material UI IconButton styling. */}
        <Button
          ref={setReferenceElement}
          id={languageSelectorId}
          css={css`
             {
              padding: 0px 12px;
              margin-right: -12px;
            }
          `}
          icon
          data-ph-capture-attribute-source={posthogAttrs.types.NAVIGATION}
          data-ph-capture-attribute-action={posthogAttrs.actions.LANGUAGE}
          aria-label="Language"
          onClick={(e) => {
            setReferenceElement(e.currentTarget);
            setLanguageSelectionOpen(!languageSelectionOpen);
          }}
        >
          <Globe />
        </Button>
      </Tooltip>
      {languageSelectionOpen && (
        <Portal>
          {/* Remove z-index once we remove grommet and MUI. */}
          <Div
            id="language-selection-modal"
            ref={setPopperElement}
            css={css`
              display: flex;
              flex-direction: column;
              width: 400px;
              border-radius: 16px;
              background: var(--000, #fff);
              box-shadow: 0px 4px 10px 0px rgba(37, 40, 45, 0.3);
              z-index: 9999;
            `}
            style={styles.popper}
            {...attributes.popper}
          >
            <Div
              css={css`
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 8px 0px 8px 16px;
                border-bottom: 1px solid var(--300, #d1dae3);
              `}
            >
              <Text h4>Select a Language</Text>
              <Button
                icon
                css={css`
                  padding: 0px;
                `}
                onClick={() => setLanguageSelectionOpen(false)}
              >
                <X size={24} weight="bold" />
              </Button>
            </Div>
            <Div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 8px;
                max-height: 400px;
                overflow-y: auto;
              `}
            >
              {getWeGlotLanguages().map((language) => {
                return (
                  <Div
                    key={language.code}
                    css={css`
                      display: flex;
                      flex-direction: row;
                      justify-center: flex-start;
                      padding: 8px;
                      gap: 16px;
                      align-self: stretch;
                      :hover {
                        border-radius: 8px;
                        background: var(--100, #f5f7fa);
                      }
                      ${currentLanguage === language.code
                        ? `                     
                        border-radius: 8px;
                        background: var(--100, #f5f7fa);
                        `
                        : ''}
                    `}
                    onClick={() => {
                      window.Weglot.switchTo(language.code);
                      changeLanguageCallback(language.code);
                    }}
                  >
                    <Div
                      css={css`
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                      `}
                    >
                      <Div
                        css={css`
                          display: flex;
                          flex-direction: column;
                        `}
                      >
                        <Text body bold color={colors.black}>
                          {language.endonym}
                        </Text>
                        <Text
                          caption
                          css={css`
                            font-size: 12px;
                          `}
                        >{`(${language.name})`}</Text>
                      </Div>
                      {currentLanguage === language.code && (
                        <Div
                          css={css`
                            display: flex;
                            justify-content: center;
                            align-items: center;
                          `}
                        >
                          <Div
                            css={css`
                              min-width: 24px;
                              width: 24px;
                              height: 24px;
                              border-radius: 8px;
                              background-color: ${colors.black};
                              border: 1px solid ${colors.black};
                              color: #ffffff;
                            `}
                          >
                            <Check color="currentColor" size={24} weight="bold" />
                          </Div>
                        </Div>
                      )}
                    </Div>
                  </Div>
                );
              })}
            </Div>
            <Div
              css={css`
                display: flex;
                flex-direction: row;
                justify-center: flex-start;
                padding: 8px;
                gap: 16px;
                align-self: stretch;
                border-top: 1px solid var(--300, #d1dae3);
              `}
            >
              <Div
                css={css`
                  display: flex;
                  justify-content: space-between;
                  width: 100%;
                  padding: 8px;
                  gap: 16px;
                  :hover {
                    border-radius: 8px;
                    background: var(--100, #f5f7fa);
                  }
                `}
                onClick={() => {
                  const languageCode = 'mh'; // hard-coding to Marshallese for now since Tyson Foods has this in their top 10, and we don't support via WeGlot.
                  // Setting the language code to English before beginning translation to non-supported languages via google translate.
                  window.Weglot.switchTo('en');
                  window.open(
                    'https://britehr-app.translate.goog/' +
                      guideID +
                      '/1?_x_tr_sl=en&_x_tr_tl=' +
                      languageCode +
                      '&_x_tr_hl=en&_x_tr_pto=wapp',
                    '_blank'
                  );
                  setLanguageSelectionOpen(false);
                }}
              >
                <Div
                  css={css`
                    display: flex;
                    align-items: center;
                  `}
                >
                  <Text body bold color={colors.black}>
                    Other
                  </Text>
                </Div>
                <Div
                  css={css`
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 8px;
                    gap: 8px;
                  `}
                >
                  <Text>Google Translate</Text>
                  <ArrowSquareOut size={24} color={colors.gray[500]} weight="bold" />
                </Div>
              </Div>
            </Div>
          </Div>
        </Portal>
      )}
    </>
  );
};
