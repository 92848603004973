import React, { useRef, useState } from 'react';
import { Menu, Tooltip } from '@material-ui/core';
import { noop } from 'lodash';
import { css } from 'emotion';

const clearButton = css`
  border: none;
  background: none;
  outline: none;
  appearance: none;
`;

export const DropMenu = ({
  title,
  button,
  children,
  listDirection = 'row',
  className = 'drop-menu-styles',
  listClassName = '',
  onClick = noop,
  onClose = noop,
  ...rest
}) => {
  const ref = useRef(null);
  const [display, setDisplay] = useState(false);

  const handleClick = (event) => {
    event.stopPropagation();
    setDisplay(true);
    onClick(event);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setDisplay(false);
    onClose();
  };

  const directionStyle =
    listDirection === 'row'
      ? {}
      : {
          MenuListProps: {
            style: {
              display: 'flex',
            },
          },
        };

  return (
    <div className={className} ref={ref}>
      {title ? (
        <Tooltip title={title} placement="top">
          <div className={clearButton} onClick={handleClick}>
            {button}
          </div>
        </Tooltip>
      ) : (
        <div className={clearButton} onClick={handleClick}>
          {button}
        </div>
      )}
      <Menu
        id="more-menu"
        anchorEl={ref.current}
        getContentAnchorEl={null}
        keepMounted
        open={display}
        onClose={handleClose}
        {...directionStyle}
        {...rest}
      >
        <div onClick={handleClose} className={listClassName}>
          {children}
        </div>
      </Menu>
    </div>
  );
};
