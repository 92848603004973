import React, { Component } from 'react';
import { css } from 'emotion';

import { motion, AnimatePresence } from 'framer-motion';
import { RecommendationList } from './RecommendationList';
import posthog from '../../../lib/analytics/posthog';
import RecLoader from '../rec-load.svg';

export class RecommendationViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingPage: 1,
      showMore: false,
    };
  }

  componentDidMount() {
    this.props.setHideButton(true);
    this.startLoadingInterval();
    //this.props.saveDecisionToolAnswers();

    posthog.capture('Decision Tool View Results', {});
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.healthCareRecommendationLoading !== prevProps.healthCareRecommendationLoading &&
      this.props.healthCareRecommendationLoading === false &&
      prevProps.healthCareRecommendationLoading === true
    ) {
      clearInterval(this.intervalId);
      this.setState({ loadingPage: 4 });
      this.props.setHideButton(false);
    }
  }

  componentWillUnmount() {
    this.props.setHideButton(false);
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  startLoadingInterval = () => {
    this.intervalId = setInterval(() => this.updateLoadingPage(), 2200);
  };

  updateLoadingPage = () => {
    this.setState((prevState) => {
      let loadingPage = prevState.loadingPage + 1;
      if (loadingPage === 3) {
        clearInterval(this.intervalId);
      }
      return {
        loadingPage,
      };
    });
  };

  render() {
    return (
      <AnimatePresence>
        <motion.div
          key={'recviewernotlist_question'}
          initial="pageInitial"
          animate="pageAnimate"
          exit="pageExit"
          variants={{
            pageInitial: {
              x: 400,
              opacity: 0,
            },
            pageAnimate: {
              x: 0,
              opacity: 1,
            },
            pageExit: {
              x: -400,
              opacity: 0,
            },
          }}
          className={css`
            text-align: center;
            margin-bottom: 50px;
          `}
        >
          {(this.state.loadingPage === 1 || this.state.loadingPage === 2 || this.state.loadingPage === 3) && (
            <motion.div
              key={'loading-page-3'}
              initial="pageInitial"
              animate="pageAnimate"
              exit="pageExit"
              variants={{
                pageInitial: {
                  opacity: 0,
                },
                pageAnimate: {
                  opacity: 1,
                },
                pageExit: {
                  x: -400,
                  opacity: 0,
                },
              }}
              className={css`
                text-align: center;
                margin-bottom: 50px;
                padding-top: 10px;
              `}
            >
              <div className={css``}>
                <img src={RecLoader} />
                <div
                  className={css`
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 22px;
                    line-height: 133.4%;

                    text-align: center;

                    color: #25282d;
                    margin-bottom: 12px;
                    margin-top: 38px;
                  `}
                >
                  Preparing Your Recommendations
                </div>
                <div
                  className={css`
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 160%;
                    /* identical to box height, or 26px */

                    text-align: center;
                    letter-spacing: 0.15px;

                    color: #66737f;
                  `}
                >
                  This should only take a sec
                </div>
              </div>
            </motion.div>
          )}

          {this.state.loadingPage === 4 && (
            <RecommendationList
              oldPageIndex={this.props.oldPageIndex}
              setCurrentPageIndex={this.props.setCurrentPageIndex}
              setOnRecommendationListPage={this.props.setOnRecommendationListPage}
              selectedBenefits={this.props.selectedBenefits}
              setSelectedBenefits={this.props.setSelectedBenefits}
              restart={this.props.restart}
              page={this.props.page}
              medicalHistory={this.props.medicalHistory}
              hideMedicalPlans={this.props.hideMedicalPlans}
              showMonthlyCosts={this.props.showMonthlyCosts}
              answers={this.props.answers}
              healthCareRecommendation={this.props.healthCareRecommendation}
              customQuestionsAnswers={this.props.customQuestionsAnswers}
              customQuestions={this.props.customQuestions}
              allCustomProducts={this.props.allCustomProducts}
              allCoreProducts={this.props.allCoreProducts}
              spendingAccounts={this.props.spendingAccounts}
              allAdditionalProducts={this.props.allAdditionalProducts}
              recommendedCustomProducts={this.props.recommendedCustomProducts}
              displaySettings={this.props.displaySettings}
              setRecommendedCustomProducts={this.props.setRecommendedCustomProducts}
              showRecommendationResults={this.props.showRecommendationResults}
              setShowRecommendationResults={this.props.setShowRecommendationResults}
              shareClicked={this.props.shareClicked}
              closeClicked={this.props.closeClicked}
              setRecId={this.props.setRecId}
              recId={this.props.recId}
              setOriginalRecs={this.props.setOriginalRecs}
              setUserSelectedRecs={this.props.setUserSelectedRecs}
              setDecisionToolOpen={this.props.setDecisionToolOpen}
              designStyles={this.props.designStyles}
            />
          )}
        </motion.div>
      </AnimatePresence>
    );
  }
}
