import React, { useState, useEffect } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Slide from '@material-ui/core/Slide';
import ListSubheader from '@material-ui/core/ListSubheader';
import Tooltip from '@material-ui/core/Tooltip';
import { CustomAxios } from '../axios/axios.js';
import { MagnifyingGlass, X } from 'phosphor-react';
import { useDebounce } from './brite-viewer/use-debounce';
import { useFeatureFlagPayload } from 'posthog-js/react';
import { Loader } from '../shared-components/loader.js';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    borderBottom: '1px solid #E8EDF3',
  },
  search: {
    position: 'relative',
    flex: 1,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'transparent',

    display: 'flex',
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
  },
  searchIcon: {
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    flex: 1,
  },
  primary: {
    fontFamily: 'Roboto',

    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '24px',

    letterSpacing: '0.15px',

    color: '#25282D',
  },

  secondary: {
    fontFamily: 'Roboto',

    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',

    letterSpacing: '0.15px',

    color: '#66737F',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(5)}px)`,

    transition: theme.transitions.create('width'),
    width: '100%',
    flex: 1,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '24px',
    /* identical to box height, or 150% */

    letterSpacing: 0.15,

    color: '#25282D',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function strip(html, json) {
  // some of our pages don't have HTMLContent, so we'll just search the JSON content as a work-around for now.
  if (!html) {
    return JSON.stringify(json);
  }
  let doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || '';
}

export default function SearchModal(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(true);
  const [filteredStates, setFilteredStates] = useState([]);
  const smartSearchFF = useFeatureFlagPayload('smart-search');
  const chatbotFF = useFeatureFlagPayload('chatbot');
  const [chatbotResponse, setChatbotResponse] = useState(null);

  // dumb search does a simple string matchin search and always sets the results
  const dumbSearch = () => {
    let filter = props.pages.filter((page) => {
      const strippedString = strip(page.HTMLContent, page.Content);

      return strippedString.toLowerCase().includes(search.toLowerCase().trim());
    });

    for (let page of filter) {
      const strippedString = strip(page.HTMLContent, page.Content);
      if (!page.HTMLContent) {
        continue;
      }
      let indexOfSearchTerm = strippedString.toLowerCase().indexOf(search.toLowerCase().trim());
      let lengthOfContent = strippedString.length;
      let startIndex = indexOfSearchTerm - 40;
      let endIndex = indexOfSearchTerm + 40;
      let endSet = false;
      if (endIndex > lengthOfContent) {
        endIndex = lengthOfContent - 1;
        endSet = true;
      }
      if (startIndex < 0) {
        startIndex = 0;
      }

      let excerpt = strippedString.substring(startIndex, endIndex);

      if (startIndex !== 0) {
        excerpt = `...${excerpt}`;
      }

      if (!endSet) {
        excerpt = `${excerpt}...`;
      }

      page.excerpt = excerpt;
    }

    if (!search) {
      filter = [];
    }

    setLoading(false);
    setFilteredStates(filter);
  };

  // smartSearch sends the search to the API; it only sets the results if useResults is true; if it is expecting to use results and something fails, it falls back to the dumb search
  const smartSearch = useDebounce(async (useResults) => {
    if (!search) {
      return;
    }
    let gotResults = false;
    try {
      const resp = await CustomAxios.get(`/public/v1/guide/${props.courseId}/search?search=${search}`);
      if (chatbotFF.value && resp?.data?.Message) {
        setChatbotResponse(resp.data.Message);
      }
      if (resp?.data?.RelevantURLs?.length) {
        gotResults = true;
        if (useResults) {
          setLoading(false);
          setFilteredStates(
            resp?.data?.RelevantURLs.map((result) => ({
              ID: result.PageID,
              Name: result.DisplayName,
            }))
          );
        }
      }
    } catch (e) {
      console.log('error doing smart search', e);
    } finally {
      if (useResults && !gotResults) {
        dumbSearch();
      }
    }
  }, 1500);

  useEffect(() => {
    if (!search) {
      setFilteredStates([]);
      return;
    }
    setLoading(true);
    const timer = setTimeout(() => {
      if (smartSearchFF?.value) {
        smartSearch(true);
      } else {
        dumbSearch();
        // still execute the smart search so we can log the search and results
        smartSearch(false);
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [search]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSearch('');
  };

  const goToPage = (order) => {
    let index = 0;
    for (let page of props.pages) {
      if (page.Order === order) {
        break;
      }
      index++;
    }
    props.setCurrentPageIndex(index);
    setOpen(false);
    setSearch('');
  };

  const goToPageId = (pageId) => {
    let index = 0;
    for (let page of props.pages) {
      if (page.ID === pageId) {
        break;
      }
      index++;
    }
    props.setCurrentPageIndex(index);
    setOpen(false);
    setSearch('');
  };

  return (
    <div>
      <Tooltip PopperProps={{ style: { zIndex: 234234 } }} title={'Search'}>
        <IconButton color="inherit" aria-label="Search" onClick={handleClickOpen} edge="end">
          <MagnifyingGlass />
        </IconButton>
      </Tooltip>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar} color="transparent" elevation="0">
          <Toolbar>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <MagnifyingGlass />
              </div>
              <InputBase
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                autoFocus
                inputProps={{ 'aria-label': 'search' }}
              />
            </div>

            <IconButton edge="start" aria-label="Close Search" color="inherit" onClick={handleClose}>
              <X />
            </IconButton>
          </Toolbar>
        </AppBar>
        <List
          style={{ marginTop: '32px' }}
          subheader={
            <ListSubheader
              disableSticky={true}
              component="div"
              id="nested-list-subheader"
              style={{
                paddingLeft: '28px',
                fontFamily: 'Roboto',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '24px',

                letterSpacing: '0.15px',

                color: '#66737F',
                marginBottom: '16px',
              }}
            >
              Search Results
            </ListSubheader>
          }
        >
          {loading && search ? (
            <Loader />
          ) : (
            search &&
            filteredStates &&
            filteredStates.length === 0 && (
              <ListItem
                style={{
                  padding: '16px',
                  paddingLeft: '28px',
                  paddingRight: '28px',
                }}
                button
                onClick={() => {}}
              >
                <ListItemText
                  primary={'No Results'}
                  classes={{
                    primary: classes.primary,
                    secondary: classes.secondary,
                  }}
                />
              </ListItem>
            )
          )}
          {chatbotResponse && (
            <ListItem
              style={{
                padding: '16px',
                paddingLeft: '28px',
                paddingRight: '28px',
              }}
            >
              <ListItemText
                classes={{
                  primary: classes.primary,
                  secondary: classes.secondary,
                }}
                primary={chatbotResponse}
              />
            </ListItem>
          )}
          {filteredStates.map((page) => {
            return (
              <React.Fragment key={page.ID}>
                <ListItem
                  style={{
                    padding: '16px',
                    paddingLeft: '28px',
                    paddingRight: '28px',
                  }}
                  button
                  onClick={() => {
                    if (!page.Order) {
                      goToPageId(page.ID);
                    } else {
                      goToPage(page.Order);
                    }
                  }}
                >
                  <ListItemText
                    classes={{
                      primary: classes.primary,
                      secondary: classes.secondary,
                    }}
                    primary={page.Name}
                    secondary={page.excerpt}
                  />
                </ListItem>
                <Divider />
              </React.Fragment>
            );
          })}
        </List>
      </Dialog>
    </div>
  );
}
