import React, { Component } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { motion } from 'framer-motion';
import BriteRadio from './inputs/BriteRadio';
import { css } from 'emotion';

export class DecisionToolBabyQuestion extends Component {
  render() {
    return (
      <motion.div
        key={`baby_question${this.props.type}`}
        initial="pageInitial"
        animate="pageAnimate"
        exit="pageExit"
        variants={{
          pageInitial: {
            y: 400,
            opacity: 0,
          },
          pageAnimate: {
            y: 0,
            opacity: 1,
          },
          pageExit: {
            y: -400,
            opacity: 0,
          },
        }}
        className={css`
          background: #ffffff;

          box-shadow: 0px 2px 8px rgba(37, 40, 45, 0.25);
          border-radius: 8px;
          padding: 24px;
          margin-bottom: 32px;
          text-align: left;
        `}
      >
        <h1
          className={css`
            color: #25282d;
            font-size: 23px;
            line-height: 32px;
            margin-block-start: 0px;
            margin-block-end: 24px;
          `}
        >
          {' '}
          {this.props.type === 'personal'
            ? this.props.isSurvey
              ? 'Are you planning to have a baby within the next 24 months?'
              : 'Are you planning to have a baby this year?'
            : this.props.isSurvey
            ? 'Are any of your dependents planning to have a baby within the next 24 months?'
            : 'Are any of your dependents planning to have a baby this year?'}
          {!this.props.isSurvey && this.props.info}
        </h1>

        {this.props.shouldDisplayDependentQuestions && (
          <div
            className={css`
              font-family: 'Roboto';
              font-style: normal;
              font-weight: 700;
              font-size: 18px;
              line-height: 24px;
              /* identical to box height, or 133% */

              letter-spacing: 0.15px;

              color: #25282d;
              margin-top: 16px;
            `}
          >
            For you
          </div>
        )}
        <div
          className={css`
            margin-top: 25px;
          `}
        >
          <BriteRadio
            aria-label="baby"
            name="baby"
            value={this.props.baby}
            options={[
              { value: true, label: 'Yes' },
              { value: false, label: 'No' },
            ]}
            onClick={(value) => {
              this.props.setBaby(value);
            }}
          />
        </div>

        {this.props.shouldDisplayDependentQuestions && (
          <>
            <div
              className={css`
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 24px;
                /* identical to box height, or 133% */

                letter-spacing: 0.15px;

                color: #25282d;
                margin-top: 24px;
              `}
            >
              For your dependents
            </div>

            <div
              className={css`
                margin-top: 25px;
              `}
            >
              <BriteRadio
                aria-label="baby"
                name="baby"
                value={this.props.dependantBaby}
                options={[
                  { value: true, label: 'Yes' },
                  { value: false, label: 'No' },
                ]}
                onClick={(value) => {
                  this.props.setDependantBaby(value);
                }}
              />
            </div>
          </>
        )}

        <div
          className={css`
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 24px;
            /* identical to box height, or 200% */

            letter-spacing: 0.15px;

            color: #9aa7b5;
            margin-top: 16px;
          `}
        >
          {this.props.questionDisclaimerText}
        </div>
      </motion.div>
    );
  }
}
