import React, { forwardRef, useMemo } from 'react';
import { css } from 'emotion';
import { buildStyles, colors } from './styles';

export const inputStyles = {
  primary: `
    padding: 8px;
    outline: none;
    border-radius: 8px;
    border: 1px solid ${colors.gray[400]};
    font-size: 1em;
  `,
};

const fallbackFromList = ['primary'];
const checkForFallback = (styles) => {
  const list = fallbackFromList.filter((attr) => styles.includes(attr));
  if (!list.length) {
    return `primary ${styles}`;
  }
  return styles;
};

export const Input = forwardRef(({ styles = '', className = '', color, bgColor, state = null, ...rest }, ref) => {
  const cssStyles = useMemo(() => {
    const taggedStyle = checkForFallback(styles);
    return css`
      margin: 0;
      ${buildStyles(inputStyles, taggedStyle)}
      ${color ? `color: ${color};` : ''}
      ${bgColor ? `background-color: ${bgColor};` : ''}
      ${className}
    `;
  }, [styles, className, color, bgColor]);

  const stateHandler = useMemo(() => {
    if (state) {
      const [value, setValue] = state;
      return { value, onChange: (e) => setValue(e.target.value) };
    }
    return {};
  }, [state]);

  const props = { className: cssStyles, ...stateHandler, ...rest, ref };

  return <input {...props} />;
});
