import React from 'react';
import { motion } from 'framer-motion';
import { css } from 'emotion';
import { useFeatureFlagPayload } from 'posthog-js/react';
import { Text } from '../../../shared-components';
import BriteRadio from './inputs/BriteRadio';
import { container, flex } from '../../../shared-components/shared-styles';
import { Check, Minus, Plus, User, UserCirclePlus, UserPlus } from 'phosphor-react';
import { colors } from '../../../shared-components/styles';

export const DecisionToolEnrollingQuestion = (props) => {
  const selectedTypes = props?.acaEnrollingMembers?.map(({ Type }) => Type);
  const handleSelection = (Type) => {
    // you cannot remove yourself from the list
    if (Type !== 'Self' && selectedTypes?.includes(Type)) {
      const list = props?.acaEnrollingMembers?.filter((member) => member?.Type !== Type);
      props.setAcaEnrollingMembers(list);
    } else {
      if (selectedTypes?.includes(Type)) {
        return;
      }

      props.setAcaEnrollingMembers([...props?.acaEnrollingMembers, { Type }]);
    }
  };

  const addRemoveChildren = (event, action) => {
    event?.stopPropagation();
    if (action === 'add') {
      props.setAcaEnrollingMembers([...props?.acaEnrollingMembers, { Type: 'Child' }]);
    } else if (action === 'remove') {
      const index = props?.acaEnrollingMembers?.findIndex(({ Type }) => Type === 'Child');
      const list = props?.acaEnrollingMembers?.filter((_, idx) => idx !== index);
      props.setAcaEnrollingMembers(list);
    }
  };

  const childrenCount = selectedTypes?.filter((type) => type === 'Child')?.length;

  // onClick = handleSelection("Self")
  // props?.displaySettings?.custom_label_employee
  // props?.displaySettings?.custom_label_employee_spouse
  // props?.displaySettings?.custom_label_child

  return (
    <motion.div
      key={'enrolling_question'}
      initial="pageInitial"
      animate="pageAnimate"
      exit="pageExit"
      variants={{
        pageInitial: {
          y: 400,
          opacity: 0,
        },
        pageAnimate: {
          y: 0,
          opacity: 1,
        },
        pageExit: {
          y: -400,
          opacity: 0,
        },
      }}
      className={css`
        background: #ffffff;
        box-shadow: 0px 2px 8px rgba(37, 40, 45, 0.25);
        border-radius: 8px;
        padding: 24px;
        margin-bottom: 32px;
        text-align: left;
      `}
    >
      <h1
        className={css`
          color: #25282d;
          font-size: 23px;
          line-height: 32px;
          margin-block-start: 0px;
          margin-block-end: 24px;
        `}
      >
        {props.isSurvey ? 'Who do you plan on enrolling?' : 'Who are you enrolling?'}
      </h1>

      <div>
        <div
          className={css`
            gap: 16px;
            box-sizing: border-box;
            user-select: none;
            .check {
              ${flex('center')}
              width: 32px;
              height: 32px;
              border-radius: 50%;
            }
            .checked {
              background-color: var(--button-background-color);
              color: var(--button-text-color);
            }
            .unchecked {
              background-color: ${colors.gray[400]};
              color: white;
            }
            .container {
              border: 2px solid ${colors.gray[300]};
              border-radius: 16px;
              padding: 24px 32px;
              margin-bottom: 24px;
              ${flex('space-between')}
              ${container.hover}
            }
          `}
        >
          <div className="container">
            <div
              className={css`
                ${flex('left grow')}
              `}
            >
              <User />
              <Text
                h4
                css={`
                  margin-left: 16px;
                `}
              >
                {props?.displaySettings?.custom_label_employee}
              </Text>
            </div>
            <div className="check checked">
              <Check size={24} color="currentColor" />
            </div>
          </div>

          <div className="container" onClick={() => handleSelection('Spouse')}>
            <div
              className={css`
                ${flex('left grow')}
              `}
            >
              <UserPlus />
              <Text
                h4
                css={`
                  margin-left: 16px;
                `}
              >
                {props?.displaySettings?.custom_label_employee_spouse}
              </Text>
            </div>
            <div className={`check ${selectedTypes?.includes('Spouse') ? 'checked' : 'unchecked'}`}>
              {selectedTypes?.includes('Spouse') ? (
                <Check size={24} color="currentColor" />
              ) : (
                <Plus size={24} color="currentColor" />
              )}
            </div>
          </div>

          <div className="container" onClick={(e) => addRemoveChildren(e, 'add')}>
            <div
              className={css`
                ${flex('left grow')}
              `}
            >
              <UserCirclePlus />
              <Text
                h4
                css={`
                  margin-left: 16px;
                `}
              >
                {props?.displaySettings?.custom_label_child}
              </Text>
            </div>
            <div
              className={css`
                ${flex('right')}
              `}
            >
              {selectedTypes?.includes('Child') ? (
                <>
                  <div className={`check checked`} onClick={(e) => addRemoveChildren(e, 'remove')}>
                    <Minus size={24} color="currentColor" />
                  </div>

                  <Text
                    label
                    bold
                    className={css`
                      margin: 0 16px;
                    `}
                  >
                    {childrenCount}
                  </Text>
                </>
              ) : null}

              <div
                className={`check ${selectedTypes?.includes('Child') ? 'checked' : 'unchecked'}`}
                onClick={(e) => addRemoveChildren(e, 'add')}
              >
                <Plus size={24} color="currentColor" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};
