import { useState, useEffect } from 'react';
import { CustomAxios } from '../axios/axios';
import { sortBy, cloneDeep } from 'lodash';
import moment from 'moment';
import posthog from '../lib/analytics/posthog';
import { useStore } from '../components/store-provider/use-store';
import { useDisplaySettings } from '../components/brite-viewer/components/benefits/plan-comparisons/use-display-settings';

export function useBenefitsHook() {
  const {
    data: { pages, isAcaPlan = false, isIchraPlan = false, displaySettings },
    setStore,
  } = useStore();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [archived, setArchived] = useState(false);
  const [customQuestions, setCustomQuestions] = useState([]);
  const [showPasscodePage, setShowPasscodePage] = useState(false);
  const [dynamicQuestions, setDynamicQuestions] = useState([]);

  const [promptOptions, setPromptOptions] = useState({
    promptAge: false,
    needTobacco: false,
    defaultEnrolling: '',
  });

  const acaSmallGroupType = 'ACAPLANS';
  const ichraType = 'ICHRA';

  const { BusinessID = '', BenefitsPackageID = '' } = pages?.[0] || {};

  useDisplaySettings(BusinessID, BenefitsPackageID, (displaySettings) => setStore('displaySettings', displaySettings));

  const checkIfIsSmallGroupPlan = (planType) => {
    return planType.toUpperCase().startsWith(acaSmallGroupType);
  };

  const checkIfIsIchraPlan = (planType) => {
    return planType.toUpperCase().startsWith(ichraType);
  };

  const fetchBenefitsPackageV2 = async () => {
    const { BenefitsPackageID = '', BusinessID = '' } = pages?.[0] || {};
    if (!BenefitsPackageID || !BusinessID) {
      return;
    }

    try {
      const url = `/public/v1/business/${BusinessID}/benefitspackage/${BenefitsPackageID}/product-layout`;
      const { data } = await CustomAxios.get(url);
      const { data: productList } = await CustomAxios.get(
        `/public/v2/business/${BusinessID}/benefitspackage/${BenefitsPackageID}/product`
      );

      let clonedData = cloneDeep(data?.Data || []);

      for (let product of clonedData) {
        for (let section of product?.Layout?.Sections) {
          let combinedFrequencyFields = [];
          for (let field of section?.Fields) {
            if (field.Type == 'visionFrequency') {
              combinedFrequencyFields[combinedFrequencyFields.length - 1].frequency = field;
              combinedFrequencyFields[combinedFrequencyFields.length - 1].Type = 'visionFrequency';
            } else {
              combinedFrequencyFields.push(field);
            }
          }
          section.Fields = combinedFrequencyFields;
        }
      }

      const layouts = clonedData.reduce((prev, item) => ({ ...prev, [item.ProductID]: item }), {});

      const products = productList.reduce(
        (prev, item) => [...prev, { ...item, sections: layouts?.[item?.ID]?.Layout?.Sections || [] }],
        []
      );
      setStore('products', products);
      setStore('productLayouts', layouts);
    } catch (err) {
      console.warn(err);
    }
  };

  const fetchBenefitsPackage = async () => {
    const { BenefitsPackageID = '', BusinessID = '' } = pages?.[0] || {};
    setStore('businessId', BusinessID);
    if (!!BenefitsPackageID) {
      try {
        const benefitPackageUrl = `/public/v1/business/${BusinessID}/benefitspackage/${BenefitsPackageID}/product`;
        const { data } = await CustomAxios.get(benefitPackageUrl);
        let list = [];
        for (const item of data) {
          if (item.Type === 'insurance_plan') {
            const url = `/public/v1/plans/${item.ID}`;
            const { data } = await CustomAxios.get(url);
            list.push(data);
          } else {
            list.push(item);
          }
        }
        setStore('benefitsPackage', list);
      } catch (err) {
        console.error(err);
      }
    }
  };

  useEffect(() => {
    fetchBenefitsPackage();
    fetchBenefitsPackageV2();
    // fetchDisplaySettings();
  }, [pages.length]);

  useEffect(() => {
    let ignore = false;

    async function fetchData() {
      setLoading(true);
      fetchBenefitsPackage();
      let path = window.location.pathname;

      let paths = path.split('/');
      let fetchSurveyPages = false;
      let vanityPathIndex = 1;

      for (let p of paths) {
        if (p === 'survey') {
          fetchSurveyPages = true;
          vanityPathIndex = 2;
        }
      }

      try {
        // Check localstorage for passcode in case they refreshed the page, so we don't have to re-prompt.
        const passcode = localStorage.getItem('passcode', true);
        if (passcode) {
          CustomAxios.setHeader('X-Brite-Course-Passcode', passcode);
        }

        let url = `/public/v1/course/${paths[vanityPathIndex]}`;
        if (fetchSurveyPages) {
          url += '/survey';
        }

        const result = await CustomAxios.get(url);
        if (!ignore) {
          if (result.data.length === 0) {
            setError(true);
          } else {
            setError(false);
          }

          let course = sortBy(result.data, ['Order']);

          for (let archiveCheck of course) {
            if (archiveCheck.ArchivedAt) {
              setArchived(true);
              break;
            }
          }

          let courseDetails;

          let needAge = false,
            needTobacco = false,
            hasAcaPlan = false,
            hasIchraPlan = false,
            hasDecisionToolPage = false,
            hasQuestionPage = false,
            enrollmentURL = '',
            enrollmentLogoURL = '';
          for (let page of course) {
            // set course details from page if we haven't yet
            if (!courseDetails) {
              courseDetails = {
                courseURL: paths[vanityPathIndex],
                courseID: page.CourseID,
                courseName: page.CourseName,
                businessName: page.BusinessName,
                businessID: page.BusinessID,
                parentBusinessName: page.ParentBusinessName,
                parentBusinessID: page.ParentBusinessID,
              };
            }

            hasDecisionToolPage = hasDecisionToolPage || page.Type === 'decision';

            if (page.EnrollmentURL) {
              enrollmentURL = page.EnrollmentURL;
              if (enrollmentURL) {
                let url = enrollmentURL;
                if (!url.match(/^http?:\/\//i) && !url.match(/^https?:\/\//i)) {
                  url = 'https://' + url;
                }
                enrollmentURL = url;
              }
              if (page.EnrollmentLogoURL) {
                enrollmentLogoURL = page.EnrollmentLogoURL;
              }
            }
            hasQuestionPage = hasQuestionPage || page.Type === 'question';

            if (page.InsurancePlans && page.InsurancePlans.length > 0) {
              for (let plan of page.InsurancePlans) {
                if (!checkIfIsSmallGroupPlan(plan.PlanType) && !checkIfIsIchraPlan(plan.PlanType)) {
                  continue;
                }

                hasAcaPlan = hasAcaPlan || checkIfIsSmallGroupPlan(plan.PlanType);
                hasIchraPlan = hasIchraPlan || checkIfIsIchraPlan(plan.PlanType);

                // TODO: the server should be handling this config so it's easier to manage, but this is fine for now
                if (!plan.OptionalPremiumLocation || plan.OptionalPremiumLocation.State != 'NY') {
                  needAge = true;
                  needTobacco = true;
                }
              }
            }
          }

          // use course details to register data for posthog events
          if (courseDetails) {
            courseDetails.hasDecisionToolPage = hasDecisionToolPage;
            courseDetails.hasQuestionPage = hasQuestionPage;
            posthog.register(courseDetails);

            posthog.setPersonProperties({
              courseURL: courseDetails.courseURL, // deprecated... I think this is better to just be registered on each event rather than on the person
              businessID: courseDetails.businessID,
              currentBusinessID: courseDetails.businessID, // this property is used to target feature flags
              currentParentID: courseDetails.parentBusinessID, // this property is used to target feature flags
              parentBusinessID: courseDetails.parentBusinessID,
              businessName: courseDetails.businessName,
              parentBusinessName: courseDetails.parentBusinessName,
            });
          }

          setPromptOptions({
            promptAge: needAge,
            promptTobacco: needTobacco,
            defaultEnrolling: (hasAcaPlan || hasIchraPlan) && !needAge ? 'Self Only' : '',
          });

          setStore('isAcaPlan', hasAcaPlan);
          setStore('isIchraPlan', hasIchraPlan);
          let showFeedback = false;
          if (course.length > 0 && course[0]) {
            showFeedback = course[0].ShowFeedbackPage;
          }

          if (enrollmentURL || showFeedback) {
            // If we don't have the logo for the ben admin, try to auto-populate it based on domain from enrollmentURL.
            // TODO, once the admin portal is prompting the user for the logo, and we have run db migrations to backfill, we can remove this extra request.
            if (enrollmentURL && !enrollmentLogoURL) {
              try {
                const result = await CustomAxios.get(`/public/v1/bp/ben_admin?share_scope=eq.global`, {
                  headers: {
                    'Accept-Profile': 'brite_public',
                  },
                });
                // loop over result.data
                for (let ba of result?.data) {
                  for (let domain of ba.domains) {
                    if (enrollmentURL.includes(domain)) {
                      enrollmentLogoURL = ba.logo_url;
                      break;
                    }
                  }
                }
              } catch (err) {
                // It's not ideal, but okay if we don't have the enrollmentLogoURL, the UI will handle it down the line.
                console.error(err);
              }
            }
            course = [
              ...course,
              {
                Type: 'endOfGuideEnroll',
                ID: 'endOfGuideEnroll',
                Name: `${enrollmentURL ? 'Enroll' : 'Feedback'}`,
                EnrollmentURL: enrollmentURL || '',
                EnrollmentLogoURL: enrollmentLogoURL || '',
                HTMLContent: '',
                Content: '',
                ShowFeedbackPage: showFeedback,
                ...courseDetails,
              },
            ];
          }

          setStore('pages', course);

          try {
            CustomAxios.post(`/public/v1/${course[0].CourseID}/analytics/courseActivity`, {
              CourseID: course[0].CourseID,
              LinkClickedAt: moment.utc().format(),
            });
          } catch (error) {
            console.log(`/public/v1/${course[0].CourseID}/analytics/courseActivity failed to save.`);
          }

          try {
            let customQuestionsResponse = await CustomAxios.get(
              `/public/v2/course/${course[0].CourseID}/decisiontool/questions`
            );
            let questionsList = [];
            if (customQuestionsResponse && customQuestionsResponse.data) {
              if (customQuestionsResponse.data.Questions) {
                questionsList = customQuestionsResponse.data.Questions;
              }
            }

            let questions = [];
            let dynamic = [];

            for (let question of questionsList) {
              if (question.QuestionType === 'hardcoded') {
                dynamic.push(question.Question);
              } else if (question.QuestionType === 'scale') {
                dynamic.push(question.Question);
              } else if (question.Question.Responses && question.Question.Text) {
                questions.push(question.Question);
              }
            }
            setDynamicQuestions(dynamic);
            setCustomQuestions(questions);
          } catch (error) {
            setDynamicQuestions([]);
            setCustomQuestions([]);
          }
        }
      } catch (e) {
        if (!ignore && e.response && e.response.status === 403) {
          setShowPasscodePage(true);
        } else if (!ignore) {
          setError(true);
        }
      } finally {
        if (!ignore) {
          setLoading(false);
        }
      }
    }

    if (!showPasscodePage) {
      fetchData();
    }

    return () => {
      ignore = true;
    };
  }, [showPasscodePage]);

  return {
    pages,
    loading,
    error,
    isAcaPlan,
    isIchraPlan,
    promptOptions,
    customQuestions,
    dynamicQuestions,
    showPasscodePage,
    archived,
    setShowPasscodePage,
    setLoading,
    displaySettings,
  };
}
