import React from 'react';
import { getAttributes } from '../utils';

const getVideoUrl = (value) => {
  try {
    const url = new URL(value);
    if (url.hostname === 'www.youtube.com') {
      if (value.includes('/embed/')) {
        return value;
      } else if (url.pathname === '/watch') {
        const v = url.searchParams.get('v');
        if (!v) {
          return '';
        }
        return `${url.origin}/embed/${v}`;
      } else if (url.pathname.includes('/embed')) {
        return value;
      } else {
        return `${url.origin}/embed${url.pathname}`;
      }
    } else if (url.hostname === 'youtu.be') {
      return `${url.protocol}//www.youtube.com/embed${url.pathname}`;
    } else if (url.hostname === 'vimeo.com') {
      if (!url.pathname.startsWith('/video')) {
        let { pathname } = url;
        const [_, id1, id2] = pathname.split('/');
        if (id2) {
          pathname = `/${id1}?h=${id2}`;
        }
        return `${url.protocol}//player.vimeo.com/video${pathname}`;
      }
    } else if (url.hostname === 'drive.google.com') {
      // if they copy paste the wrong URL from google drive, let's try to help them out and get the right one
      return url.href.replace('/view', '/preview').replace('/edit', '/preview');
    }
    return value;
  } catch (err) {
    console.log(err);
    return '';
  }
};

export const Video = ({ item, totalColumns }) => {
  const componentAttributes = getAttributes({
    column: item,
    length: totalColumns,
    type: 'component',
  });

  const src = getVideoUrl(componentAttributes?.src);

  return (
    <div {...componentAttributes}>
      <iframe {...componentAttributes} src={src}></iframe>
    </div>
  );
};
