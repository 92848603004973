import React from 'react';
import { css } from 'emotion';
import SelectedImage from './selectedRadio.png';
import NotSelectedImage from './not-selected.png';

export const radioStyle = (selected) => css`
  box-sizing: border-box;
  border: 2px solid ${selected ? '#25282D' : '#9aa7b5'};
  box-shadow: ${selected ? '0px 0px 16px rgba(0, 0, 0, 0.2)' : 'none'};
  border-radius: 8px;
  width: 300px;
  cursor: pointer;
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    border: 2px solid #25282d;
    /* Blue Shadow */
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
  }
`;

export default function BriteRadio(props) {
  return (
    <div
      className={css`
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 16px;
        box-sizing: border-box;
      `}
    >
      {props.options.map((option) => {
        return (
          <div
            onClick={() => {
              props.onClick(option.value);
            }}
            className={radioStyle(props?.value === option?.value)}
          >
            <div
              className={css`
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 24px;
                /* identical to box height, or 133% */

                letter-spacing: 0.15px;

                color: #25282d;
              `}
            >
              {option.label}
            </div>

            {option.value === props.value ? (
              <img src={SelectedImage} width="24px" height="24px" />
            ) : (
              <img src={NotSelectedImage} width="24px" height="24px" />
            )}
          </div>
        );
      })}
    </div>
  );
}
