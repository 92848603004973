import { get } from 'lodash';
import { getProductPremium } from './field-utils';

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
});

export const currencyFormatterCents = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 2,
});

const formatTypes = ['text', '$', '%', 'yes/no'];

// deprecated
export const getPremium = ({ plan, key, type = 'medical', interval = 12 }) => {
  let cost = plan.Cost;
  if (!cost) {
    cost = {
      TotalMonthlyPremiums: plan.TotalMonthlyPremiums,
      Contributions: {
        MonthlyContributions: plan.EmployeeContributions,
        ContributionType: plan.EmployeeContributionType,
      },
    };
  }
  return getProductPremium({ cost, key, interval });
};

// deprecated
export const getCostDetails = ({ cost, key, interval = 12 }) => {
  return getProductPremium({ cost, key, interval });
};

export const getDisplayValue = ({ value, format, plan }) => {
  let formatType = format;
  if (!formatTypes.includes(format)) {
    formatType = get(plan, format, null);
    if (formatType === null) {
      return '-';
    }
  }

  if (value === -1) {
    return '-';
  }

  if (formatType === 'text') {
    return value;
  } else if (formatType === '$') {
    if (typeof value === 'string') {
      value = Number(value.replace(/\D/g, ''));
    }

    value = Math.max(0, Number(value));
    return currencyFormatter.format(value);
  } else if (formatType === '%') {
    if (typeof value === 'string') {
      value = Number(value.replace(/\D/g, ''));
    }
    value = Math.min(Math.max(0, value), 100);
    value = value <= 1 ? value * 100 : value;
    return `${value}%`;
  } else if (formatType === 'yes/no') {
    return value ? 'Yes' : 'No';
  }
};

export const getProfessionalServicesDisplayValue = ({ value, format, plan }) => {
  const type = get(plan, `InNetworkProfessionalServices.${format}.ADOrCopay`, 'AD');
  const formatType = get(plan, `InNetworkProfessionalServices.${format}.PercentOrDollar`, '$');
  if (type === 'AD') {
    return [`${getDisplayValue({ value, format: formatType, plan })} AD`];
  } else if (type === 'COPAY') {
    return [`${getDisplayValue({ value, format: formatType, plan })} Copay`];
  } else if (type === 'COPAY + %') {
    const specialCopay = get(plan, `InNetworkProfessionalServices.${format}.SpecialCopay`, null);
    if (specialCopay !== null) {
      return [
        getDisplayValue({ value: specialCopay, format: '$', plan }),
        getDisplayValue({ value, format: formatType, plan }),
      ];
    } else {
      return [getDisplayValue({ value, format: formatType, plan })];
    }
  } else {
    return ['-'];
  }
};

export const getPrescriptionDrugsDisplayValue = ({ value, format, plan }) => {
  if (formatTypes.includes(format)) {
    return [getDisplayValue({ value, format, plan })];
  }
  const type = get(plan, `PrescriptionDrugs.${format}.ADOrCopay`, 'AD');
  const formatType = get(plan, `PrescriptionDrugs.${format}.PercentOrDollar`, '$');
  if (type === 'AD') {
    return [`${getDisplayValue({ value, format: formatType, plan })} AD`];
  } else if (type === 'COPAY') {
    return [getDisplayValue({ value, format: formatType, plan })];
  } else if (type === 'COPAY + %') {
    const specialCopay = get(plan, `PrescriptionDrugs.${format}.SpecialCopay`, null);
    if (specialCopay !== null) {
      return [
        getDisplayValue({ value: specialCopay, format: '$', plan }),
        getDisplayValue({ value, format: formatType, plan }),
      ];
    } else {
      return [getDisplayValue({ value, format: formatType, plan })];
    }
  } else {
    return ['-'];
  }
};

export const medicalSummaryDefaults = {
  planDetails: [
    {
      key: 'InNetworkPlanDesign.SingleDeductible',
      label: 'Deductible - Individual',
      format: '$',
    },
    {
      key: 'InNetworkPlanDesign.Deductible',
      label: 'Deductible - Family',
      format: '$',
    },
    {
      key: 'InNetworkPlanDesign.SingleOOPM',
      label: 'Out of Pocket Max - Individual',
      format: '$',
    },
    {
      key: 'InNetworkPlanDesign.OOPM',
      label: 'Out of Pocket Max - Family',
      format: '$',
    },
    {
      key: 'InNetworkPlanDesign.Coinsurance',
      label: 'Co-Insurance',
      format: '%',
    },
  ],
  professionalServices: [
    {
      key: 'InNetworkProfessionalServices.PrimaryCarePhysician.Value',
      label: 'Primary Care Physician',
      format: 'PrimaryCarePhysician',
    },
    {
      key: 'InNetworkProfessionalServices.Specialist.Value',
      label: 'Specialist',
      format: 'Specialist',
    },
    {
      key: 'InNetworkProfessionalServices.TeleMedicine.Value',
      label: 'Telehealth Visit',
      format: 'TeleMedicine',
    },
    {
      key: 'InNetworkProfessionalServices.MentalHealthOutpatient.Value',
      label: 'Mental Health Visit',
      format: 'MentalHealthOutpatient',
    },
    {
      key: 'InNetworkProfessionalServices.InPatientHospital.Value',
      label: 'Inpatient Hospital',
      format: 'InPatientHospital',
    },
    {
      key: 'InNetworkProfessionalServices.OutPatientSurgery.Value',
      label: 'Outpatient Surgery',
      format: 'OutPatientSurgery',
    },
    {
      key: 'InNetworkProfessionalServices.EmergencyRoom.Value',
      label: 'Emergency Room',
      format: 'EmergencyRoom',
    },
    {
      key: 'InNetworkProfessionalServices.UrgentCare.Value',
      label: 'Urgent Care',
      format: 'UrgentCare',
    },
  ],
  prescriptionDrugs: [
    {
      key: 'PrescriptionDrugs.MedicalDeductibleApplies.Value',
      label: 'Rx Deductible',
      format: '$',
    },
    {
      key: 'PrescriptionDrugs.Tier1.Value',
      label: 'Tier',
      format: 'PrescriptionDrugs.Tier1.PercentOrDollar',
    },
    {
      key: 'PrescriptionDrugs.MailOrder',
      label: 'Mail Order',
      format: 'text',
    },
  ],
};

export const dentalSummaryDefaults = {
  planDetails: [
    {
      key: 'CardTemplate.RawData.AnnualMaximum',
      label: 'Annual Maximum',
      format: '$',
    },
    {
      key: 'CardTemplate.RawData.IndividualDeductible',
      label: 'Deductible - Individual',
      format: '$',
    },
    {
      key: 'CardTemplate.RawData.FamilyDeductible',
      label: 'Deductible - Family',
      format: '$',
    },
    {
      key: 'CardTemplate.RawData.PreventativeServices',
      label: 'Preventative Services',
      format: '%',
    },
    {
      key: 'CardTemplate.RawData.BasicServices',
      label: 'Basic Services',
      format: '%',
    },
    {
      key: 'CardTemplate.RawData.MajorServices',
      label: 'Major Services',
      format: '%',
    },
  ],
};

export const visionSummaryDefaults = {
  planDetails: [
    {
      key: 'CardTemplate.RawData.EyeExams',
      label: 'Eye Exams',
      format: 'text',
    },
    {
      key: 'CardTemplate.RawData.LensesBenefit',
      label: 'Lenses Benefit',
      format: 'text',
    },
    {
      key: 'CardTemplate.RawData.ContactLenses',
      label: 'Contact Lenses',
      format: 'text',
    },
    {
      key: 'CardTemplate.RawData.Frames',
      label: 'Frames',
      format: 'text',
    },
  ],
};
