import React, { Component } from 'react';
import { motion } from 'framer-motion';
import Button from '@material-ui/core/Button';
import { css } from 'emotion';
import { colors } from '../../../shared-components/styles';

export class DecisionToolIntroViewer extends Component {
  render() {
    return (
      <motion.div
        key={'intro_question'}
        initial="pageInitial"
        animate="pageAnimate"
        exit="pageExit"
        variants={{
          pageInitial: {
            opacity: 0,
          },
          pageAnimate: {
            opacity: 1,
          },
          pageExit: {
            x: -400,
            opacity: 0,
          },
        }}
        className={css`
          height: 100%;
          text-align: center;
          margin-bottom: 50px;
          padding-top: 10px;
        `}
      >
        <div
          className={css`
            margin: 12px;
            padding: 20px;
            border-radius: 10px;
            max-height: 600px;
          `}
        >
          <h1
            className={css`
              font-family: 'Roboto';
              font-style: normal;
              font-weight: 700;
              font-size: 34px;
              line-height: 48px;
              /* identical to box height, or 141% */

              text-align: center;

              color: #25282d;
            `}
          >
            {' '}
            Benefits Package Recommendations
          </h1>

          <p
            className={css`
              font-family: 'Roboto';
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              /* or 150% */
              text-align: center;
              color: #36414c;
            `}
          >
            {this.props.userSelectedRecs
              ? 'Your recommendations are ready!'
              : 'Answer a few simple questions to receive your personalized recommendations.'}
          </p>
          {this.props.fedexVerbiageChangesFF?.value && (
            <p
              className={css`
                margin: auto;
                font-family: 'Roboto';
                font-style: italic;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                text-align: center;
                color: ${colors.gray[600]};
                max-width: 750px;
              `}
            >
              Disclaimer: This tool is used for estimation purposes only. All costs shown provide estimates based on
              average costs and do not reflect the actual costs for services provided, including pharmacy medications.
              Under no circumstance does this tool provide any guarantee of coverage for any programs provided by your
              Employer.
            </p>
          )}

          <div
            className={css`
              margin-top: 40px;
              margin-bottom: 20px;
            `}
          >
            <Button
              style={{
                background: '#25282D',
                color: '#fff',
                borderRadius: '8px',
                padding: '12px 42px',
                fontWeight: 700,
              }}
              id="decision-tool-begin"
              variant="contained"
              size="large"
              color="primary"
              onClick={() => {
                this.props.setDecisionToolOpen(true);
              }}
            >
              {this.props.userSelectedRecs ? 'View your Recommendations' : 'Get My Recommendations'}
            </Button>
          </div>
        </div>
      </motion.div>
    );
  }
}
