import { ProductRecommendation } from '../../../shared-components/product-components/product-recommendation';
import { useStore } from '../../store-provider/use-store';

// This wrapper allows the API for ProductRec to be similar to the admin
// Makes ProductRec easier to share between repos.
export const ProductRecWrapper = ({ recommendation, productTypeIds, selected, setSelected, isMulti = false }) => {
  const {
    data: { products, productLayouts, displaySettings },
  } = useStore();

  return (
    <ProductRecommendation
      productTypeIds={productTypeIds}
      products={products}
      selected={selected}
      recommendation={recommendation}
      setSelected={setSelected}
      productLayouts={productLayouts}
      displaySettings={displaySettings}
      isMulti={isMulti}
    />
  );
};
