import React from 'react';
import { css } from 'emotion';
import { TextField, Button } from '@material-ui/core';
import { CustomAxios } from '../axios/axios';
import { useLocalStorage } from '../api-hooks/use-local-storage';

export const PasscodePage = (props) => {
  const [passcode, setPasscode] = React.useState('');
  const briteLocalStorage = useLocalStorage();

  const submit = () => {
    CustomAxios.setHeader('X-Brite-Course-Passcode', passcode);
    briteLocalStorage.setItem('passcode', passcode, true);
    props.setLoading(true);
    props.setShowPasscodePage(false);
  };

  return (
    <div
      className={css`
        max-width: 500px;
        margin: 40px auto;
      `}
    >
      <div
        className={css`
          color: #25282d;
          font-weight: bold;
          font-size: 24px;
          line-height: 32px;
          margin-bottom: 32px;
          font-family: Roboto;
        `}
      >
        Passcode Protected Course
      </div>
      <div
        className={css`
          display: flex;
          align-items: center;

          margin-bottom: 40px;
        `}
      >
        <div
          className={css`
            color: #25282d;
            font-size: 16px;
            margin-right: 60px;
            font-family: Roboto;
          `}
        >
          Enter Passcode
        </div>
        <TextField
          type="password"
          style={{ flexGrow: '1' }}
          value={passcode}
          onChange={(e) => setPasscode(e.target.value)}
          variant="outlined"
        />
      </div>
      <Button fullWidth color="primary" variant="contained" onClick={() => submit()}>
        Submit
      </Button>
    </div>
  );
};
