import React from 'react';
import { getAttributes } from '../utils';

export const Space = ({ item, totalColumns, containerAttributes }) => {
  const componentAttributes = getAttributes({
    column: item,
    length: totalColumns,
    type: 'component',
  });

  const spaceStyle =
    item.component === 'space' ? { borderRadius: containerAttributes?.style?.borderRadius || '0' } : {};

  const componentStyle = { ...componentAttributes.style, ...spaceStyle };

  return <div {...componentAttributes} style={componentStyle} />;
};
