import { useRef } from 'react';
import { css, cx } from 'emotion';
import { animation } from '../../shared-components/shared-styles';
import { useStore } from '../store-provider/use-store';
import { PDFComponentContainer } from './PDFComponentContainer';

const briteContentStyles = () => {
  return css`
    box-sizing: border-box;
    padding: 8px;
    margin: 0 auto;
    width: 100%;
    opacity: 100%;

    @media only screen and (max-width: 420px) {
      .row.stack-columns {
        flex-direction: column;
        .component-container {
          width: 100%;
        }
      }
    }

    .row {
      z-index: 5;
      page-break-inside: avoid;
      a {
        color: inherit;
        text-decoration: inherit;
      }
    }

    ${animation('fadein', '.35s ease')};

    img {
      ${animation('fadein', '.2s ease')};
    }
  `;
};

const rowStyle = (row) => css`
  z-index: -1;
  position: absolute;
  top: 0;
  bottom: 0;
  ${!row?.fullScreenWidth
    ? `
      left: 0;
      right: 0;
  `
    : `
    left: calc(100% / 2 - ${window.innerWidth / 2}px);
    width: ${window.innerWidth * 1}px;
  `}
`;

export const PDFPage = ({ page }) => {
  const rowRef = useRef();

  const Content = page?.Content;

  const {
    data: { displaySettings },
  } = useStore();

  const getRowClasslist = (data) => {
    const classList = ['row'];

    if (data?.stackColumns) {
      classList.push('stack-columns');
    }

    return classList.join(' ');
  };

  if (!Content || !displaySettings) {
    return null;
  }

  return (
    <div
      style={{
        backgroundColor: `var(--background-color)`,
        breakAfter: 'page',
        ...(Content?.root?.containerStyle || {}), // Responsible for the background image.
      }}
    >
      <div ref={rowRef} className={cx('_brite-content', briteContentStyles())}>
        {Content?.rows?.map(
          (row, rowIdx) =>
            !row?.hideOnExport && (
              <div
                className={css`
                  padding: 0 ${row?.columns?.some((item) => item.component === 'benefits') ? '16px' : '100px'};
                `}
                key={page?.ID + row?.rowId}
              >
                <div className={getRowClasslist(row)} key={page?.ID + row?.rowId}>
                  <div className={rowStyle(row)} style={row?.style || {}} />
                  {row?.columns?.map((item, idx) => (
                    <PDFComponentContainer
                      item={item}
                      rowIdx={rowIdx}
                      key={page?.ID + row?.rowId + idx}
                      isStackColumns={row?.stackColumns}
                      totalColumns={row?.columns?.length || 0}
                    />
                  ))}
                </div>
              </div>
            )
        )}
      </div>
    </div>
  );
};
