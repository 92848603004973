import { css } from 'emotion';
import QRCode from 'react-qr-code';
import { Div } from '../../shared-components';

// hasDefaultContainer is a boolean that determines whether the QR code is wrapped in a default container with padding.
// Toggle false if the QRCode has an existing container.
export const PDFQRCode = ({ hasDefaultContainer = true, size, value = '' }) => {
  const props = { size, value };
  return hasDefaultContainer ? (
    <Div
      css={css`
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px;
      `}
    >
      <QRCode {...props} />
    </Div>
  ) : (
    <QRCode {...props} />
  );
};
