import { css } from 'emotion';
import { Div, Text } from '../../../../shared-components';
import { flex } from '../../../../shared-components/shared-styles';
import { useStore } from '../../../store-provider/use-store';
import { getProductPremium } from './field-utils';
import { getTiersAndLabels } from './plan-comparisons/use-display-settings';
import { CostACA } from './cost-aca';

export const CostSummary = (props) => {
  const { data, markHidden = false, comparisonView = false, premiumsSetExternally = false } = props;
  const {
    data: { displaySettings },
  } = useStore();

  const { product } = data;
  const cost = product?.Cost;

  const { tiers, tierLabels } = getTiersAndLabels({
    cost,
    displaySettings,
  });

  if (premiumsSetExternally) {
    return <CostACA data={props?.data} props={props} Summary={CostSummary} />;
  }

  // EMPLOYEE-VIEW
  return (
    <Div
      css={css`
        width: 100%;
      `}
    >
      <Text
        h4
        className={css`
          padding: 16px 32px;
          ${comparisonView ? 'color: var(--text-h4); font-size: 22px;' : ''}
        `}
      >
        Cost {displaySettings?.premiumInterval === 12 ? 'per month' : 'per pay period'}
      </Text>

      {tiers?.map((key, idx) => (
        <Div
          className="alternating-item"
          css={css`
            ${comparisonView ? '' : flex('space-between')}
            width: calc(100% - 64px);
            padding: 16px 32px;
          `}
        >
          <Text
            label
            css={`
              ${comparisonView ? 'margin-bottom: 8px;' : ''}
            `}
          >
            {tierLabels?.[idx]}
          </Text>
          <Text label bold h4={comparisonView}>
            {markHidden
              ? '-'
              : getProductPremium({
                  cost,
                  key,
                  interval: displaySettings?.premiumInterval,
                })}
          </Text>
        </Div>
      ))}
    </Div>
  );
};
