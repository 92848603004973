import { css } from 'emotion';
import React from 'react';
import { ExtensionIconMapper } from '../../../shared-components/extension-icon-mapper';
import { colors } from '../../../shared-components/styles';
import { getAttributes } from '../utils';
import { Text } from '../../../shared-components/text';
import { DownloadSimple } from 'phosphor-react';

export const Document = ({ item, totalColumns }) => {
  const componentAttributes = getAttributes({
    column: item,
    length: totalColumns,
    type: 'component',
  });

  return (
    <div {...componentAttributes} style={{ width: '100%', boxSizing: 'border-box' }}>
      <a
        target="_blank"
        href={item?.document?.PublicURL}
        className={css`
          display: flex;
          align-items: center;
          justify-content: start;
          background-color: white;
          border: 1px solid ${colors.gray[300]};
          border-radius: 8px;
          width: calc(100% - 48px);
          height: 64px;
          text-decoration: none;
          cursor: pointer;
          padding: 0 24px;
          svg {
            min-width: 32px;
          }
        `}
      >
        <ExtensionIconMapper size={32} color="var(--button-background-color)" fileType={item?.document?.FileType} />
        <Text
          label
          bold
          ellipsis
          css={`
            flex-grow: 1;
            margin: 0 16px;
          `}
        >
          {item?.document?.Name}
        </Text>
        <DownloadSimple size={32} />
      </a>
    </div>
  );
};
