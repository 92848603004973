import { useEffect, useMemo, useState } from 'react';
import { Button, Modal, Text } from '../../../shared-components';
import { Box } from '../../../shared-components/Box';
import { colors } from '../../../shared-components/styles';
import { CarrierLogo } from '../../brite-viewer/components/benefits/carrier-logo';
import { Check, Minus, Plus, Star, X } from 'phosphor-react';
import { getDisplayNumberValue } from '../../brite-viewer/components/benefits/field-utils';
import { posthogAttrs } from '../../../posthog-constants';
import { Collapse } from '@material-ui/core';
import { css } from 'emotion';
import { useRecommendations } from '../../../shared-components/product-components/use-rec-config';
import { useStore } from '../../store-provider/use-store';
import { LearnMore } from './LearnMore';
import * as math from 'mathjs';
import { filterSupportedPrograms, IncludedProgram, programs } from './included-program';
import { PRODUCT_HEADERS } from '../../brite-viewer/components/benefits/product-comparisons';
import { smallScreen } from '../additional-benefits/product-components/shared-styles';

const getFinalPlanCost = (rec, periods = null) => {
  // TODO: Handle periods - 26/12/etc
  let cost = math.divide(rec.PlanCostsForYear.FinalPlanCost, rec.PlanCostsForYear.MonthsIncluded);
  cost = Math.max(0, cost);
  return `$${getDisplayNumberValue(cost, { decimals: 2, round: false })}`;
};

export const MedicalRecommendations = ({
  selectedMedicalRec,
  setSelectedMedicalRec,
  recommendations,
  setShowComparisons,
}) => {
  const {
    data: { productLayouts },
  } = useStore();

  const [openList, setOpenList] = useState([]);
  const [modal, setModal] = useState(null);
  const [changes, setChanges] = useState(null);

  const plansByNetwork = useMemo(() => {
    const recsByNetwork = recommendations?.reduce((prev, rec) => {
      const id = rec?.Plan?.MultiNetworkID || rec?.Plan?.ID;
      return {
        ...prev,
        [id]: [...(prev?.[id] || []), rec],
      };
    }, {});
    return Object.entries(recsByNetwork);
  });

  useEffect(() => {
    const keys = plansByNetwork?.map(([key]) => key);
    setOpenList(keys);
  }, []);

  const toggleOpenList = (id) => {
    if (openList.includes(id)) {
      setOpenList(openList.filter((item) => item !== id));
    } else {
      setOpenList([...openList, id]);
    }
  };

  const spreadPlans = useMemo(() => {
    return plansByNetwork?.reduce((prev, [key, recs]) => [...prev, ...recs.map(({ Plan }) => Plan)], []);
  }, [plansByNetwork]);

  const recommendationSummaryData = useRecommendations({
    productId: modal,
    products: spreadPlans,
    productLayouts,
  });

  const recommendation = recommendations?.find((rec) => rec?.Plan?.ID === modal);

  const updateRecChange = () => {
    setSelectedMedicalRec(changes.changeToRec);
    setChanges(null);
  };

  const handleSelectedMedicalRec = (rec) => {
    if (rec?.Plan?.ID === selectedMedicalRec?.Plan?.ID) {
      return;
    }
    const removingSpendingAccounts =
      selectedMedicalRec?.PlanCostsForYear?.AdditionalCostInfo?.IncentivesList?.filter(
        (item) => !item?.ComparableOnly
      )?.map((item) => PRODUCT_HEADERS[item?.Type]) || [];

    const addingSpendingAccounts =
      rec?.PlanCostsForYear?.AdditionalCostInfo?.IncentivesList?.filter((item) => !item?.ComparableOnly)?.map(
        (item) => PRODUCT_HEADERS[item?.Type]
      ) || [];

    const removingIncludedPrograms =
      selectedMedicalRec?.Plan?.includedPrograms?.map((item) => programs[item?.ProgramType]) || [];
    const addingIncludedPrograms = rec?.Plan?.includedPrograms?.map((item) => programs[item?.ProgramType]) || [];

    const removes = [...new Set([...removingSpendingAccounts, ...removingIncludedPrograms])];
    const adds = [...new Set([...addingSpendingAccounts, ...addingIncludedPrograms])];

    const selectedCost = math.divide(
      selectedMedicalRec.PlanCostsForYear.FinalPlanCost,
      selectedMedicalRec?.PlanCostsForYear?.MonthsIncluded
    );
    const recCost = math.divide(rec.PlanCostsForYear.FinalPlanCost, rec?.PlanCostsForYear?.MonthsIncluded);
    const costChange = math.subtract(selectedCost, recCost);

    const cost = costChange === 0 ? null : getDisplayNumberValue(costChange, { decimals: 2, round: false });
    const changes = {
      cost,
      adds: adds?.filter((item) => !removes.includes(item)),
      removes: removes?.filter((item) => !adds.includes(item)),
      changeToRec: rec,
    };

    if (adds?.length || removes?.length) {
      setChanges(changes);
    } else {
      setChanges(null);
      setSelectedMedicalRec(rec);
    }
  };

  const networkDescriptionOrDefault = (rec) => {
    // if there is a network description we will just use that
    if (rec?.Plan?.NetworkDescription) {
      return rec?.Plan?.NetworkDescription;
    }

    const networkType = rec?.Plan?.MultiNetworkCategory;

    // we have no default for other
    if (networkType == 'other') {
      return '';
    }

    // if there is no given network description and we know the "type" then we will show a default value
    // is American Health Partners
    if (rec?.Plan?.BusinessID === 'b779e426-9648-4ce6-8778-addba47f2411') {
      if (networkType === 'narrowNetwork') {
        return `- Lower Premiums\n- Deeper Discounts\n- Fewer Providers`;
      } else if (networkType === 'buyUpNetwork') {
        return `- Higher Premium\n- Largest Hospital & Provider Access`;
      } else {
        return `- Higher Premiums\n- Lower Discounts\n- More Providers`;
      }
    } else {
      if (networkType === 'narrowNetwork') {
        return `- Reduced Premium\n- Small Network of High Quality Providers`;
      } else if (networkType === 'buyUpNetwork') {
        return `- Higher Premium\n- Largest Hospital & Provider Access`;
      } else {
        return `- Moderate Premium\n- Large Network with National Coverage`;
      }
    }
  };

  return (
    <>
      <Box
        css={`
          margin: 8px 24px;
          border: 1px solid ${colors.gray[300]};
          border-radius: 16px;
          background-color: white;
          padding: 32px;
          box-sizing: border-box;
        `}
      >
        <Box flex="space-between">
          <Text h1>Medical Plan</Text>
        </Box>
        <Box
          css={`
            margin-top: 32px;
            display: grid;
            gap: 32px;
          `}
        >
          {plansByNetwork?.map(([key, recs], idx) => {
            const totalRecs = recs?.length;
            const core = recs?.find((r) => !r?.Plan?.MultiNetworkCategory || r?.Plan?.MultiNetworkCategory === 'core');
            const isSelected = selectedMedicalRec?.Plan?.ID === core?.Plan?.ID && totalRecs === 1;
            return (
              <Box
                key={key}
                css={`
                  position: relative;
                  border-radius: 16px;
                  border: 1px solid ${colors.gray[300]};
                  padding: 32px;
                  ${isSelected
                    ? `
                  outline: 3px solid var(--button-background-color);  
                  border: 1px solid transparent;
                `
                    : ''}
                  ${totalRecs === 1 && !isSelected
                    ? `
                  :hover {
                    background-color: ${colors.gray[100]};
                    transition: background-color 0.3s ease;
                    cursor: pointer;
                  }
                `
                    : ''}
                `}
                onClick={() => {
                  if (totalRecs === 1) {
                    handleSelectedMedicalRec(core);
                  }
                }}
              >
                {isSelected ? (
                  <Box
                    css={`
                      position: absolute;
                      top: 2px;
                      right: 2px;
                      border-radius: 50%;
                      background-color: var(--button-background-color);
                      width: 24px;
                      height: 24px;
                    `}
                    flex="center"
                  >
                    <Check color="white" size={16} weight="bold" />
                  </Box>
                ) : null}
                {idx === 0 ? (
                  <Box
                    flex="center"
                    css={`
                      position: absolute;
                      top: -16px;
                      left: calc(50% - 90px);
                      width: 160px;
                      background-color: var(--button-background-color);
                      padding: 2px 8px;
                      border-radius: 30px;
                      svg {
                        margin-right: 8px;
                        min-width: 16px;
                      }
                    `}
                  >
                    <Star color="white" size={16} weight="fill" />
                    <Text
                      css={`
                        color: white;
                        font-weight: bold;
                      `}
                    >
                      Recommended
                    </Text>
                  </Box>
                ) : null}
                <Box flex="space-between wrap-reverse">
                  <Box>
                    <Text
                      h2
                      css={`
                        min-width: 150px;
                      `}
                    >
                      {core?.Plan?.ProductName}
                    </Text>
                    {totalRecs === 1 && !!getFinalPlanCost(core) ? (
                      <Text label bold>
                        {getFinalPlanCost(core)} / Month
                      </Text>
                    ) : null}
                  </Box>
                  <CarrierLogo
                    carrierID={core?.Plan?.CarrierID}
                    css={`
                      border-radius: 8px;
                      border: 1px solid ${colors.gray[300]};
                      width: 180px;
                      background-color: white;
                    `}
                    position="center"
                    maxWidth="180px"
                  />
                </Box>
                {totalRecs > 1 ? (
                  <Box>
                    <Box
                      flex="space-between"
                      css={`
                        margin-top: 32px;
                        padding: 0 8px;
                        border-radius: 8px;
                        :hover {
                          background-color: ${colors.gray[100]};
                          cursor: pointer;
                        }
                      `}
                      onClick={() => toggleOpenList(key)}
                    >
                      <Text h4>Additional options ({totalRecs})</Text>
                      <Box
                        flex="center"
                        css={`
                          background-color: ${colors.gray[100]};
                          border-radius: 50%;
                          width: 40px;
                          height: 40px;
                        `}
                      >
                        {openList.includes(key) ? <Minus /> : <Plus />}
                      </Box>
                    </Box>
                    <Collapse
                      in={openList.includes(key)}
                      className={css`
                        padding: 0 8px;
                        margin: 0 -8px;
                      `}
                    >
                      <Box
                        css={`
                          display: grid;
                          gap: 24px;
                          margin-top: 32px;
                        `}
                      >
                        {recs?.map((rec, recIdx) => {
                          const isTopRecommendation = idx === 0 && recIdx === 0;
                          const isSelected = selectedMedicalRec?.Plan?.ID === rec?.Plan?.ID;
                          return (
                            <Box
                              css={`
                                position: relative;
                                border-radius: 16px;
                                border: 1px solid ${colors.gray[300]};
                                padding: 24px;
                                ${isSelected
                                  ? `
                                outline: 3px solid var(--button-background-color);
                                border: 1px solid transparent;
                                  
                              `
                                  : `
                                transition: background-color 0.3s ease;
                                :hover {
                                  cursor: pointer;
                                  background-color: ${colors.gray[100]};
                                }
                              `}
                              `}
                              onClick={() => handleSelectedMedicalRec(rec)}
                            >
                              {isSelected ? (
                                <Box
                                  css={`
                                    position: absolute;
                                    top: 2px;
                                    right: 2px;
                                    border-radius: 50%;
                                    background-color: var(--button-background-color);
                                    width: 24px;
                                    height: 24px;
                                  `}
                                  flex="center"
                                >
                                  <Check color="white" size={16} weight="bold" />
                                </Box>
                              ) : null}
                              <Box
                                flex="space-between"
                                css={`
                                  padding-bottom: 8px;
                                `}
                              >
                                <Box
                                  flex="left"
                                  css={`
                                    svg {
                                      margin-right: 8px;
                                    }
                                    margin-right: 24px;
                                  `}
                                >
                                  {isTopRecommendation ? (
                                    <Star color="var(--button-background-color)" size={16} weight="fill" />
                                  ) : null}
                                  <Text h4>{rec?.Plan?.NetworkName}</Text>
                                </Box>
                                {getFinalPlanCost(core) ? (
                                  <Text
                                    h4
                                    css={`
                                      min-width: max-content;
                                    `}
                                  >
                                    {getFinalPlanCost(rec)} / Month
                                  </Text>
                                ) : null}
                              </Box>
                              <Box flex="space-between start">
                                <Text
                                  css={`
                                    margin-right: 24px;
                                    white-space: pre-line;
                                  `}
                                >
                                  {networkDescriptionOrDefault(rec)}
                                </Text>
                                <Text
                                  css={`
                                    min-width: max-content;
                                  `}
                                >
                                  Your Estimated Cost
                                </Text>
                              </Box>
                              <Button
                                naked
                                css={`
                                  text-decoration: underline;
                                  text-transform: none;
                                  padding: 8px 0;
                                `}
                                aria-label="Learn more"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setModal(rec?.Plan?.ID);
                                }}
                                data-ph-capture-attribute-source={posthogAttrs?.types?.RECOMMENDATIONS}
                                data-ph-capture-attribute-action={posthogAttrs?.actions?.VIEW_MORE}
                                data-ph-capture-attribute-product-type={core.Plan?.Type}
                              >
                                Learn More
                              </Button>
                            </Box>
                          );
                        })}
                      </Box>
                    </Collapse>
                  </Box>
                ) : (
                  <Box
                    css={`
                      margin-top: 8px;
                    `}
                  >
                    {core?.Plan?.NetworkName || core?.Plan?.NetworkDescription ? (
                      <Box
                        css={`
                          margin-bottom: 8px;
                        `}
                      >
                        <Text h4>{core?.Plan?.NetworkName}</Text>
                        <Text>{core?.Plan?.NetworkDescription}</Text>
                      </Box>
                    ) : null}
                    <Button
                      naked
                      css={`
                        text-decoration: underline;
                        text-transform: none;
                        padding: 8px 0;
                      `}
                      aria-label="Learn more"
                      onClick={(e) => {
                        e.stopPropagation();
                        setModal(core?.Plan?.ID);
                      }}
                      data-ph-capture-attribute-source={posthogAttrs?.types?.RECOMMENDATIONS}
                      data-ph-capture-attribute-action={posthogAttrs?.actions?.VIEW_MORE}
                      data-ph-capture-attribute-product-type={core.Plan?.Type}
                    >
                      Learn More
                    </Button>
                  </Box>
                )}
              </Box>
            );
          })}
        </Box>

        <Button
          css={`
            margin-top: 32px;
            min-width: 100%;
          `}
          onClick={() => setShowComparisons(true)}
          data-ph-capture-attribute-source={posthogAttrs?.types?.RECOMMENDATIONS}
          data-ph-capture-attribute-action={posthogAttrs?.actions?.SHOW_COMPARISONS}
          secondary
        >
          Compare All Plans
        </Button>
        {!!modal ? (
          <LearnMore recommendation={recommendation} data={recommendationSummaryData} onClose={() => setModal('')} />
        ) : null}
      </Box>
      <Box>
        {filterSupportedPrograms(selectedMedicalRec.Plan.includedPrograms).length ? (
          <div
            className={css`
              border: 1px solid ${colors.gray[300]};
              background-color: white;
              padding: 32px;
              margin: 0 24px;
              border-radius: 16px;
              margin-top: 24px;
              gap: 24px;
              display: grid;
            `}
          >
            <Text h2>Included Programs</Text>
            {filterSupportedPrograms(selectedMedicalRec.Plan.includedPrograms).map((item) => (
              <IncludedProgram benefit={item} />
            ))}
          </div>
        ) : null}
      </Box>
      {changes !== null ? (
        <Modal display={true} onClose={() => setChanges(null)}>
          <Box
            css={`
              width: 500px;
              padding: 32px;
            `}
          >
            <Box flex="space-between">
              <Text h1>Heads Up</Text>
              <Button styles="icon" onClick={() => setChanges(null)}>
                <X />
              </Button>
            </Box>
            <Box>
              <Text>This selection will make the following changes to your benefits</Text>
              {changes.adds?.length ? (
                <Box
                  css={`
                    border-bottom: 1px solid ${colors.gray[300]};
                    padding: 16px 0;
                  `}
                >
                  <Text
                    h4
                    css={`
                      padding-bottom: 8px;
                    `}
                  >
                    Add
                  </Text>
                  {changes.adds.map((item) => (
                    <Text label>{item}</Text>
                  ))}
                </Box>
              ) : null}
              {changes.removes?.length ? (
                <Box
                  css={`
                    border-bottom: 1px solid ${colors.gray[300]};
                    padding: 16px 0;
                  `}
                >
                  <Text
                    h4
                    css={`
                      padding-bottom: 8px;
                    `}
                  >
                    Remove
                  </Text>
                  {changes.removes.map((item) => (
                    <Text label>{item}</Text>
                  ))}
                </Box>
              ) : null}
              {changes?.cost ? (
                <Box
                  flex="space-between"
                  css={`
                    padding-top: 24px;
                  `}
                >
                  <Text h4>Estimated Cost Change</Text>
                  <Text label bold>
                    <span>{changes?.cost > 0 ? 'Save ' : '+'}</span>${math.abs(changes?.cost)} / Month
                  </Text>
                </Box>
              ) : null}
            </Box>
            <Box
              flex="right"
              css={`
                gap: 16px;
                margin-top: 24px;
              `}
            >
              <Button secondary onClick={() => setChanges(null)}>
                Cancel
              </Button>
              <Button onClick={updateRecChange}>Make Changes</Button>
            </Box>
          </Box>
        </Modal>
      ) : null}
    </>
  );
};
