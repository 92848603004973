import React from 'react';
import { css } from 'emotion';
import Button from '@material-ui/core/Button';
import QuestionPageModal from './question-page-modal';
import { motion } from 'framer-motion';

export const QuestionPageViewer = (props) => {
  const [open, setOpen] = React.useState(false);
  const [answerState, setAnswerState] = React.useState('no-correct-answer');
  const [selectedAnswer, setSelectedAnswer] = React.useState(null);

  return (
    <motion.div
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      key={props.currentPage.Question.Text}
      transition={{ ease: 'easeOut', duration: 2 }}
      className={css`
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
      `}
    >
      <div
        className={css`
          font-size: 21px;
          font-weight: bold;
          line-height: 24px;
          margin: 24px;
        `}
      >
        {props.currentPage.Question.Text}
      </div>
      {props.currentPage.Question.Answers.map((answer, index) => {
        if (!answer.Text) {
          return null;
        }
        return (
          <div
            key={index}
            className={css`
              margin-bottom: 16px;
            `}
          >
            <Button
              variant="contained"
              className={css`
                padding: 6px 32px !important;
                margin-left: 5px !important;
                margin-right: 5px !important;
                max-width: 350px;
              `}
              onClick={() => {
                let anyCorrect = false;
                let answerState = 'no-correct-answer';
                for (let answer of props.currentPage.Question.Answers) {
                  if (answer.IsCorrect) {
                    anyCorrect = true;
                  }
                }
                if (anyCorrect) {
                  answerState = answer.IsCorrect ? 'correct' : 'incorrect';
                }

                setSelectedAnswer(answer);
                setAnswerState(answerState);
                setOpen(true);
              }}
            >
              {answer.Text}
            </Button>
          </div>
        );
      })}
      <QuestionPageModal
        open={open}
        setOpen={setOpen}
        questionId={props.currentPage.Question.ID}
        answerId={selectedAnswer?.ID}
        courseId={props.currentPage.CourseID}
        description={props.currentPage.Question.Explanation}
        nextPage={props.nextPage}
        answerState={answerState} // 'correct', 'incorrect', or 'no-correct-answer'
      />
    </motion.div>
  );
};
