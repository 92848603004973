import React from 'react';
import { css } from 'emotion';
import ErrorIcon from '@material-ui/icons/Error';

export const Error = () => {
  return (
    <div
      className={css`
        display: flex;
        margin: 40px;
        justify-content: center;
        color: red;
        font-family: 'Roboto', sans-serif;
        flex-direction: column;
        text-align: center;
      `}
    >
      <p>
        Could not load benefit data. Try refreshing the page. If the issue persists, contact your HR representative to
        get a new link.
      </p>

      <div>
        <ErrorIcon fontSize={'large'} />
      </div>
    </div>
  );
};
