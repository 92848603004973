import { useEffect } from 'react';
import { CustomAxios } from '../../../../../axios/axios';
import { useFeatureFlagPayload } from 'posthog-js/react';
export const intervalMap = {
  12: 'Monthly',
  52: 'Weekly',
  24: 'Semi-Monthly',
  26: 'Bi-Weekly',
};

export const contributionLabelDefaults = {
  employee: 'Employee',
  spouse: 'Spouse',
  child: 'Child',
  children: 'Children',
  family: 'Family',
};

export const tierStructures = {
  2: ['EmployeeOnly', 'Family'],
  3: ['EmployeeOnly', 'EmployeeSpouse', 'Family'],
  4: ['EmployeeOnly', 'EmployeeSpouse', 'EmployeeChildren', 'Family'],
  5: ['EmployeeOnly', 'EmployeeSpouse', 'EmployeeChild', 'EmployeeChildren', 'Family'],
  // 12 tier
  12: [
    'EmployeeOnly',
    'EmployeeChild',
    'Employee2Children',
    'Employee3Children',
    'Employee4Children',
    'EmployeeChildren',
    'EmployeeSpouse',
    'FamilyChild',
    'Family2Children',
    'Family3Children',
    'Family4Children',
    'Family',
  ],
};

export const useDisplaySettings = (businessId, packageId, callback) => {
  const getLabel = (value, key) => {
    const labels = contributionLabelDefaults;
    return value || labels[key];
  };

  const updateDisplaySettings = (data, pkg) => {
    const settings = data?.[0] || {};

    const employee = getLabel(settings?.custom_label_employee, 'employee');
    settings.custom_label_employee = employee;
    const children = getLabel(settings?.custom_label_employee_children, 'children');
    settings.custom_label_employee_children = children;
    const spouse = getLabel(settings?.custom_label_employee_spouse, 'spouse');
    settings.custom_label_employee_spouse = spouse;
    const child = getLabel(settings?.custom_label_child, 'child');
    settings.custom_label_child = child;
    const family = getLabel(settings?.custom_label_family, 'family');
    settings.custom_label_family = family;

    const labels = {
      ...settings,
      employee,
      children,
      spouse,
      family,
      child,
    };

    const premiumInterval = pkg?.PremiumIntervalsPerYear || settings?.premium_intervals_per_year || 12;

    const displaySettings = {
      ...labels,
      premiumInterval,
      deprecated_4TierLabelStructure: [labels?.employee, labels?.spouse, labels?.children, labels?.family],
      customLabelStructures: {
        // Tier 2
        2: [labels?.employee, `${labels?.employee} + 1 or more`],
        // Tier 3
        3: [labels?.employee, `${labels?.employee} + 1`, `${labels?.employee} + 2 or more`],
        // Tier 4
        4: [
          labels?.employee,
          `${labels?.employee} + ${labels?.spouse}`,
          `${labels?.employee} + ${labels?.children}`,
          labels?.family,
        ],
        // Tier 5
        5: [
          labels?.employee,
          `${labels?.employee} + ${labels?.spouse}`,
          `${labels?.employee} + ${labels?.child}`,
          `${labels?.employee} + ${labels?.children}`,
          labels?.family,
        ],
        // Tier 12
        12: [
          labels?.employee,
          `${labels?.employee} + ${labels?.child}`,
          `${labels?.employee} + 2 ${labels?.children}`,
          `${labels?.employee} + 3 ${labels?.children}`,
          `${labels?.employee} + 4 ${labels?.children}`,
          `${labels?.employee} + 5  or more ${labels?.children}`,
          `${labels?.employee} + ${labels?.spouse}`,
          `${labels?.employee} + ${labels?.spouse} + ${labels?.child}`,
          `${labels?.employee} + ${labels?.spouse} + 2 ${labels?.children}`,
          `${labels?.employee} + ${labels?.spouse} + 3 ${labels?.children}`,
          `${labels?.employee} + ${labels?.spouse} + 4 ${labels?.children}`,
          `${labels?.employee} + ${labels?.spouse} + 5 or more ${labels?.children}`,
        ],
      },
    };
    if (callback) {
      callback(displaySettings);
    }
  };

  const fetchDisplaySettings = async () => {
    try {
      const { data } = await CustomAxios.get(`/public/v1/bp/display_settings?id=eq.${businessId}`, {
        headers: {
          'Accept-Profile': 'brite_public',
        },
      });

      let pkg = {};
      if (packageId) {
        const { data } = await CustomAxios.get(`/public/v1/business/${businessId}/benefitspackage/${packageId}`, {
          headers: {
            'Accept-Profile': 'brite_public',
          },
        });
        pkg = data;
      }
      updateDisplaySettings(data, pkg);
    } catch (err) {
      console.warn(err);
      updateDisplaySettings([], {});
    }
  };

  useEffect(() => {
    if (businessId) {
      fetchDisplaySettings();
    }
  }, [businessId]);
};

export const getTiersAndLabels = ({ cost, displaySettings }) => {
  const tiers = cost?.TotalMonthlyPremiums?.Tiers || tierStructures[4];

  const tierLabels = displaySettings?.customLabelStructures?.[tiers?.length];

  const relevantIdx = tiers.findIndex((tier) => tier === cost?.RelevantTier);

  const sortedTierLabels =
    relevantIdx > 0 ? [tierLabels[relevantIdx], ...tierLabels.filter((_, idx) => idx !== relevantIdx)] : tierLabels;

  const sortedTiers = !!cost?.RelevantTier
    ? [cost?.RelevantTier, ...tiers.filter((tier) => tier !== cost?.RelevantTier)]
    : tiers;

  return {
    tiers,
    tierLabels,
    // sortedTiers moves the relevantTier to the first item in the list
    sortedTiers,
    sortedTierLabels,
  };
};
