import { useEffect, useRef, useState } from 'react';
import { useDebounce } from './use-debounce';
import { useEvent } from '../../shared-components/useEvent';

export const extractDOMRect = (element) => {
  const bounds = element?.getBoundingClientRect?.();
  const { top = 0, left = 0, right = 0, bottom = 0, x = 0, y = 0, height = 0, width = 0 } = bounds || {};
  return {
    top,
    left,
    right,
    bottom,
    x,
    y,
    height,
    width,
    lastUpdate: new Date().toISOString(),
    element,
  };
};

export const useBounds = (ref, deps = []) => {
  const [bounds, setBounds] = useState({});

  const getBounds = (updateType) => {
    const bounds = extractDOMRect(ref?.current);
    setBounds({ ...bounds, updateType });
  };

  useEvent('resize', () => getBounds('resize'));
  useEvent('scroll', () => getBounds('scroll'));

  useEffect(() => {
    getBounds('default');
  }, [...deps]);

  return bounds;
};
