import { useEffect, useState } from 'react';

const buildCode = (event) => {
  let list = [];
  const { code, metaKey, altKey, ctrlKey, shiftKey } = event;
  let addCode = true;
  if (metaKey || code.includes('Meta')) {
    addCode = !code.includes('Meta');
    list.push('meta');
  }
  if (altKey || code.includes('Alt')) {
    addCode = !code.includes('Alt');
    list.push('alt');
  }
  if (ctrlKey || code.includes('Control')) {
    addCode = !code.includes('Control');
    list.push('ctrl');
  }
  if (shiftKey || code.includes('Shift')) {
    addCode = !code.includes('Shift');
    list.push('shift');
  }
  if (code && addCode) {
    list.push(code);
  }
  return list.join('+');
};

export const useKeyboard = (keydown = {}, disabled) => {
  const handleKeydown = (event) => {
    const code = buildCode(event);
    if (code in keydown && !disabled) {
      event.stopPropagation();
      event.preventDefault();
      keydown[code]();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown, null);
    return () => {
      document.removeEventListener('keydown', handleKeydown, null);
    };
  });
};
