import React from 'react';
import { Grommet, Layer } from 'grommet';
import { css } from 'emotion';
import Button from '@material-ui/core/Button';
import { ArrowRight } from 'phosphor-react';
import { posthogAttrs } from '../../posthog-constants';
import { CTESection } from '../cte-section';

export const theme = {
  layer: {
    container: { zIndex: 2000 },
    zIndex: 2000,
  },
};

export const EnrollModal = ({ url, logoURL, setShow, designStyles, setCloseClicked }) => {
  return (
    <>
      <Grommet theme={theme}>
        <Layer
          responsive={true}
          full
          modal
          onClickOutside={() => {
            setShow(false);
          }}
          onEsc={() => {
            setShow(false);
          }}
          className={css`
            height: 100%;
            width: 100%;
            background-color: rgba(37, 40, 45, 0.89) !important;
            box-sizing: border-box;
            padding-top: 32px;
            padding-bottom: 48px;
            overflow: auto;
          `}
        >
          <div
            className={css`
              display: flex;
              justify-content: end;
            `}
          >
            <Button
              style={{
                padding: '12px 12px',
                border: '1px solid #fff',
                color: '#fff',
                marginRight: '24px',
                width: '120px',
              }}
              id="footer"
              variant="outlined"
              role="nav"
              className="page-nav-next"
              onClick={() => {
                setShow(false);
              }}
            >
              Back
            </Button>
          </div>
          <div
            className={css`
              width: 100%;
              max-width: 700px;

              -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
              -moz-box-sizing: border-box; /* Firefox, other Gecko */
              box-sizing: border-box; /* Opera/IE 8+ */
              margin: 0 auto;
              margin-top: 24px;
              @media (max-width: 800px) {
                max-width: 80%;
              }
            `}
          >
            <CTESection
              color={designStyles?.Theme?.Body?.ButtonColor ? designStyles?.Theme?.Body?.ButtonColor : '#25282D'}
            />
          </div>
          <div
            className={css`
              border-radius: 16px;
              display: flex;
              gap: 24px;
              padding: 24px;
              background: white;
              -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
              -moz-box-sizing: border-box; /* Firefox, other Gecko */
              box-sizing: border-box; /* Opera/IE 8+ */

              flex-wrap: wrap;
              align-items: center;
              justify-content: center;
              max-width: 700px;
              margin: 24px auto;
              @media (max-width: 800px) {
                max-width: 80%;
              }
            `}
          >
            <div>
              <div
                className={css`
                  color: #25282D;

                  text-align: center;
                  font-feature-settings: 'clig' off, 'liga' off;
                  /* H2 */
                  font-family: Roboto;
                  font-size: 24px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 32px; /
                  `}
              >
                Ready To Enroll in Your Benefits?
              </div>
              <div
                className={css`
                  font-family: 'Roboto';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 24px;
                  /* or 150% */

                  color: #66737f;
                  margin-top: 8px;
                  margin-bottom: 16px;
                  max-width: 510px;
                  text-align: center;
                `}
              >
                You will be enrolling online. The link has been provided by your employer and will open in a new tab
              </div>
            </div>
            <div
              className={css`
                border: 1px solid #d1dae3;
                border-radius: 8px;
                display: flex;
                align-items: center;
                padding: 24px;
                gap: 24px;
                justify-content: center;
                flex-wrap: wrap;
                @media (max-width: 400px) {
                  border: 1px solid transparent;
                }
              `}
            >
              {logoURL && (
                <div
                  className={css`
                    max-width: 250px;
                    width: 250px;

                    box-sizing: border-box;

                    overflow-wrap: break-word;
                  `}
                >
                  <img
                    src={logoURL}
                    className={css`
                      max-width: 100%;
                      max-height: 100%;
                    `}
                  />
                </div>
              )}
              <div>
                <Button
                  style={{
                    padding: '16px 24px',
                    width: '282px',
                    backgroundColor: designStyles?.Theme?.Body?.ButtonColor
                      ? designStyles?.Theme?.Body?.ButtonColor
                      : '#25282D',
                  }}
                  onClick={() => {
                    window.open(url, '_blank');
                  }}
                  color="primary"
                  variant="contained"
                  size="large"
                  endIcon={<ArrowRight size={24} color="#fff" />}
                  data-ph-capture-attribute-source={posthogAttrs.types.NAVIGATION}
                  data-ph-capture-attribute-action={posthogAttrs.actions.ENROLL}
                >
                  Enroll Now
                </Button>
              </div>
            </div>
          </div>
        </Layer>
      </Grommet>
    </>
  );
};
