import { useState, useEffect } from 'react';
import { CustomAxios } from '../axios/axios';
import { isToday } from 'date-fns';
import { BRITE_RECS, BRITE_USER_RECS, BRITE_USER_ANSWERS } from '../components/decision-tool-views/constants';
import { useStore } from '../components/store-provider/use-store';
import { useLocalStorage } from './use-local-storage';

export function useStoredRecs() {
  const [originalRecs, setOriginalRecs] = useState(null);
  const [userSelectedRecs, setUserSelectedRecs] = useState(null);
  const [userAnswers, setUserAnswers] = useState(null);
  const [openRecResults, setOpenRecResults] = useState(false);
  const [recId, setRecId] = useState('');
  const briteLocalStorage = useLocalStorage();

  const { setStore } = useStore();

  useEffect(() => {
    setStore('acaCostData', {
      members: userAnswers?.acaEnrollingMembers || [],
      state: userAnswers?.livingState,
      zipCode: userAnswers?.zipCode,
    });
  }, [userAnswers]);

  const loadOriginalRecs = () => {
    let path = window.location.pathname;

    let paths = path.split('/');
    let vanityPathIndex = 1;

    for (let p of paths) {
      if (p === 'survey') {
        vanityPathIndex = 2;
      }
    }
    const origRecs = briteLocalStorage.getItem(BRITE_RECS + paths[vanityPathIndex]);
    const data = JSON.parse(origRecs) || {};

    const displayRecs = data.updatedAt && isToday(new Date(data.updatedAt));
    if (displayRecs) {
      setOriginalRecs(data);
    } else {
      briteLocalStorage.removeItem(BRITE_RECS + paths[vanityPathIndex]);
      setOriginalRecs(null);
    }
  };

  const loadUserAnswers = () => {
    let path = window.location.pathname;

    let paths = path.split('/');
    let vanityPathIndex = 1;

    for (let p of paths) {
      if (p === 'survey') {
        vanityPathIndex = 2;
      }
    }
    const origRecs = briteLocalStorage.getItem(BRITE_USER_ANSWERS + paths[vanityPathIndex]);
    const data = JSON.parse(origRecs) || {};

    const displayRecs = data.updatedAt && isToday(new Date(data.updatedAt));
    if (displayRecs) {
      setUserAnswers(data);
    } else {
      briteLocalStorage.removeItem(BRITE_USER_ANSWERS + paths[vanityPathIndex]);
      setUserAnswers(null);
    }
  };

  const loadUserSelectedRecs = () => {
    let path = window.location.pathname;

    let paths = path.split('/');
    let vanityPathIndex = 1;

    for (let p of paths) {
      if (p === 'survey') {
        vanityPathIndex = 2;
      }
    }
    const origRecs = briteLocalStorage.getItem(BRITE_USER_RECS + paths[vanityPathIndex]);
    const data = JSON.parse(origRecs) || {};

    const displayRecs = data.updatedAt && isToday(new Date(data.updatedAt));
    if (displayRecs) {
      setUserSelectedRecs(data);
    } else {
      briteLocalStorage.removeItem(BRITE_USER_RECS + paths[vanityPathIndex]);
      setUserSelectedRecs(null);
    }
  };

  const loadRecsFromLocalStorage = () => {
    loadOriginalRecs();
    loadUserAnswers();
    loadUserSelectedRecs();
  };

  const loadBenefitRecommendationData = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('rec');

    if (!!id) {
      setRecId(id);
      try {
        const employeeElections = `/public/v1/bp/employee_elections?id=eq.${id}`;
        const { data } = await CustomAxios.get(employeeElections, {
          headers: {
            'Accept-Profile': 'brite_public',
          },
        });

        if (data && data.length && data.length > 0 && data[0].id) {
          setOpenRecResults(true);
        }

        let dateex = new Date().toISOString();

        let path = window.location.pathname;

        let paths = path.split('/');
        let vanityPathIndex = 1;

        for (let p of paths) {
          if (p === 'survey') {
            vanityPathIndex = 2;
          }
        }

        try {
          briteLocalStorage.setItem(
            BRITE_RECS + paths[vanityPathIndex],
            JSON.stringify({
              ...data[0].plan_elections[0],
              updatedAt: dateex,
            })
          );
        } catch (e) {
          briteLocalStorage.clear();
        }

        try {
          briteLocalStorage.setItem(
            BRITE_USER_RECS + paths[vanityPathIndex],
            JSON.stringify({
              ...data[0].plan_elections[0],
              updatedAt: dateex,
            })
          );
        } catch (e) {
          briteLocalStorage.clear();
        }

        loadRecsFromLocalStorage();
      } catch (err) {
        console.error(err);
        setRecId('');
        loadRecsFromLocalStorage();
      }
    } else {
      setRecId('');
      loadRecsFromLocalStorage();
    }
  };

  useEffect(() => {
    loadBenefitRecommendationData();
  }, []);

  const clearStoredRecs = () => {
    let path = window.location.pathname;

    let paths = path.split('/');
    let vanityPathIndex = 1;

    for (let p of paths) {
      if (p === 'survey') {
        vanityPathIndex = 2;
      }
    }

    briteLocalStorage.removeItem(BRITE_RECS + paths[vanityPathIndex]);
    briteLocalStorage.removeItem(BRITE_USER_RECS + paths[vanityPathIndex]);
    briteLocalStorage.removeItem(BRITE_USER_ANSWERS + paths[vanityPathIndex]);
    setOriginalRecs(null);
    setUserSelectedRecs(null);
    setUserAnswers(null);
    setRecId('');
  };

  return {
    originalRecs,
    userSelectedRecs,
    userAnswers,
    clearStoredRecs,
    openRecResults,
    recId,
    setRecId,
    setUserSelectedRecs,
    setOriginalRecs,
    setUserAnswers,
  };
}
