import { css } from 'emotion';
import { useMemo } from 'react';
import { Div } from '../../../shared-components';
import { PricingSummary } from '../../brite-viewer/components/benefits/summaries/pricing-summary';
import { useProduct } from '../../brite-viewer/components/benefits//use-product';
import { BenefitsSummary } from './BenefitsSummary';
import { NetworkSearch } from './NetworkSearch';

export const Benefits = ({ item, containerStyle }) => {
  const { component, data, type } = item;

  const product = useProduct({
    productId: data?.id,
    productType: type,
  });

  const Summaries = useMemo(() => {
    if (component === 'benefits') {
      return (
        <Div
          css={css`
            padding: 24px 8px;
          `}
        >
          <BenefitsSummary data={product} />
        </Div>
      );
    } else if (component === 'pricing') {
      const premiumsSetExternally = product?.product?.Details?.PlanType?.startsWith('aca');
      const props = {
        data: product,
        summary: data,
        type: type,
        premiumsSetExternally,
      };
      return (
        <Div style={containerStyle}>
          <PricingSummary isPDF {...props} />
        </Div>
      );
    } else if (component === 'network') {
      return <NetworkSearch data={product} />;
    }
  }, [data?.id, data?.variant, product]);

  if (!data?.id) {
    return null;
  } else {
    return Summaries;
  }
};
