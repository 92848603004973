import { css, cx } from 'emotion';
import { File, FileCsv, FileDoc, FileJpg, FilePdf, FileXls } from 'phosphor-react';
import React from 'react';
import { colors } from './styles';

const iconMap = {
  '': File,
  ['.csv']: FileCsv,
  ['.plain']: FileDoc,
  ['.pdf']: FilePdf,
  ['.sheet']: FileXls,
  ['.xlsx']: FileXls,
  ['.xls']: FileXls,
  ['.xlsm']: FileXls,
  ['.jpeg']: FileJpg,
};

export const ExtensionIconMapper = ({ className = '', fileType, color = colors.black, size = 40 }) => {
  if (!fileType) {
    return null;
  }

  const extensionMatch = fileType.match(/\.[0-9a-z+]+$/i);
  const extension = typeof extensionMap === 'string' ? extensionMatch : extensionMatch?.[0];
  const Icon = extension in iconMap ? iconMap[extension] : File;
  return (
    <Icon
      className={cx(
        css`
          min-width: max-content;
        `,
        className
      )}
      weight="fill"
      size={size}
      color={color}
    />
  );
};
