import { css } from 'emotion';

// Emulate Text component styles via text-body-component.
export const PDFText = ({ text }) => (
  <div
    className={css`
      background-color: rgba(0, 0, 0, 0);
      padding: 8px;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
    `}
  >
    <span className="text-body-component" style={{ color: 'var(--button-background-color)' }}>
      {text}
    </span>
  </div>
);
