import { css } from 'emotion';
import QRCode from 'react-qr-code';
import { Div, Text } from '../../../shared-components';
import { colors } from '../../../shared-components/styles';

export const formatUrl = (text) => {
  let url = text;
  if (!/^https?:\/\//i.test(url) && !/^http?:\/\//i.test(url)) {
    url = 'https://' + url;
  }
  try {
    const data = new URL(url);
    return [data.href, true];
  } catch (err) {
    return ['', false];
  }
};

export const NetworkSearch = ({ data }) => {
  const {
    IsMultiCarrier,
    MultiCarrierNetworkName,
    MultiCarrierNetworkDescription,
    MultiCarrierNetworkCarrierSearchUrl,
    NetworkName,
    NetworkDescription,
    NetworkProviderSearchUrl,
  } = data?.product?.Details || {};

  const { ProductNetworkName, ProductNetworkDescription, ProductNetworkProviderSearchUrl } = data?.product || {};

  return (
    <Div
      css={css`
        padding: 8px;
        width: 100%;
      `}
    >
      <Div
        css={css`
          display: flex;
          padding: 16px 0px;
          align-items: flex-start;
          gap: 24px;
          align-self: stretch;
        `}
      >
        <Text h4>Network Information</Text>
      </Div>
      <Div
        css={css`
          display: flex;
          flex-direction: column;
          padding: 16px;
          border-radius: 16px;
          border: 1px solid ${colors.gray[300]};
        `}
      >
        <Network
          title={`Search for ${ProductNetworkName || NetworkName} Providers`}
          description={ProductNetworkDescription || NetworkDescription}
          url={ProductNetworkProviderSearchUrl || NetworkProviderSearchUrl || ''}
        />
        {IsMultiCarrier && (
          <Network
            title={`Search for ${MultiCarrierNetworkName} Providers`}
            description={MultiCarrierNetworkDescription}
            url={MultiCarrierNetworkCarrierSearchUrl || ''}
          />
        )}
      </Div>
    </Div>
  );
};

const Network = ({ title = '', description = '', url = '' }) => (
  <Div
    css={css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      overflow-wrap: break-word;
      gap: 16px;
    `}
  >
    <Div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 8px;
      `}
    >
      <Text h4>{title}</Text>
      <Text
        body
        css={css`
          word-break: break-all;
        `}
      >
        {description}
      </Text>
    </Div>
    <Div
      css={css`
        padding: 8px;
      `}
    >
      <QRCode size={80} value={url} />
    </Div>
  </Div>
);
