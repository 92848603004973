export function useLocalStorage() {
  const getItem = (k, ignoreMode = false) => {
    const urlParams = new URLSearchParams(window.location.search);
    const mode = urlParams.get('mode');
    if (!ignoreMode && mode === 'kiosk') {
      return null;
    }

    return localStorage.getItem(k);
  };

  const removeItem = (k) => {
    localStorage.removeItem(k);
  };

  const setItem = (k, d, ignoreMode = false) => {
    const urlParams = new URLSearchParams(window.location.search);
    const mode = urlParams.get('mode');
    if (!ignoreMode && mode === 'kiosk') {
      return;
    }

    localStorage.setItem(k, d);
  };

  const clear = () => {
    localStorage.clear();
  };

  return {
    getItem,
    removeItem,
    setItem,
    clear,
  };
}
