import React from 'react';
import { Button, IconButton, Typography } from '@material-ui/core';
import { Layer } from 'grommet';
import { css } from 'emotion';
import { X } from 'phosphor-react';

const container = css`
  width: 20vw;
  min-width: 500px;
  height: 220px;
  padding: 24px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`;

export const ConfirmationModal = ({
  display,
  title = 'Are you sure?',
  onConfirm,
  onClose,
  onExit = onClose, // This is in case the onClose button is also doing things before closing rather than just exiting
  closeText = 'Close',
  confirmText = 'Confirm',
}) => {
  const handleConfirm = () => {
    onConfirm();
    onExit();
  };

  if (!display) {
    return null;
  }

  return (
    <Layer className={container} onClickOutside={onExit} onEsc={onExit} modal={true}>
      <IconButton style={{ position: 'absolute', top: '8px', right: '8px' }} onClick={onExit}>
        <X />
      </IconButton>
      <div
        className={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 100%;
          padding: 32px;
        `}
      >
        <Typography style={{ fontSize: '18px', fontWeight: 'bold' }}>{title}</Typography>
      </div>
      <div
        className={css`
          width: 100%;
          display: flex;
          justify-content: right;
          align-items: center;
          button {
            margin: 0 16px;
          }
        `}
      >
        <Button onClick={onClose}>{closeText}</Button>
        <Button onClick={handleConfirm} color="primary" variant="contained">
          {confirmText}
        </Button>
      </div>
    </Layer>
  );
};
