import React, { useMemo, useState } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { css } from 'emotion';
import { toast } from 'react-toastify';
import { motion } from 'framer-motion';
import { colors } from '../../../shared-components/styles';
import { container, flex, scrollbar } from '../../../shared-components/shared-styles';
import {
  CaretDown,
  CaretUp,
  CheckCircle,
  MinusCircle,
  PlusCircle,
  User,
  UserCirclePlus,
  UserPlus,
} from 'phosphor-react';
import { Text } from '../../../shared-components';
import { useOutsideClick } from '../../use-outside-click';
import { Collapse } from '@material-ui/core';
import { useStore } from '../../store-provider/use-store';

function createArray(length, placeholder = null) {
  return new Array(length).fill(placeholder);
}

export const DecisionToolEnrollingACAQuestion = (props) => {
  const addMember = (type) => {
    let members = [...props.acaEnrollingMembers];

    let childCount = 0;
    let spouseExists = false;

    for (let member of members) {
      if (member.Type === 'Child') {
        childCount++;
      } else if (member.Type === 'Spouse') {
        spouseExists = true;
      }
    }

    if ((type === 'Child' && childCount < 3) || (type === 'Spouse' && !spouseExists)) {
      members.push({
        Age: 0,
        IsTobaccoUser: false,
        Type: type,
        tempId: new Date().getTime(),
      });
      props.setAcaEnrollingMembers(members);
    } else {
      if (type === 'Spouse') {
        toast.info('Can only add one spouse', {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.info('Only add your oldest 3 children under the age of 26', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  const updateMember = (member, key, value) => {
    let members = [...props.acaEnrollingMembers];
    for (let item of members) {
      if (item.tempId === member.tempId) {
        item[key] = value;
      }
    }
    props.setAcaEnrollingMembers(members);
  };

  const removeMember = (member) => {
    let members = [...props.acaEnrollingMembers];
    let filtered = members.filter(function (el) {
      return el.tempId !== member.tempId;
    });
    props.setAcaEnrollingMembers(filtered);
  };

  const getMemberByType = (type) => props.acaEnrollingMembers?.find((item) => item?.Type === type);

  const dependents = props.acaEnrollingMembers?.filter((item) => item?.Type === 'Child');

  const methods = {
    addMember,
    removeMember,
    updateMember,
  };

  return (
    <motion.div
      key={'zipcode_aca_question'}
      initial="pageInitial"
      animate="pageAnimate"
      exit="pageExit"
      variants={{
        pageInitial: {
          y: 400,
          opacity: 0,
        },
        pageAnimate: {
          y: 0,
          opacity: 1,
        },
        pageExit: {
          y: -400,
          opacity: 0,
        },
      }}
      className={css`
        background: #ffffff;

        box-shadow: 0px 2px 8px rgba(37, 40, 45, 0.25);
        border-radius: 8px;
        padding: 24px;
        margin-bottom: 32px;
        text-align: left;
      `}
    >
      <h1
        className={css`
          color: #25282d;
          font-size: 23px;
          line-height: 32px;
          margin-block-start: 0px;
          margin-block-end: 24px;
        `}
      >
        Who are you enrolling?
      </h1>

      <Member type="Self" data={getMemberByType('Self')} methods={methods} />
      <Member type="Spouse" data={getMemberByType('Spouse')} methods={methods} />
      {dependents?.map((item) => (
        <Member type="Child" data={item} methods={methods} />
      ))}
      <Member type="Child" data={null} methods={methods} />
    </motion.div>
  );
};

const typeIconMap = {
  Self: User,
  Spouse: UserPlus,
  Child: UserCirclePlus,
};

const acaToBriteKeys = {
  Self: 'employee',
  Spouse: 'spouse',
  Child: 'child',
};

const Member = ({ data, type, methods }) => {
  const [open, setOpen] = useState(false);
  const ref = useOutsideClick(() => setOpen(false));

  const {
    data: { displaySettings },
  } = useStore();

  const getAges = () => {
    if (data?.Type === 'Child') {
      return 27;
    } else {
      return 100;
    }
  };

  const isTopPosition = useMemo(() => {
    const bounds = ref?.current?.getBoundingClientRect();
    return bounds?.top > window.innerHeight / 2;
  });

  const Icon = typeIconMap[type];

  return (
    <div
      className={css`
        border: 2px solid ${colors.gray[300]};
        border-radius: 16px;
        padding: 24px;
        margin-bottom: 24px;
        ${!!data || type === 'Self' ? '' : container.hover}
        ${type !== 'Self' ? 'cursor: pointer;' : ''}
        .hover-icon {
          margin-right: 16px;
          color: ${colors.gray[400]};
        }
        :hover {
          .hover-icon {
            color: ${colors.evPurple};
          }
        }
      `}
      onClick={() => (type !== 'Self' ? (!!data ? methods?.removeMember(data) : methods?.addMember(type)) : null)}
    >
      <div
        className={css`
          ${flex('space-between')}
        `}
      >
        <div
          className={css`
            ${flex('left')}
            svg {
              margin-right: 16px;
            }
          `}
        >
          <Icon />
          <Text h4>
            {!data && type !== 'Self' ? 'Add ' : ''}
            {displaySettings[acaToBriteKeys[type]]}
          </Text>
        </div>

        {!data ? (
          <PlusCircle weight="fill" className="hover-icon" />
        ) : data?.Type === 'Self' ? (
          <CheckCircle weight="fill" color={colors.evPurple} />
        ) : (
          <MinusCircle weight="fill" className="hover-icon" />
        )}
      </div>

      <Collapse in={!!data}>
        <div>
          <Text
            css={`
              margin: 8px 0;
            `}
          >
            Age
          </Text>
          <div
            className={css`
              position: relative;
              width: 100px;
            `}
            ref={ref}
            onClick={(e) => e.stopPropagation()}
          >
            <div
              className={css`
                ${flex('space-between')}
                ${container.hover}
                background-color: white;
                border-radius: 8px;
                border: 1px solid ${colors.gray[300]};
                padding: 8px 16px;
                flex-grow: 1;
                svg {
                  width: 24px;
                  height: 24px;
                }
              `}
              onClick={() => setOpen(!open)}
            >
              <Text label>{data?.Age}</Text>
              {open ? <CaretUp /> : <CaretDown />}
            </div>
            {open ? (
              <div
                className={css`
                  z-index: 1000;
                  position: absolute;
                  ${isTopPosition ? `bottom: 100%;` : `top: 100%;`}
                  left: 0;
                  right: 0;
                  ${container.box}
                  padding: 8px;
                  padding-right: 12px;
                  overflow: auto;
                  max-height: 30vh;
                  ${scrollbar.style}
                `}
                onClick={() => setOpen(false)}
              >
                {Array.from(Array(getAges('Child')).keys()).map((option) => {
                  return (
                    <MenuItem
                      key={option}
                      value={option}
                      onClick={() => methods.updateMember(data, 'Age', option)}
                      className={css`
                        border-radius: 8px !important;
                      `}
                    >
                      {option}
                    </MenuItem>
                  );
                })}
              </div>
            ) : null}
          </div>
        </div>
      </Collapse>
    </div>
  );
};

// {props.acaEnrollingMembers.map((member) => (
//   <div
//     className={css`
//       display: flex;
//       align-items: center;
//       justify-content: space-between;
//       padding: 8px;
//       flex-wrap: wrap;
//     `}
//     key={member.tempId}
//   >
//     <div
//       className={css`
//         color: black;
//         font-weight: bold;
//         font-size: 16px;
//       `}
//     >
//       {member.Type === "Single" ? "Self" : `+ ${member.Type}`}
//     </div>
//     <div
//       className={css`
//         display: flex;
//         align-items: center;
//       `}
//     >
//       {props.promptOptions.promptAge && (
//         <FormControl variant="outlined">
//           <InputLabel id="enrolling-label">Age</InputLabel>
//           <Select
//             MenuProps={{
//               style: { zIndex: 35001 },
//             }}
//             className={css`
//               min-width: 80px;
//             `}
//             labelId="enrolling-label"
//             value={member.Age}
//             onChange={(e) => updateMembersAge(e, member)}
//             label="Age"
//           >
// {Array.from(Array(getAges(member.Type)).keys()).map(
//   (option) => {
//     return (
//       <MenuItem key={option} value={option}>
//         {option}
//       </MenuItem>
//     );
//   }
// )}
//           </Select>
//         </FormControl>
//       )}

//       {props.promptOptions.promptTobacco && (
//         <FormControlLabel
//           control={
//             <Checkbox
//               checked={member.IsTobaccoUser}
//               onChange={(e) => updateMembersTobaccoUser(e, member)}
//               name="IsTobaccoUser"
//               color="primary"
//             />
//           }
//           labelPlacement="top"
//           label="Tobacco"
//         />
//       )}

//       {/* Single is deprecated */}
//       {(member.Type === "Single" || member.Type === "Self") && (
//         <div
//           className={css`
//             width: 49px;
//           `}
//         ></div>
//       )}

//       {member.Type !== "Single" && member.Type !== "Self" && (
//         <div>
//           <IconButton
//             aria-label="Delete"
//             onClick={() => removeMember(member)}
//           >
//             <ClearIcon />
//           </IconButton>
//         </div>
//       )}
//     </div>
//   </div>
// ))}
