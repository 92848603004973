import { css } from 'emotion';
import { Div, Text } from '../../../../shared-components';
import { flex } from '../../../../shared-components/shared-styles';
import { colors } from '../../../../shared-components/styles';
import { FieldComponent } from './field-component';

export const SummaryField = ({ product, field, config, comparisonView = false }) => {
  // Field is not defined at some point in the render cycle so we need to check for it before proceeding.
  if (!field || (!comparisonView && field.State === 'hide') || config?.hideField) {
    return null;
  }

  const hasSectionTitle = 'sectionTitle' in field;

  const Wrapper = ({ children, hideAlternating }) => {
    return (
      <Div
        onClick={() => console.log(field)}
        className={hideAlternating ? '' : 'alternating-item'}
        css={css`
          overflow: hidden;
          ${comparisonView && field?.State === 'hide'}
          padding: 16px 32px;
          ${field?.State === 'highlight'
            ? `
            border-left: 4px solid var(--button-background-color, ${colors.purple});
            p {
              font-size: 1.3em;
              font-weight: bold;
            }
          `
            : ''}
        `}
      >
        {children}
      </Div>
    );
  };

  if (config?.hideField) {
    return null;
  } else if (config?.components?.length) {
    return (
      <>
        {config?.components?.map((Component) => (
          <Wrapper>
            <Component field={field} Wrapper={Wrapper} />
          </Wrapper>
        ))}
      </>
    );
  } else if (hasSectionTitle) {
    return (
      <Text
        h4
        className={css`
          width: 100%;
          padding: 8px 32px;
          padding-top: 16px;
        `}
      >
        {field.sectionTitle}
      </Text>
    );
  }

  return (
    <Wrapper hideAlternating={field?.Type === 'label'}>
      {!hasSectionTitle ? (
        <Div
          css={css`
            ${!(field.RecDisplayValue || field?.DisplayValue)
              ? ''
              : comparisonView
              ? ''
              : flex('space-between start')} flex-grow: 1;
          `}
        >
          {field.Type === 'label' ? (
            <Text
              label
              bold={product?.Type === 'insurance_plan'}
              className={css`
                width: 100%;
                color: var(--text-body);
              `}
            >
              {field.RecDisplayValue || field.DisplayValue}
            </Text>
          ) : (
            <Text
              label
              css={`
                ${comparisonView ? 'margin-bottom: 8px;' : ''}
              `}
            >
              {field.RecDisplayValue || field.DisplayValue}
            </Text>
          )}
          {field.Type !== 'label' && <FieldComponent field={field} product={product} comparisonView={comparisonView} />}
        </Div>
      ) : null}
    </Wrapper>
  );
};
