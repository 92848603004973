import { useEffect, useState } from 'react';
import { CustomAxios } from '../../../../axios/axios';
import { useStore } from '../../../store-provider/use-store';

export const useAcaCost = ({ productId }) => {
  const {
    data: { acaCostData = {} },
  } = useStore();

  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState({});

  const fetchMemberCost = async () => {
    try {
      setIsLoading(true);
      const { data } = await CustomAxios.post(`public/v1/product/${productId}/cost`, {
        State: acaCostData.livingState,
        ACAZipCode: acaCostData.zipCode,
        ACAEnrollees: acaCostData.members,
      });
      setValue(data);
    } catch (err) {
      console.warn(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (acaCostData?.members?.length) {
      fetchMemberCost();
    }
  }, [acaCostData?.members]);

  return {
    isLoading,
    value,
  };
};
